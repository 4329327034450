<template>
  <div>
    <Banner theme="yellow" text-color="#56565A">
      <template v-slot:title> Submission Forms </template>
      <template v-slot:description>
        Submission Forms are a tool you can use to request User Generated
        Content (UGC) and allow guests to submit photos, video, and/or messages.
        You can then curate, display, and unpublish the content as you see fit.
      </template>
      <template v-slot:image>
        <img
          src="@/assets/banners/widgets/user-generated-content.svg"
          alt="Submission Forms"
        />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Submission Form"
      btn-to="/widgets/submission-forms/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Submission Form"
      :search-text.sync="searchText"
    />

    <div class="page-subtitle">Visualization</div>
    <div v-if="submissionContentsList">
      <Stories
        :click-path="(item) => `/widgets/submission-contents/${item.id}/edit`"
        create-path="/widgets/submission-contents/new"
        :list="submissionContentsList"
        add-btn-text="New Visualizaton"
      />
    </div>

    <div class="page-subtitle">Submission Forms</div>

    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length">
        <ListPanel
          v-for="section in computedList"
          :key="section.id"
          :action-path="`/widgets/submission-forms/${section.id}`"
          :title="section.title"
          @destroy="destroySubmissionForm(section)"
          @duplicate="duplicateSubmissionForm(section)"
        >
          <template #addAction>
            <v-btn
              :to="`/widgets/submission-forms/${section.id}/submissions/new`"
              color="primary"
              elevation="0"
              rounded
              class="content-blocks__new-block-btn"
            >
              <slot name="btn">
                <v-icon>$plus</v-icon>
                <span class="content-blocks__new-block-btn-text">
                  Add Submission
                </span>
              </slot>
            </v-btn>
            <v-dialog width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  outlined
                  color="primary"
                  class="ml-6"
                  v-bind="attrs"
                  v-on="on"
                >
                  Download CSV
                </v-btn>
              </template>

              <v-card>
                <v-card-text>
                  <v-container>
                    <v-checkbox
                      v-model="exportModal.enableDateRange"
                      label="Enable Date Range"
                    ></v-checkbox>

                    <v-menu
                      v-if="exportModal.enableDateRange"
                      ref="menu"
                      v-model="exportModal.menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="exportModal.dateRangeText"
                          label="Select Date Range (all times in ET)"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="exportModal.dateRange"
                        range
                        scrollable
                        no-title
                        full-width
                        style="width: 422px"
                        @change="setDateRange"
                      ></v-date-picker>
                    </v-menu>
                  </v-container>
                </v-card-text>

                <!-- <v-divider></v-divider> -->

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="downloadCSV(section)">
                    Download CSV
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template #prependActions>
            <v-btn
              icon
              :to="`/widgets/submission-forms/${section.id}/export_sort`"
            >
              <v-icon size="24"> $table </v-icon>
            </v-btn>
          </template>
          <!-- <SubmissionsDataGrid :entity="section" /> -->
          <SubmissionsDataTable :entity="section" />
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Submission Forms</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from "../index-page";
// import SubmissionsDataGrid from "@/components/dataview/submissions.datagrid";
import SubmissionsDataTable from "@/components/dataview/submissions.datatable";

export default {
  name: "WidgetUserSubmittedContentPage",
  components: {
    // SubmissionsDataGrid,
    SubmissionsDataTable,
  },
  extends: IndexPage,
  data() {
    return {
      submissionContentsList: [],
      exportModal: {
        menu: false,
        enableDateRange: false,
        dateRange: [],
        dateRangeText: "",
      },
    };
  },
  created() {
    this.fetchContentList();
  },
  methods: {
    async fetchContentList() {
      const { data } = await this.$api.SubmissionContent.get();
      this.submissionContentsList = data;
    },
    async fetchList() {
      const { data } = await this.$api.SubmissionForm.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroySubmissionForm(submissionForm) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.SubmissionForm.destroy({ id: submissionForm.id });
      this.list = this.list.filter((t) => t.id != submissionForm.id);
    },
    async duplicateSubmissionForm(submissionForm) {
      await this.$api.SubmissionForm.duplicate({ id: submissionForm.id });
      this.fetchList();
      this.fetchContentList();
    },
    async downloadCSV(submissionForm) {
      try {
        const response = await this.$api.SubmissionForm.export({
          id: submissionForm.id,
          dates: this.exportModal.enableDateRange
            ? this.exportModal.dateRange
            : null,
          token: this.$store.getters["auth/token"],
        });

        // Create a Blob from the CSV response
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${submissionForm.title}.csv`); // Set the filename
        document.body.appendChild(link);
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        this.$allertWindow(`Error downloading the export: ${error}`, "error");
      }
    },
    setDateRange(value) {
      this.exportModal.dateRangeText = value
        ? `${value[0]} to ${value[1]}`
        : "";
      this.exportModal.menu = false;
    },
  },
};
</script>

<style scoped lang="scss"></style>
