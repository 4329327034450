<template>
  <div>
    <div v-if="list">
      <div v-if="list.length">
        <v-data-table
          :headers="headers"
          :hide-default-footer="showMore"
          :items="list"
          item-key="id"
          show-expand
          class="elevation-0 data-table"
        >
          <template v-slot:body="{ items }">
            <tbody v-for="item in items" :key="item.id">
              <tr class="data-table__row">
                <td />

                <td class="nowrap">
                  <router-link :to="`/support/${entity.id}/tickets/${item.id}`">
                    {{
                      item.created_at
                        | moment(
                          "timezone",
                          currentUser.active_project.timezone,
                          "MM/DD/YY h:mm A"
                        )
                    }}
                  </router-link>
                </td>
                <td>
                  <div v-for="(fd, index) in item.formatted_data" :key="index">
                    <b>{{ fd.label }}:&nbsp;</b>
                    <span>{{ fd.value }}</span>
                  </div>
                  <a :href="`mailto:${item.email}`" />
                </td>
                <td>
                  <div class="ticket-status" :class="item.status">
                    {{ statuses[item.status] }}
                  </div>
                </td>
                <td>
                  <router-link :to="`/users/participants/${item.user.id}/edit`" v-if="item.user">
                    {{ item.user.email }}
                  </router-link>
                  <span v-else>User was not logged in.</span>
                </td>
                <td class="text-center nowrap">
                  <Actions
                    hide-edit
                    hide-duplicate
                    :path="`/support/${entity.id}/tickets/${item.id}`"
                    @destroy="destroy(item)"
                  />
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
        <router-link
          v-if="showMore"
          :to="`/support/${entity.id}`"
          class="view-all"
        >
          View All Tickets
        </router-link>
      </div>
      <div v-else class="no-entries">No Entries</div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  props: {
    entity: Object,
    showMore: Boolean,
  },
  data() {
    return {
      list: null,
      statuses: {
        new: "New",
        in_progress: "In Progress",
        closed: "Resolved",
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Date",
          align: "start",
          value: "created_at",
          sortable: false,
        },
        {
          text: "",
          align: "start",
          value: "formatted_data",
          sortable: false,
        },
        {
          text: "Status",
          align: "start",
          value: "status",
          sortable: false,
        },
        {
          text: "User",
          align: "start",
          value: "user",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  created() {
    this.setList();
  },
  methods: {
    async destroy({ id }) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.SupportTicket.destroy({ id });
      this.setList();
    },
    async setList() {
      const { data } = await this.$api.SupportTicket.get({
        support_form_id: this.entity.id,
      });
      this.list = data;
    },
  },
};
</script>

<style scoped lang="scss">
.ticket-status {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  padding: 5px 12px;
  border-radius: 100px;
  text-align: center;
  display: inline-block;

  &.pending {
    color: white;
    background: var(--v-primary-base);
  }
  &.closed {
    color: white;
    background: var(--v-text-base);
  }
}
</style>
