<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr class="data-table__row">
            <td />

            <td>
              <!-- <a :href="`mailto:${item.email}`">{{ item.email }}</a> -->
              {{ item.email }}
            </td>
            <td class="nowrap">
              <router-link :to="`/users/participants/${item.id}/edit`">
                {{ item.full_name }}
              </router-link>
              <v-btn
                @click="loginTo(item)"
                outlined
                style="margin-left: 15px"
                color="primary"
                x-small
                >Login</v-btn
              >
            </td>
            
             <td class="nowrap">
             {{ item.roles.join(', ') }}
             </td>

             <td class="nowrap">
              {{ item.blocked ? "Blocked" : "Active" }}
             </td>

            <td class="text-center nowrap">
              <Actions
                hide-duplicate
                :path="`/users/participants/${item.id}`"
                @destroy="$emit('destroy', item)"
              >
              </Actions>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    loginTo(user) {
      const domain =
        this.currentUser.active_project.domains[
          this.currentUser.active_project_env
        ];
      window.open(
        "https://" + domain + "/token-login?token=" + user.one_click_auth_token
      );
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Email",
          align: "start",
          value: "email",
        },
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        {
           text: 'Roles',
           align: 'start',
           value: 'roles',
        },
        {
          text: 'Status',
          align: 'start',
          value: 'blocked'
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style></style>
