<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Image Asset Info">
        <FormRow label="Title" required>
          <TextField v-model="form.title" placeholder="Title" />
        </FormRow>

        <FormRow label="File" required tooltip="This is the uploaded file.">
          <Uploader v-model="form.asset" position="top" :ratio="1200 / 600" />
        </FormRow>
        <!-- <FormRow
          v-if="(form.asset || {}).url"
          clean
          tooltip="Click this button to copy a link to the file to your clipboard."
        >
          <template #label>
            <v-btn @click.stop="copyAssetUrlToClipboard">
              <v-icon>$file</v-icon>
              Copy original to Clipboard
            </v-btn>
          </template>
        </FormRow> -->
        <template v-if="(form.asset || {}).url">
          <div v-for="(subAsset, key) in computedVersions" :key="subAsset.url">
            <FormRow
              clean
              tooltip="Click this button to copy a link to the file to your clipboard."
              v-if="subAsset.url"
            >
              <template v-if="subAsset.size">
                <ul>
                  <li>
                    {{ `Dimensions: ${subAsset.width}x${subAsset.height}` }}
                  </li>
                  <li>
                    {{
                      `Size: ${formatBytes(subAsset.size)} ${calculateSavings(
                        computedVersions.original.size,
                        subAsset.size
                      )}`
                    }}
                  </li>
                </ul>
              </template>
              <template #label>
                <v-btn @click.stop="copyAssetUrlToClipboard(subAsset.url)">
                  <v-icon>$file</v-icon>
                  Copy {{ key === "url" ? "original" : `${key} version` }} to
                  Clipboard
                </v-btn>
              </template>
            </FormRow>
          </div>
        </template>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ btnText }}
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
import { copyToClipboard } from "@/lib";

export default {
  extends: mixin,
  methods: {
    copyAssetUrlToClipboard(copyValue) {
      copyToClipboard(copyValue);
      this.$allertWindow("Link Copied to Clipboard");
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = [
        "Bytes",
        "KiB",
        "MiB",
        "GiB",
        "TiB",
        "PiB",
        "EiB",
        "ZiB",
        "YiB",
      ];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    calculateSavings(fromBytes, toBytes) {
      let saved = ((toBytes - fromBytes) / Math.abs(fromBytes)) * 100;
      if (saved !== 0) {
        return `(${saved > 0 ? "+" : "-"}${Math.abs(saved).toFixed(2)}%)`;
      } else {
        return "";
      }
    },
  },
  computed: {
    computedVersions() {
      const versionData = this.form.version_data;
      const asset = { ...this.form.asset };
      delete asset.url;

      if (!versionData) {
        return { url: { url: this.form.asset.url }, ...asset };
      }

      // Combine 'original' in 'version_data' with 'asset.url'
      const combined = {
        url: {
          url: this.form.asset.url,
          width: versionData.original.width,
          height: versionData.original.height,
          size: versionData.original.size,
        },
        ...asset,
      };

      for (const key of Object.keys(versionData)) {
        combined[key] = { ...combined[key], ...versionData[key] };
      }

      return combined;
    },
  },
};
</script>

<style scoped lang="scss"></style>
