<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr class="data-table__row">
            <td />
            <td class="w0 nowrap">
              {{
                item.created_at
                  | moment(
                    "timezone",
                    currentUser.active_project.timezone,
                    "MM/DD/YY h:mm A"
                  )
              }}
            </td>
            <td class="w0 nowrap">
              {{
                item.updated_at
                  | moment(
                    "timezone",
                    currentUser.active_project.timezone,
                    "MM/DD/YY h:mm A"
                  )
              }}
            </td>
            <td>
              <router-link :to="`/dev-tools/image-assets/${item.id}/edit`">
                {{ item.title }}
              </router-link>
            </td>
            <td class="text-center nowrap w0">
              <div class="image-asset-actions">
                <Actions
                  hide-duplicate
                  :path="`/dev-tools/image-assets/${item.id}`"
                  @destroy="$emit('destroy', item)"
                >
                  <template #prepend>
                    <v-btn icon @click.stop="copyAssetLinkToClipboard(item)">
                      <v-icon>$copy_link</v-icon>
                    </v-btn>
                  </template>
                </Actions>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { copyToClipboard } from "@/lib";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sentList: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Created Date",
          align: "start",
          value: "created_at",
        },
        {
          text: "Updated Date",
          align: "start",
          value: "updated_at",
        },
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        {
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    async sendImageAsset(image_asset) {
      await this.$api.imageAsset.sendImageAsset(image_asset);
    },
    copyAssetLinkToClipboard(item) {
      copyToClipboard(item.asset.url);
      this.$allertWindow("Link copied to Clipboard");
    },
  },
};
</script>

<style scoped lang="scss">
.image-asset-actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
</style>
