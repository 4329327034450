<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Notifications </template>
      <template v-slot:description>
        Notifications allow you to create and edit system generated alert that
        share information or drive users to another page via a call to action.
      </template>
      <template v-slot:image>
        <img
          src="@/assets/banners/widgets/notifications.svg"
          alt="Notifications"
        />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Notification"
      btn-to="/widgets/notifications/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Notifications"
      :search-text.sync="searchText"
    />

    <div v-if="list">
      <NotificationsDataTable
        v-if="computedList.length"
        :list="computedList"
        @destroy="destroy"
        @duplicate="duplicate"
      />
      <div v-else class="no-entries">No Notifications</div>
    </div>
    <Spinner v-else relative />
  </div>
</template>

<script>
import IndexPage from "../index-page";
import NotificationsDataTable from "@/components/dataview/notifications.datatable";

export default {
  name: "WidgetNotificationsPage",
  components: { NotificationsDataTable },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.Notification.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroy(item) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Notification.destroy({ id: item.id });
      this.list = this.list.filter((t) => t.id != item.id);
    },
    async duplicate(item) {
      await this.$api.Notification.duplicate({ id: item.id });
      this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss"></style>
