<template>
  <div class="register-page">
    <v-form ref="form" @submit.prevent="submit" class="register-page__form">
      <div class="register-page__title">CREATE ACCOUNT</div>
      <TextField
        :rules="[validation.email]"
        label="Email"
        v-model="form.email"
        type="email"
        ref="email"
      />
      <TextField
        :rules="[validation.password]"
        label="Password"
        v-model="form.password"
        type="password"
        @change="
          form.password_confirmation
            ? $refs.password_confirmation.validate()
            : null
        "
      />
      <TextField
        label="Password Confirmation"
        v-model="form.password_confirmation"
        type="password"
        ref="password_confirmation"
        :rules="[validation.passwordConfirmation(form.password)]"
      />
      <Checkbox v-model="form.agree_rules">
        <span>I agree to the</span>&nbsp;
        <a href="" target="_blank">Terms of Service</a>&nbsp;
        <span>and</span>&nbsp;
        <a href="" target="_blank">Privacy Policy</a>&nbsp;
      </Checkbox>
      <br />
      <v-btn
        :disabled="!form.agree_rules"
        type="submit"
        block
        color="primary"
        elevation="0"
        rounded
        large
      >
        Register
      </v-btn>
      <RecaptchaText />
      <div class="text-center mt-5">
        Already registered?
        <router-link to="/login">Log in</router-link>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        password: '',
        password_confirmation: '',
      },
    };
  },
  mounted() {
    window.$refs = this.$refs;
    window.form = this.$refs.form;
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        await this.$store.dispatch('auth/register', this.form);
        this.$router.push('/').catch(() => {});
      }
    },
  },
};
</script>

<style scoped lang="scss">
.register-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &__title {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    padding-bottom: 15px;
  }

  &__form {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    background: white;
    padding: 30px;
    max-width: 430px;
    width: 100%;
    margin: auto;

    &::v-deep {
      .text-field {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
}
</style>
