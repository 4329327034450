<template>
  <div>
    <div class="page-title">Dropbox Settings</div>
    <TextField label="App Key" v-model="localValue.data.app_key" />
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
};
</script>

<style></style>
