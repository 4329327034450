<template>
  <div>
    <Banner theme="purple">
      <template v-slot:title> Easy Updates </template>
      <template v-slot:description>
        Here are all the sections that are configured for easy text and image
        editing. Click the pencil to view and change each section. Make sure
        you're in staging to preview your changes and if everything looks good
        create a new release and push it to production with the "Releases"
        button.
      </template>
      <template v-slot:image> </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Easy Updates"
      btn-to="/variables/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Easy Updates"
      :search-text.sync="searchText"
    />

    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length" style="width: 100%">
        <VariableDataTable
          :list="computedList"
          @destroy="destroyVariable({ id: $event.id })"
        />
      </div>
      <div v-else class="no-entries">No Updates</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from "./index-page";
import VariableDataTable from "@/components/dataview/variable.datatable";

export default {
  name: "ChatBoxPage",
  components: {
    VariableDataTable,
  },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.Variable.lite({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroyVariable(variable) {
      const confimed = await this.$confirmWindow({
        title: "Are you sure?",
      });
      if (!confimed) return;

      await this.$api.Variable.destroy({ id: variable.id });
      this.list = this.list.filter((t) => t.id != variable.id);
    },
  },
};
</script>

<style scoped lang="scss"></style>
