<template>
  <div>
    <div v-if="list">
      <v-data-table
        v-if="list.length"
        :headers="headers"
        :items="list"
        item-key="id"
        show-expand
        class="elevation-0 data-table"
      >
        <template v-slot:body="{ items }">
          <tbody v-for="(item, index) in items" :key="item.id">
            <tr class="data-table__row">
              <td />
              <td>
                {{
                  item.datetime
                    | moment(
                      "timezone",
                      currentUser.active_project.timezone,
                      "MMM D h:mm A zz"
                    )
                }}
              </td>
              <td>
                <router-link :to="`/venue-events/${item.id}/edit`">
                  {{ item.title }}
                </router-link>
                <span v-if="item.hidden" class="data-table__row__cancelled"
                  >Hidden</span
                >
                <span v-if="item.offsale" class="data-table__row__cancelled"
                  >Offsale</span
                >
                <span v-if="item.cancelled" class="data-table__row__cancelled"
                  >Cancelled</span
                >
              </td>
              <td class="text-center nowrap">
                <Actions
                  hide-duplicate
                  :path="`/venue-events/${item.id}`"
                  @destroy="destroy(item)"
                >
                  <template #prepend>
                    <div
                      style="display: contents"
                      v-if="entity.sort_by == 'position'"
                    >
                      <v-btn
                        icon
                        @click="$emit('moveUp', index)"
                        :disabled="index == 0"
                      >
                        <v-icon>$arrow_up</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="$emit('moveDown', index)"
                        :disabled="index == items.length - 1"
                      >
                        <v-icon>$arrow_down</v-icon>
                      </v-btn>
                    </div>
                  </template>
                </Actions>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <div v-else class="no-entries">No Entries</div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  props: {
    entity: Object,
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Date/Time",
          align: "start",
          value: "datetime",
        },
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        {
          align: "center",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    async destroy(entry) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.ListEntry.destroy({ id: entry.id });

      this.list = this.list.filter((t) => t.id != entry.id);
    },
  },
};
</script>

<style scoped lang="scss">
.data-table__row__cancelled {
  font-weight: bold;
  font-style: italic;
  &::before {
    content: "\00a0\00a0";
  }
}
</style>
