<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Venues </template>
      <template v-slot:description>
        Venues are where Venue Events take place. They are pulled from
        Ticketmaster's API using the Ticketmaster Addon, and are used to import
        and organize Venue Events.
      </template>
      <!-- <template v-slot:image>
        <img
          src="@/assets/banners/widgets/content-blocks.svg"
          alt="Content Blocks"
        />
      </template> -->
    </Banner>

    <div v-if="list">
      <TicketmasterVenuesDatatable
        v-if="computedList.length"
        :list="computedList"
      />
      <div v-else class="no-entries">No Venues</div>
    </div>
    <Spinner v-else relative />
  </div>
</template>

<script>
import IndexPage from "./index-page";
import TicketmasterVenuesDatatable from "@/components/dataview/ticketmaster-venues.datatable";

export default {
  name: "TicketmasterVenuesPage",
  extends: IndexPage,
  components: { TicketmasterVenuesDatatable },
  methods: {
    async fetchList() {
      const { data } = await this.$api.TicketmasterVenue.get({ enabled: true });
      this.list = data;
      console.info("this.list", this.list);
    },
  },
};
</script>

<style scoped lang="scss"></style>
