<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Venue Info">
        <FormRow required label="Name">
          <TextField placeholder="Name" v-model="form.name" />
        </FormRow>
        <FormRow label="Short Name">
          <TextField placeholder="Short Name" v-model="form.short_name" />
        </FormRow>
        <FormRow
          label="Ticketmaster Link Branding Param"
          tooltip="This optional parameter will be added to “Buy Tickets” links when events are imported, to support custom headers on the Ticketmaster pages."
        >
          <TextField placeholder="Brand Param" v-model="form.brand_param" />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";

export default {
  extends: mixin,
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
