<template>
  <div>
    <Banner theme="green">
      <template v-slot:title> Text Blocks </template>
      <template v-slot:description>
        Text Blocks are big, graphic buttons. Each has a headline and
        description, but can also be paired with a unique background image.
        These can be linked to other pages on your Commons site or externally.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/widgets/text-blocks.svg" alt="Text Blocks" />
      </template>
    </Banner>
    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Text Block"
      btn-to="/widgets/text-blocks/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Text block"
      :search-text.sync="searchText"
    />

    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length">
        <ListPanel
          v-for="section in computedList"
          :key="section.id"
          :action-path="`/widgets/text-blocks/${section.id}`"
          :title="section.title"
          @destroy="destroyBlock(section)"
          @duplicate="duplicateBlock(section)"
        >
          <TextBlockDataGrid :layout="section.layout" :list="section.items" />
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Text Blocks</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from "../index-page";
import TextBlockDataGrid from "@/components/dataview/text-block.datagrid";

export default {
  name: "WidgetTextBlocksPage",
  components: { TextBlockDataGrid },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.TextBlock.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroyBlock(block) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.TextBlock.destroy({ id: block.id });
      this.list = this.list.filter((t) => t.id != block.id);
    },
    async duplicateBlock(block) {
      await this.$api.TextBlock.duplicate({ id: block.id });
      this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss"></style>
