<template>
  <div>
    <div class="page-title">Edit Entity</div>
    <Form :submit="update" v-model="form" v-if="form" btnText="Update Entity" />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.data-loader-entities.page";
export default {
  components: { Form },
  data() {
    return {
      form: {},
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.DataLoaderEntity.get({
        id: this.$route.params.id,
        schema_id: this.$route.params.schema_id,
      });
      this.form = data;
    },
    async update() {
      await this.$api.DataLoaderEntity.update(this.form);
      this.$allertWindow("Successfully updated");
    },
  },
};
</script>

<style></style>
