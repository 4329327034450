<template>
  <div>
    <div class="page-title">Theme Template</div>
    <Spinner v-if="!form" />
    <FullForm v-model="form" :submit="updateThemeTemplate" v-else />
  </div>
</template>

<script>
import FullForm from "./themes/full-form.page";
export default {
  components: {
    FullForm,
  },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.Theme.get({
        id: this.$route.params.id,
        template: true,
      });
      this.form = data;
    },
    async updateThemeTemplate() {
      await this.$api.Theme.update({ ...this.form, template: true });
      this.$allertWindow("Successfully updated");

      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style></style>
