<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Article Info">
        <FormRow required label="Title">
          <TextField
            :rules="[validation.required]"
            placeholder="Title"
            v-model="form.title"
          ></TextField>
        </FormRow>

        <FormRow required label="Preview">
          <Uploader :ratio="1000 / 750" v-model="form.preview" enableCropper />
        </FormRow>

        <FormRow required label="Preview Text">
          <TextArea
            placeholder="PreviewText"
            v-model="form.short_description"
            :rules="[validation.required]"
          ></TextArea>
        </FormRow>

        <FormRow label="Tags">
          <template #tooltip>
            Here you can assign your pre-exisitng "tags" to an article.
          </template>
          <BlogTagsSelector
            multiple
            v-model="form.blog_tag_ids"
          ></BlogTagsSelector>
        </FormRow>

        <FormRow required label="Publish Date">
          <DateTimePicker
            placeholder="Publish Date"
            v-model="form.publish_date"
            :rules="[validation.required]"
          ></DateTimePicker>
        </FormRow>
      </FormPanel>
      <h2 style="padding: 15px">Main Content</h2>
      <RichTextEditor v-model="form.description" />
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ btnText }}
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "@/pages/_extends/form.extends.js";

export default {
  extends: mixin,
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss"></style>
