<template>
  <div class="form-row">
    <div class="form-label">
      <span class="form-label-text">
        <slot name="label"> {{ label }} </slot>
        <span v-if="!clean">
          <span class="form-required" v-if="required">*</span>
        </span>
        <v-tooltip open-delay="500" v-if="!!tooltipExists" max-width="300" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" size="20" class="form-question"
              >$question_round</v-icon
            >
          </template>
          <span>
            <slot name="tooltip">
              {{ tooltip }}
            </slot>
          </span>
        </v-tooltip>
        <slot name="tooltip-next"></slot>
      </span>
      <div class="form-optional" v-if="!clean && !required">(optional)</div>
      <div class="form-additional">
        <slot name="additional"></slot>
      </div>
    </div>
    <div class="form-inputs">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    required: Boolean,
    clean: Boolean,
    empty: Boolean,
    tooltip: String,
  },
  computed: {
    tooltipExists() {
      return this.$slots.tooltip || this.tooltip;
    },
  },
};
</script>

<style scoped lang="scss">
.form {
  &-question {
  }
  &-row {
    padding: 10px 0;
    display: flex;
    width: 100%;
  }
  &-additional {
    font-size: 10px;
    color: #929292;
  }
  &-label {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.2;
    color: var(--v-text-base);
    min-width: 250px;
    margin-top: 15px;

    &-text {
      display: flex;
      align-items: top;
    }
  }
  &-inputs {
    width: 100%;
    padding: 0 30px;
  }

  &-required {
    color: var(--v-primary-base);
    margin-left: 3px;
    font-size: 14px;
  }

  &-optional {
    color: #ccc;
    text-transform: none;
  }

  &-question {
    margin-left: 5px;
    cursor: help;
    position: relative;
    top: -1px;
  }
}

@media screen and (max-width: 980px) {
  .form-row {
    flex-direction: column;
  }

  .form-inputs {
    padding: 5px 0;
  }
}
</style>
