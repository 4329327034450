<template>
  <div>
    <Banner theme="purple">
      <template v-slot:title> Content Management </template>
      <template v-slot:description>
        Content Management for Custom Components
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/pages.svg" alt="Content Management" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Schema"
      btn-to="/content-management/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search"
      :search-text.sync="searchText"
    />
    <div class = "page-subtitle"> Stickied </div>
    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length" style="width: 100%">
        <ListPanel
          class = "sticky-panels"
          v-for="schema in filteredSticky"
          :key="schema.id"
          :action-path="`/content-management/${schema.int_id}`"
          :title="schema.title"
          @destroy="destroySchema(schema)"
          @duplicate="duplicateSchema(schema)"
          :type="schema.schema_type"
        >
          <template #addAction>
            <v-btn
              :to="`/content-management/${schema.int_id}/entities/new`"
              color="primary"
              elevation="0"
              rounded
              v-if="['regular', 'page'].includes(schema.schema_type)"
            >
              <slot name="btn">
                <v-icon>$plus</v-icon>
                <span class="content-blocks__new-block-btn-text">
                  Add “{{ schema.title }}”
                </span>
              </slot>
            </v-btn>
            <v-btn
              :to="`/content-management/${schema.int_id}/entities/${schema.entities[0].int_id}/edit`"
              color="primary"
              elevation="0"
              rounded
              v-if="schema.schema_type === 'singleton'"
            >
              <slot name="btn">
                <v-icon>$pencil</v-icon>
                <span class="content-blocks__new-block-btn-text">
                  Update “{{ schema.title }}”
                </span>
              </slot>
            </v-btn>
          </template>
          <DataLoaderEntitiesTable
            v-if="schema.entities.length"
            show-more
            :entity="schema"
            :list="schema.entities"
            @up="up(schema, $event)"
            @down="down(schema, $event)"
            @destroy="deleteEntity({ id: $event.id, schema_id: schema.id })"
            :type="schema.schema_type"
            @fetch-list="fetchList"
          />
          <div v-else class="no-entries">No Entities</div>
        </ListPanel>
      </div>

      <div style="height: 40px;"> </div>

      <div v-if="computedList && computedList.length" style="width: 100%">
        <ListPanel
          v-for="schema in filteredNotSticky"
          :key="schema.id"
          :action-path="`/content-management/${schema.int_id}`"
          :title="schema.title"
          @destroy="destroySchema(schema)"
          @duplicate="duplicateSchema(schema)"
          :type="schema.schema_type"
        >
          <template #addAction>
            <v-btn
              :to="`/content-management/${schema.int_id}/entities/new`"
              color="primary"
              elevation="0"
              rounded
              v-if="['regular', 'page'].includes(schema.schema_type)"
            >
              <slot name="btn">
                <v-icon>$plus</v-icon>
                <span class="content-blocks__new-block-btn-text">
                  Add “{{ schema.title }}”
                </span>
              </slot>
            </v-btn>
            <v-btn
              :to="`/content-management/${schema.int_id}/entities/${schema.entities[0].int_id}/edit`"
              color="primary"
              elevation="0"
              rounded
              v-if="schema.schema_type === 'singleton'"
            >
              <slot name="btn">
                <v-icon>$pencil</v-icon>
                <span class="content-blocks__new-block-btn-text">
                  Update “{{ schema.title }}”
                </span>
              </slot>
            </v-btn>
          </template>
          <DataLoaderEntitiesTable
            v-if="schema.entities.length"
            show-more
            :entity="schema"
            :list="schema.entities"
            @up="up(schema, $event)"
            @down="down(schema, $event)"
            @destroy="deleteEntity({ id: $event.id, schema_id: schema.id })"
            :type="schema.schema_type"
            @fetch-list="fetchList"
          />
          <div v-else class="no-entries">No Entities</div>
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Schemas</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from "./index-page";
import DataLoaderEntitiesTable from "@/components/dataview/data-loader-entities.datatable";

export default {
  name: "ProjectDataPage",
  components: {
    DataLoaderEntitiesTable,
  },
  extends: IndexPage,
  computed: {
    filteredNotSticky() {
      return this.computedList.filter(schema => !schema.is_sticky);
    },
    filteredSticky() {
      return this.computedList.filter(schema => schema.is_sticky);
    }
  },
  methods: {
    async up(schema, id) {
      await this.$api.DataLoaderEntity.rearrange({
        id,
        direction: "up",
        schema_id: schema.id,
      });
      this.fetchList();
    },
    async down(schema, id) {
      await this.$api.DataLoaderEntity.rearrange({
        id,
        direction: "down",
        schema_id: schema.id,
      });
      this.fetchList();
    },
    async duplicateSchema({ id }) {
      await this.$api.DataLoaderSchema.duplicate({ id });
      this.fetchList();
    },
    async fetchList() {
      const { data } = await this.$api.DataLoaderSchema.get({
        sort_by: this.sortBy,
      });
      if (["super_admin"].includes(this.currentUser.active_team_role)) {
        this.list = data;
        return;
      }
      this.list = data.filter((t) => !t.internal);
    },
    async destroySchema(schema) {
      const confimed = await this.$confirmWindow({
        title: "Are you sure?",
      });
      if (!confimed) return;

      await this.$api.DataLoaderSchema.destroy({ id: schema.id });
      this.list = this.list.filter((t) => t.id != schema.id);
    },
    async deleteEntity({ id, schema_id }) {
      const confimed = await this.$confirmWindow({
        title: "Are you sure?",
      });
      if (!confimed) return;
      await this.$api.DataLoaderEntity.destroy({ id, schema_id });
      this.list.forEach((schema) => {
        if (schema.id == schema_id) {
          schema.entities = schema.entities.filter((t) => t.id != id);
        }
      });
      this.list = [...this.list];
    },
  },
};
</script>

<style scoped lang="scss">
  .stickied-title {
    font-weight: bold;
    padding-bottom: 20px;
  }
</style>
