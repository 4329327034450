<template>
  <div class="login-page">
    <v-form @submit.prevent="submit" class="login-page__form">
      <div class="login-page__title">LOG IN TO PLATFORM</div>
      <TextField label="Email" v-model="form.email" type="email" />
      <TextField label="Password" v-model="form.password" type="password" />
      <v-btn type="submit" block color="primary" elevation="0" rounded large>
        Login
      </v-btn>
      <v-layout align-center justify-space-between>
        <Checkbox label="Keep me logged in" v-model="form.keep_session" />
        <router-link to="/forgot-password">Forgot password?</router-link>
      </v-layout>
      <div class="text-center mt-5">
        Don't have an account?
        <router-link to="/register">Register</router-link>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  methods: {
    async submit() {
      await this.$store.dispatch("auth/login", this.form);
      this.$router.push("/").catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.login-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;

  &__title {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    padding-bottom: 15px;
  }

  &__form {
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    background: white;
    padding: 30px;
    max-width: 400px;
    width: 100%;

    &::v-deep {
      .text-field {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
}
</style>
