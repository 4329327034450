<template>
  <div>
    <div class="join-page-wrapper" v-if="invite">
      <div class="join-page-dialog" :class="{ wide: !currentUser }">
        <b>{{ invite.who }}</b>
        <br />
        has invited you to join the team
        <br />
        <b> "{{ invite.team_name }}" </b>
        <div v-if="currentUser">
          <div v-if="invite.status == 'sent'" class="join-page-dialog__actions">
            <v-btn @click="reject" large outlined rounded>Reject</v-btn>
            <v-btn
              @click="accept"
              large
              class="elevation-0"
              color="primary"
              rounded
              >Join</v-btn
            >
          </div>
        </div>
        <div v-else>
          <br />
          <v-divider></v-divider>
          <br />

          <v-form
            v-if="invite.user_exists"
            @submit.prevent="login"
            ref="loginForm"
          >
            <div class="join-fields">
              <div class="join-field">
                <TextField
                  label="Email"
                  v-model="loginForm.email"
                  :rules="[validation.required]"
                ></TextField>
              </div>

              <div class="join-field">
                <TextField
                  label="Password"
                  type="password"
                  v-model="loginForm.password"
                  :rules="[validation.required]"
                ></TextField>
              </div>
            </div>

            <div class="join-page-dialog__actions">
              <v-btn color="primary" class="elevation-0" large type="submit"
                >Sign In</v-btn
              >
            </div>
            <div class="forgot-pw">
              <router-link to="/forgot-password">Forgot password?</router-link>
            </div>
          </v-form>
          <v-form v-else @submit.prevent="register" ref="registerForm">
            <div class="join-fields">
              <div class="join-field">
                <TextField
                  label="First Name"
                  v-model="registerForm.first_name"
                  :rules="[validation.required]"
                ></TextField>
              </div>

              <div class="join-field">
                <TextField
                  label="Last Name"
                  v-model="registerForm.last_name"
                  :rules="[validation.required]"
                ></TextField>
              </div>

              <div class="join-field">
                <TextField
                  label="Company"
                  v-model="registerForm.company"
                ></TextField>
              </div>

              <div class="join-field">
                <TextField
                  label="Title"
                  v-model="registerForm.title"
                ></TextField>
              </div>
              <div class="join-field">
                <TextField
                  type="password"
                  label="Password"
                  v-model="registerForm.password"
                ></TextField>
              </div>
              <div class="join-field">
                <TextField
                  type="password"
                  label="Password Confirmation"
                  v-model="registerForm.password_confirmation"
                ></TextField>
              </div>
            </div>
            <Checkbox v-model="registerForm.agree_rules">
              <span>I agree to the</span>&nbsp;
              <a href="" target="_blank">Terms of Service</a>&nbsp;
              <span>and</span>&nbsp;
              <a href="" target="_blank">Privacy Policy</a>&nbsp;
            </Checkbox>
            <div class="join-page-dialog__actions">
              <v-btn
                :disabled="!registerForm.agree_rules"
                color="primary"
                class="elevation-0"
                large
                type="submit"
                >Join</v-btn
              >
            </div>
          </v-form>
        </div>
      </div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      invite: null,
      token: this.$route.params.token,
      registerForm: {},
      loginForm: {},
    };
  },
  created() {
    this.setInvite();
  },
  methods: {
    async register() {
      if (!this.$refs.registerForm.validate()) return;

      const { data } = await this.$api.Invite.register({
        ...this.registerForm,
        token: this.token,
      });

      const loginParams = {
        email: data.email,
        password: this.registerForm.password,
      };

      await this.$store.dispatch("auth/login", loginParams);
      this.$router.push("/");
    },
    async login() {
      if (!this.$refs.loginForm.validate()) return;

      await this.$store.dispatch("auth/login", this.loginForm);
    },
    async setInvite() {
      try {
        const { data } = await this.$api.Invite.get({
          token: this.token,
        });
        if (data.status == "sent") {
          this.invite = data;
        } else {
          this.$router.push("/");
        }
      } catch {
        this.$router.push("/");
      }
    },
    async accept() {
      await this.$api.Invite.accept({ token: this.token });
      this.$router.push("/");
    },
    async reject() {
      await this.$api.Invite.reject({ token: this.token });
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
.join-field {
  margin-bottom: 10px;
  padding: 10px;
}

.join-fields {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.forgot-pw {
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.join-page {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    padding: 40px;
  }
  &-dialog {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
    max-width: 400px;
    background: white;
    margin: auto;
    font-size: 24px;
    text-align: center;
    color: black;

    &.wide {
      max-width: 620px;
    }

    &__actions {
      display: flex;
      border-top: 1px solid #dedede;
      justify-content: space-around;
      padding-top: 20px;
      margin-top: 40px;
    }
  }
}
</style>
