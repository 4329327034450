<template>
  <div>
    <v-form ref="form">
      <FormPanels>
        <FormPanel label="Site Info">
          <FormRow
            tooltip="This is the name your site will be referenced as on the Admin portal."
            required
            label="Site Name"
          >
            <TextField
              v-model="form.title"
              placeholder="Site Name"
              :rules="[validation.required]"
            />
          </FormRow>
          <FormRow
            tooltip="This is a browser tab title we use by default."
            label="Site Headline"
          >
            <TextField v-model="form.headline" placeholder="Site Headline" />
          </FormRow>
          <FormRow
            tooltip="A quick overview of this site and its purpose."
            label="Site Description"
          >
            <TextArea
              v-model="form.description"
              placeholder="Site Description"
            />
          </FormRow>
          <FormRow
            label="Site Meta Tags"
            tooltip="These will be the default meta tags for each page on your site, if no meta tags specified on individual pages."
          >
            <MetaTagsEditor v-model="form.meta_tags" />
          </FormRow>
          <FormRow
            tooltip="This will be used on the Admin portal to help identify this project, and in the header of some transactional emails."
          >
            <template #label> Site Logo<br />(200x200) </template>
            <Uploader v-model="form.preview" :ratio="1" enableCropper />
          </FormRow>
        </FormPanel>
        <FormPanel label="Site Setup">
          <FormRow
            :required="form.restrict_production_access"
            label="Live Site URL"
            tooltip="This will be the public URL for your Commons site."
          >
            <v-layout align-center>
              <span class="mr-3">https://</span>
              <div>
                <TextField
                  v-model="form.production_domain"
                  placeholder="example.com"
                  :rules="
                    form.restrict_production_access
                      ? [
                          validation.required,
                          validation.project.productionDomain,
                        ]
                      : [validation.project.productionDomain]
                  "
                />
              </div>
            </v-layout>
          </FormRow>
          <div v-if="form.production_domain">
            <FormRow label="Redirect to another Website">
              <RadioGroup
                v-model="form.production_redirect_enabled"
                :items="[
                  { title: 'No', value: false },
                  { title: 'Yes', value: true },
                ]"
              />
            </FormRow>
            <FormRow
              v-if="form.production_redirect_enabled"
              required
              label="Redirect URL"
            >
              <TextField
                v-model="form.production_redirect_url"
                placeholder="Redirect URL"
                :rules="[validation.required, validation.url]"
              />
            </FormRow>
          </div>
          <FormRow label="Password Protect Live Site">
            <template #tooltip>
              Select "Yes" if you want to have password access to the URL above.
              Note, this is not user authentication, purely domain access.
            </template>
            <RadioGroup
              v-model="form.restrict_production_access"
              :items="[
                { title: 'No', value: false },
                { title: 'Yes', value: true },
              ]"
            />
          </FormRow>
          <div v-if="form.restrict_production_access">
            <FormRow
              required
              label="Live Site Access Username"
              tooltip="The username to access the Live Site URL"
            >
              <TextField
                v-model="form.production_login"
                placeholder="Live Site Access Username"
                :rules="[validation.required]"
              />
            </FormRow>
            <FormRow
              required
              label="Live Site Access Password"
              tooltip="The password to access the Live Site URL"
            >
              <TextField
                v-model="form.production_password"
                placeholder="Live Site Access Password"
                :rules="[validation.required]"
                type="password"
              />
            </FormRow>
          </div>
          <br />
          <v-divider />
          <br />
          <FormRow
            required
            label="Staging Site URL Prefix"
            tooltip="This will be the staging url for your Commons site. If the staging domain isn't working, please contact the iU web dev team to set it up."
          >
            <v-layout align-center>
              <span class="mr-3">https://</span>
              <div>
                <TextField
                  v-model="form.staging_domain"
                  placeholder="example"
                  :rules="[
                    validation.required,
                    validation.project.stagingDomain,
                  ]"
                />
              </div>
              <span class="mr-3">.{{ rootDomain }}</span>
            </v-layout>
          </FormRow>
          <FormRow label="Password Protect Staging Site">
            <template #tooltip>
              Select "Yes" if you want to have password access to the URL above.
              Note, this is not user authentication, purely domain access.
            </template>
            <RadioGroup
              v-model="form.restrict_staging_access"
              :items="[
                { title: 'No', value: false },
                { title: 'Yes', value: true },
              ]"
            />
          </FormRow>
          <div v-if="form.restrict_staging_access">
            <FormRow required label="Staging Site  Access Username">
              <template #tooltip>
                The username to access the Staging Site URL
              </template>
              <TextField
                v-model="form.staging_login"
                placeholder="Staging Site  Access Username"
                :rules="[validation.required]"
              />
            </FormRow>
            <FormRow required label="Staging Site Access Password">
              <template #tooltip>
                The password to access the Staging Site URL
              </template>
              <TextField
                v-model="form.staging_password"
                placeholder="Staging Site Access Password"
                :rules="[validation.required]"
              />
            </FormRow>
          </div>
          <v-divider />

          <FormRow
            label="Alternative Home URL"
            tooltip="If the home links in the header, footer, and mobile navigation menu should link to a URL other than the root URL of this site, put that URL here."
          >
            <v-layout align-center>
              <span class="mr-3">https://</span>
              <div>
                <TextField
                  v-model="form.alt_home_url"
                  placeholder="example.com"
                />
              </div>
            </v-layout>
          </FormRow>
        </FormPanel>
        <FormPanel label="Social Media">
          <FormRow label="LinkedIn" tooltip="Enter your LinkedIN handle here.">
            <TextField v-model="form.linkedin" placeholder="LinkedIn" />
          </FormRow>
          <FormRow label="Facebook" tooltip="Enter your Facebook handle here.">
            <TextField v-model="form.facebook" placeholder="Facebook" />
          </FormRow>
          <FormRow label="TikTok" tooltip="Enter your TikTok handle here.">
            <TextField v-model="form.ticktok" placeholder="TikTok" />
          </FormRow>
          <FormRow
            label="Instagram"
            tooltip="Enter your Instagram handle here."
          >
            <TextField v-model="form.instagram" placeholder="Instagram" />
          </FormRow>
          <FormRow label="Twitter" tooltip="Enter your Twitter handle here.">
            <TextField v-model="form.twitter" placeholder="Twitter" />
          </FormRow>
        </FormPanel>

        <FormPanel label="Site Details">
          <FormRow
            required
            label="Base Timezone"
            tooltip="This is the timezone in which all dates & times for your project will be linked."
          >
            <TimezoneSelector
              v-model="form.timezone"
              :rules="[validation.required]"
            />
          </FormRow>
          <FormRow
            label="Display Local Timezone"
            tooltip="Select this if event and schedule times should be shown in the site visitor's local timezone, such as for a nation-wide or international event."
          >
            <Checkbox
              class="form-builder__required"
              v-model="form.display_local_timezone"
            />
          </FormRow>
          <FormRow
            label="Copyright Text"
            tooltip="This will display text in the Footer of your site, commonly used for any copyright language."
          >
            <TextArea v-model="form.copyright" />
          </FormRow>
          <FormRow
            label="Disable Online Events"
            tooltip="This will hide the “Events” menu from the sidebar in admin, for sites that do not use online event functionality."
            v-if="currentUser.active_team_role == 'super_admin'"
          >
            <Checkbox v-model="form.disable_online_events" />
          </FormRow>
        </FormPanel>
        <FormPanel
          v-if="currentUser.active_team_role == 'super_admin'"
          label="Storage"
        >
          <FormRow label="Storage Used" clean>
            <div class="layout align-center" style="margin-top: 15px">
              {{ Math.round(form.storage_amount / 1048576) }} MB
            </div>
          </FormRow>
          <FormRow label="Storage Limit (MB)" clean>
            <TextField
              :value="Math.round(form.storage_limit / 1048576)"
              placeholder="Storage Limit"
              :rules="[validation.storageLimit]"
              @input="updateStorageLimit($event)"
            />
          </FormRow>
        </FormPanel>
      </FormPanels>
      <SubmitFooter v-if="btnText" :refs="$refs" :submit="submit">
        {{ btnText }}
        <template #actions>
          <div v-if="form.id && currentUser.active_team_role == 'super_admin'">
            <!-- <v-btn
              outlined
              color="primary"
              elevation="0"
              rounded
              large
              @click="duplicate"
            >
              Duplicate
            </v-btn> -->
            <v-btn
              outlined
              color="primary"
              elevation="0"
              rounded
              large
              @click="destroy"
            >
              Archive
            </v-btn>
          </div>
        </template>
      </SubmitFooter>
    </v-form>
    <!-- <ProjectDuplicateDialog /> -->
  </div>
</template>

<script>
// import _ from "lodash";
import mixin from "../_extends/form.extends.js";
// import ProjectDuplicateDialog from "@/components/ProjectDuplicateDialog.vue";

export default {
  extends: mixin,
  // components: { ProjectDuplicateDialog },
  data() {
    return {
      rootDomain: process.env.VUE_APP_ROOT_DOMAIN,
    };
  },
  methods: {
    updateStorageLimit($event) {
      this.form.storage_limit = $event * 1048576;
    },
    async destroy() {
      if (this.currentUser.active_team_role != "super_admin") return;
      const confirmed = await this.$confirmWindow({
        title: "Are you sure?",
        description:
          "This will archive the project, making it inaccessible. After 30 days, the project and all its data will be permanently deleted.",
      });
      if (!confirmed) return;
      await this.$api.Project.archive({ id: this.form.id });
      this.$router.push("/projects");
    },
    /*
    async duplicate() {
      if (this.currentUser.active_team_role != "super_admin") return;

      const projectParams = _.pick(this.form, [
        "id",
        "title",
        "staging_domain",
        "production_domain",
        "restrict_production_access",
        "production_redirect_enabled",
        "production_redirect_url",
        "production_login",
        "production_password",
        "restrict_staging_access",
        "staging_login",
        "staging_password",
      ]);
      await new Promise((resolve) => {
        this.$root.$emit("ProjectDuplicateDialog", {
          project: projectParams,
          callback: (value) => {
            resolve(value);
            //this.$router.go(`/projects/${value.project_id}/edit`);
            this.$router.push("/projects");
          },
        });
      });
    },
    */
  },
};
</script>

<style></style>
