<template>
  <div>
    <div class="page-title">Ticketmaster Settings</div>

    <div class="venue-lines">
      <div
        class="venue-line"
        v-for="(venue, idx) in localValue.data.venue_ids"
        :key="idx"
      >
        <div class="venue-select">
          <TicketmasterVenueSelector
            v-model="localValue.data.venue_ids[idx]"
            @input="refreshVenueIds()"
          />
        </div>
        <v-btn color="primary" fab x-small @click="deleteVenueId(idx)" class="venue-line-delete">
          <v-icon>$delete</v-icon>
        </v-btn>
      </div>

      <v-btn color="primary" outlined rounded @click="addVenueId"
        >+ Add A Venue</v-btn
      >
    </div>
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
  created() {
    if (!this.localValue.data.venue_ids) {
      this.localValue.data.venue_ids = [];
    }
  },
  methods: {
    addVenueId() {
      this.localValue.data.venue_ids = this.localValue.data.venue_ids || [];
      this.localValue.data.venue_ids.push("");
      this.localValue = { ...this.localValue };
    },
    deleteVenueId(idx) {
      this.localValue.data.venue_ids.splice(idx, 1);
      this.localValue = { ...this.localValue };
    },
    refreshVenueIds() {
      this.localValue = { ...this.localValue };
    }
  },
};
</script>

<style scoped lang="scss">
.venue-lines {
  background: #f7f7f7;
  padding: 10px;
  border: 1px solid #e7e7e7;
}
.venue-line {
  display: flex;
  margin-bottom: 20px;

  &::v-deep {
    .text-field {
      margin: 0;
      margin-right: 15px;
    }

    fieldset {
      background: white;
    }
  }
}

.venue-select {
  flex-grow: 1;
}

.venue-line-delete {
  margin-top: 1em;
}

.env-production {
  .venue-lines {
    background: #262729!important;
    border: none;
  }
}
</style>
