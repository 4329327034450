<template>
  <div></div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
};
</script>

<style></style>
