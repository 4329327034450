<template>
  <div>
    <div class="richtext-title">
      <h2 class="richtext-title__title">{{ settings.title }}</h2>
      <v-tooltip
        open-delay="500"
        v-if="settings.helper_text"
        max-width="300"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" size="20" class="form-question"
            >$question_round</v-icon
          >
        </template>
        <span>
          <slot name="tooltip">
            {{ settings.helper_text }}
          </slot>
        </span>
      </v-tooltip>
    </div>
    <RichTextEditor
      @input="$emit('input', $event)"
      :value="value"
      :label="settings.title"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    settings: Object,
  },
};
</script>

<style scoped lang="scss">
.richtext-title {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
</style>
