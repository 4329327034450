<template>
  <v-app>
    <v-main>
      <vue-page-transition>
        <router-view />
      </vue-page-transition>
    </v-main>
    <v-btn fab fixed bottom right>
      <v-icon>$question</v-icon>
    </v-btn>
    <ConfirmDialog />
    <Alert />
  </v-app>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#app {
  background: var(--v-background-base);
  font-family: "Source Sans Pro", sans-serif;
  color: var(--v-text-base);
}
</style>
