<template>
  <div>
    <TextArea placeholder="Label" v-model="localValue.label" />
    <Checkbox
      class="checkbox-input-checkbox"
      label="Radio Style"
      v-model="localValue.radio_style"
    />
  </div>
</template>

<script>
import mixin from "./form-builder.mixin.js";
export default {
  extends: mixin,
};
</script>

<style lang="scss" scoped>
.checkbox-input-checkbox {
  margin-top: -1px;
  margin-left: 20px;
}
</style>
