<template>
  <div class="projects-page">
    <v-btn
      :to="`/teams/${currentUser.active_team.id}/edit`"
      class="pull-right"
      large
      outlined
      color="primary"
      v-if="['super_admin', 'admin'].includes(currentUser.active_team_role)"
      >Edit Team</v-btn
    >
    <div class="page-title">Projects</div>
    <div class="projects">
      <!-- <router-link to="/projects/new" class="project project--new">
        <v-icon size="36" color="white">$plus</v-icon>
        <div class="project__title">New Project</div>
      </router-link> -->
      <component
        :class="{
          active:
            currentUser.active_project &&
            currentUser.active_project.id == project.id,
        }"
        v-for="project in activeProjects"
        :key="project.id"
        class="project"
        :to="`/projects/${project.id}/edit`"
        :is="
          ['super_admin', 'admin'].includes(currentUser.active_team_role)
            ? 'router-link'
            : 'div'
        "
      >
        <div
          class="project__actions"
          v-if="['super_admin', 'admin'].includes(currentUser.active_team_role)"
        >
          <v-btn icon :to="`/projects/${project.id}/edit`">
            <v-icon>$cog</v-icon>
          </v-btn>
        </div>
        <div
          class="project__preview"
          :style="{ backgroundImage: `url(${project.preview.thumb.url})` }"
        >
          <Avatar
            v-if="!project.preview.thumb.url"
            :id="project.id"
            :name="project.title"
          />
          <v-icon class="project__selected" color="primary"
            >$circle_checkbox_on</v-icon
          >
        </div>
        <div class="project__container">
          <div class="project__title">{{ project.title }}</div>
          <div class="project__description">
            by <u style="color: #8e8989">{{ project.team_name }}</u>
            <!-- <div v-if="project.description" :title="project.description">
              {{ project.description }}
            </div>
            <div v-else>
              No description
            </div> -->
          </div>

          <div class="project__overlay">
            <v-btn outlined @click.prevent="selectProject(project)"
              >Go to Project</v-btn
            >
          </div>
        </div>
      </component>
    </div>
    <div
      v-if="
        currentUser.active_team_role == 'super_admin' &&
        archivedProjects &&
        archivedProjects.length
      "
    >
      <div class="page-title">Archived Projects</div>
      <div class="projects">
        <div
          :class="{
            active:
              currentUser.active_project &&
              currentUser.active_project.id == project.id,
          }"
          v-for="project in archivedProjects"
          :key="project.id"
          class="project project-archived"
          :to="`/projects/${project.id}/edit`"
        >
          <div
            class="project__preview"
            :style="{ backgroundImage: `url(${project.preview.thumb.url})` }"
          >
            <Avatar
              v-if="!project.preview.thumb.url"
              :id="project.id"
              :name="project.title"
            />
          </div>
          <div class="project__container">
            <div class="project__title">{{ project.title }}</div>
            <div class="project__description">
              by <u style="color: #8e8989">{{ project.team_name }}</u>
              <br />
              Will be deleted on
              <span>{{
                willBeDeletedOn(project.archived_at)
                  | moment(
                    "timezone",
                    currentUser.active_project
                      ? currentUser.active_project.timezone
                      : "America/New_York",
                    "MM/DD"
                  )
              }}</span>
            </div>

            <div class="project__overlay">
              <v-btn outlined @click.prevent="unarchiveProject(project)"
                >Restore</v-btn
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndexPage from "./index-page";

export default {
  name: "ProjectsPage",
  extends: IndexPage,
  created() {
    this.fetchList();

    this.$root.$on("projects/reload", () => {
      this.fetchList();
    });
  },
  beforeDestroy() {
    this.$root.$off("projects/reload");
  },
  methods: {
    async fetchList() {
      const { data } = await this.$api.Project.get();
      // if (!data.length) {
      //   return this.$router.push("/projects/new");
      // }
      this.list = data;
    },
    async selectProject(project) {
      await this.$api.Project.makeActive({ id: project.id });
      this.$root.$emit("updateEnv", "staging");
      this.$router.push("/");
    },
    async unarchiveProject(project) {
      const confirmed = await this.$confirmWindow({
        title: "Are you sure?",
        description:
          "This project is archived and scheduled to be destroyed soon. Are you sure you want to restore it?",
      });
      if (!confirmed) return;
      await this.$api.Project.unarchive({ id: project.id });
      this.fetchList();
    },
    willBeDeletedOn(archived_at) {
      let d = new Date(archived_at);
      d.setDate(d.getDate() + 30);
      return d;
    },
  },
  computed: {
    activeProjects() {
      return this.list?.filter((p) => !p.archived);
    },
    archivedProjects() {
      return this.list?.filter((p) => p.archived);
    },
  },
};
</script>

<style scoped lang="scss">
.projects {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -20px;
}

.projects-page {
  max-width: 1200px;
  margin: auto;
}

a.project {
  cursor: pointer;
}

.project {
  margin: 40px 20px;
  background: white;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: 0.3s;
  position: relative;
  color: var(--v-text-base);
  text-decoration: none;

  &--new {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: var(--v-primary-base);

    &:hover {
      filter: brightness(105%);
    }
  }
  &--new &__title {
    color: white;
    font-size: 16px;
    margin-top: 0;
  }

  &.active &__preview {
    border-color: var(--v-primary-base);
  }

  &:hover {
    box-shadow: 0px 0px 5px 3px rgba(0, 0, 0, 0.05);
  }
  &:hover &__description {
    color: var(--v-text-base);
  }

  &__actions {
    position: absolute;
    top: -35px;
    right: 0;
    opacity: 0;
    transition: 0.3s;
  }

  &:hover &__actions {
    opacity: 1;
  }

  &__selected {
    position: absolute;
    top: -5px;
    right: -5px;
    background: white;
    border-radius: 50%;
    display: none;
  }

  &.active &__selected {
    display: flex;
  }

  &__container {
    overflow: hidden;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 15px;
  }

  &__preview {
    position: relative;
    width: 80px;
    height: 80px;
    background: #e7e7e7;
    border-radius: 50%;
    border: 5px solid white;
    display: inline-block;
    margin-top: -40px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.05);
    background-size: cover;
    background-position: center;

    &::v-deep {
      .avatar {
        position: absolute;
        left: 0;
        right: 0;
        width: 70px;
        height: 70px;
        font-size: 24px;
      }
    }
  }
  &__title {
    font-weight: bold;
    font-size: 14px;
    margin-top: 10px;
    line-height: 1.2;
    padding: 0 15px;
    height: 32px;
    overflow: hidden;
  }

  &__description {
    font-size: 12px;
    // text-align: left;
    padding: 15px;
    line-height: 1.2;
    color: #a0a0a0;
    transition: 0.3s;
    min-height: 58px;
    overflow: hidden;
  }
}
</style>
