<template>
  <div>
    <v-data-table
      :headers="headers"
      :hide-default-footer="showMore"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr class="data-table__row">
            <td />

            <td class="nowrap">
              {{
                item.publish_date
                  | moment(
                    "timezone",
                    currentUser.active_project.timezone,
                    "MM/DD/YY h:mm A"
                  )
              }}
            </td>
            <td>
              <router-link :to="`/blog-articles/${item.id}/edit`">
                {{ item.title }}
              </router-link>
            </td>
            <td>
              {{ item.short_description }}
            </td>
            <td>
              {{ item.blog_tags }}
            </td>
            <td class="text-center nowrap">
              <Actions
                hide-duplicate
                :path="`/blog-articles/${item.id}`"
                @destroy="$emit('destroy', item)"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
    <router-link
      v-if="showMore"
      :to="`/blog-articles/${entity.id}`"
      class="view-all"
    >
      View All Articles
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    showMore: Boolean,
    entity: Object,
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Date",
          align: "start",
          value: "created_at",
          sortable: true,
        },
        {
          text: "Title",
          align: "start",
          value: "title",
        },
        {
          text: "Short Description",
          align: "start",
          value: "body",
          sortable: false,
        },
        {
          text: "Tags",
          align: "start",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style></style>
