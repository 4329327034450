<template>
  <div>
    <Banner theme="yellow" text-color="#56565A">
      <template v-slot:title> Registration Forms </template>
      <template v-slot:description>
        Registration Forms helps you collect information about users with
        configurable forms. Users can use these forms to set up new accounts and
        roles, giving you greater insight and control of the site experience at
        the user level.
      </template>
      <template v-slot:image>
        <img
          src="@/assets/banners/widgets/user-generated-content.svg"
          alt="Registration Forms"
        />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Registration Form"
      btn-to="/widgets/registration-forms/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Registration Form"
      :search-text.sync="searchText"
    />

    <div class="page-subtitle">Registration Forms</div>

    <div v-if="computedList && computedList.length">
      <ListPanel
        v-for="section in computedList"
        :key="section.id"
        :action-path="`/widgets/registration-forms/${section.id}`"
        :title="section.title"
        @destroy="destroy(section)"
        @duplicate="duplicate(section)"
        :hide-expand="true"
      >
      </ListPanel>
    </div>
    <div v-else class="no-entries">No Registration Forms</div>
  </div>
</template>

<script>
import IndexPage from "../index-page";

export default {
  name: "WidgetRegistrationFormsPage",
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.RegistrationForm.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroy(form) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.RegistrationForm.destroy({ id: form.id });
      this.list = this.list.filter((t) => t.id != form.id);
    },
    async duplicate(form) {
      await this.$api.RegistrationForm.duplicate({ id: form.id });
      this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss"></style>
