<template>
  <div>
    <Banner theme="purple">
      <template v-slot:title>Theme</template>
      <template v-slot:description
        >Themes allow you to change the appearance of your Commons Page(s).
        Colors, fonts, default images, and more can be customized
        here.</template
      >
      <template v-slot:image>
        <img src="@/assets/banners/theme.svg" alt="Theme" />
      </template>
    </Banner>

    <ThemesTabs ref="themeTabs" @open="openTheme($event)" />
    <Spinner v-if="!form" />

    <FullForm v-model="form" :submit="updateTheme" v-else />
  </div>
</template>

<script>
import FullForm from "./themes/full-form.page";
import { mapState } from "vuex";

export default {
  name: "ThemePage",
  components: {
    FullForm,
  },
  data() {
    return {
      form: null,
    };
  },
  methods: {
    async updateTheme(additional) {
      await this.$api.Theme.update(this.form, additional);
      this.$allertWindow("Successfully updated");

      /*window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });*/

      this.$refs.themeTabs.setThemes();
    },
    async setTheme(id, params = {}) {
      try {
        const { data } = await this.$api.Theme.get({ id });

        if (params.fields) {
          for (let field of params.fields) {
            this.form = { ...this.form, [field]: data[field] };
          }
        } else {
          this.form = data;
        }
      } catch {
        this.$router.push("/?redirect=/themes");
      }
    },
    openTheme(id) {
      if (id && id != this.$route.params.id) {
        this.$router.push(`/themes/${id}`);
      }
      if (this.form && this.form.id == id) {
        this.setTheme(id, { fields: ["title"] });
      } else {
        this.setTheme(id);
      }
    },
  },
  computed: {
    ...mapState({
      datasources: (state) => state.datasources,
    }),
  },
};
</script>

<style scoped lang="scss"></style>
