<template>
  <div class="s-autocomplete">
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      :items="items"
      chips
      outlined
      height="40"
      rounded
      multiple
      class="tag-selector multiple"
      deletable-chips
      clearable
      hide-details="auto"
      v-bind="bind"
    ></v-autocomplete>
    <v-btn class="s-autocomplete-clear" icon @click="$emit('input', [])"
      ><v-icon>$close</v-icon></v-btn
    >
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    settings: Object,
  },
  data() {
    return {
      items: [],
      bind: {},
    };
  },
  created() {
    this.fetchItems();
  },
  methods: {
    async fetchItems() {
      if (this.settings.source == "custom") {
        this.items = this.settings.variants;
      } else {
        const schema_id = this.settings.schema?.schema_id;
        const { data: schema } = await this.$api.DataLoaderSchema.get({
          id: schema_id,
        });
        const { data } = await this.$api.DataLoaderEntity.lite({ schema_id });
        this.items = data.map((t) => ({
          title: t.fields[this.settings.item_text],
          tech_name: t.tech_name,
          schema_tech_name: schema.tech_name,
        }));
        this.bind = {
          itemText: "title",
          returnObject: true,
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.s-autocomplete {
  position: relative;

  &-clear {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  &::v-deep {
    .v-input__append-inner {
      display: none;
    }
  }
}
.tag-selector {
  width: 100%;
  max-width: 270px;

  &.multiple {
    max-width: 100%;
  }

  &::v-deep button.v-icon.v-chip__close {
    &:after {
      content: "✕";
      position: relative;
      color: var(--v-text-base);
      font-size: 12px;
      opacity: 1;
      background: transparent;
      padding: 3px;
    }

    &:hover:after {
      color: var(--v-primary-base);
    }

    svg {
      display: none;
    }
  }

  &::v-deep {
    fieldset {
      border-color: var(--v-border-base);
    }

    .v-input__slot:hover fieldset {
      border-color: rgba(0, 0, 0, 0.3);
    }

    &.v-text-field--outlined.v-input--has-state fieldset {
      border-color: red;
    }
  }
}
</style>
