<template>
  <div
    class="has-many"
    :class="{ 'link-mode': linkMode, 'as-parent': anyParentIncludes }"
  >
    <div class="has-many-title">
      <h2 class="has-many-title__title">{{ settings.title }}</h2>
      <v-tooltip
        open-delay="500"
        v-if="settings.helper_text"
        max-width="300"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" size="20" class="form-question"
            >$question_round</v-icon
          >
        </template>
        <span>
          <slot name="tooltip">
            {{ settings.helper_text }}
          </slot>
        </span>
      </v-tooltip>
    </div>
    <v-expansion-panels class="panels" accordion v-model="panel" multiple>
      <v-expansion-panel
        class="form-panel"
        :class="{
          'visible-fields':
            linkMode && (parentIncludes(field) || selfIncludes(field)),
        }"
        :style="[
          visiblePanels && !visiblePanels.includes(field.uuid)
            ? { display: 'none' }
            : {},
        ]"
        v-for="(field, idx) in fields"
        :key="field.uuid"
      >
        <v-expansion-panel-header v-if="!linkMode || anyParentIncludes">
          <div>
            <v-text-field
              @click.stop
              style="max-width: 200px; padding: 0"
              hide-details
              v-model="field.panel_title"
              :placeholder="findSchemaTitle(field)"
            ></v-text-field>
            <div class="has-many__actions">
              <v-btn icon @click.stop="copyLink(field)">
                <v-icon size="20">$copy_link</v-icon>
              </v-btn>
              <v-btn icon @click.stop="duplicate(field)">
                <v-icon size="20">$content_copy</v-icon>
              </v-btn>
              <v-btn
                class="has-many-move-up"
                icon
                @click.stop="moveUp(idx)"
                :disabled="idx == 0"
              >
                <v-icon>$arrow_up</v-icon>
              </v-btn>
              <v-btn
                class="has-many-move-up"
                icon
                @click.stop="moveDown(idx)"
                :disabled="idx == fields.length - 1"
              >
                <v-icon>$arrow_down</v-icon>
              </v-btn>
              <v-btn
                class="has-many-delete"
                icon
                @click.stop="deleteEntity(idx)"
              >
                <v-icon>$delete</v-icon>
              </v-btn>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <div class="has-many-field">
            <SchemaBuilder
              :schemaId="field.schema_id || settings.schema_id"
              v-model="fields[idx]"
            />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-menu offset-y v-if="!linkMode || anyParentIncludes">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" color="primary" outlined
          >+ Add {{ entityTitle }}</v-btn
        >
      </template>
      <v-list>
        <v-list-item
          @click="addEntity(schema)"
          v-for="schema in schemas"
          :key="schema.id"
          >{{ schema.title }}</v-list-item
        >
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import _ from "lodash";
import { copyToClipboard, getRanHex } from "@/lib";

export default {
  props: {
    value: Array,
    settings: Object,
    hasMany: Boolean,
  },
  data() {
    return {
      fields: [...(this.value || [])],
      visiblePanels: false,
      schemas: [],
      panel: [],
      linkMode: false,
    };
  },
  created() {
    this.setSchemas();
    this.linkMode = "link" in this.$route.query && this.uuid;
    if (this.linkMode) {
      this.visiblePanels = this.fields
        .filter((t) => {
          return JSON.stringify(t).includes(this.uuid) || !this.uuid;
        })
        .map((t) => t.uuid);

      for (let i = 0; i < this.fields.length; i++) this.panel.push(i);
    }

    const parentUuid = getRanHex(8);
    this.fields.map((t) => {
      if (!t.__links__?.__self__) {
        t.__links__ = t.__links__ || {};
        const uuid = getRanHex(8);
        t.__links__.__self__ = this.generateEasyUpdateLink(uuid);
      }
      if (!t.__links__?.__parent__) {
        t.__links__.__parent__ = this.generateEasyUpdateLink(parentUuid);
      }
    });
  },
  computed: {
    uuid() {
      if (this.$route.query.link)
        return this.$route.query.link.split("_").at(-1);
      return false;
    },
    entityTitle() {
      let title = this.schemas.map((t) => t.title).join(" / ");
      if (title.length > 35) title = title.slice(0, 35);
      return title;
    },
    anyParentIncludes() {
      const a = this.fields.find((t) => this.parentIncludes(t));

      return a;
    },
  },
  methods: {
    parentIncludes(field) {
      // if (this.hidden) return false;
      const parent = field?.__links__?.__parent__ || "";

      return parent.includes(this.uuid);
    },
    selfIncludes(field) {
      const self = field?.__links__?.__self__ || "";
      return self.includes(this.uuid);
    },
    generateEasyUpdateLink(uuid) {
      const { schema_id, id } = this.$route.params;
      if (schema_id && id) {
        return `${schema_id}_${id}_${uuid}`;
      }
      return false;
    },
    copyLink(field) {
      copyToClipboard(field.__links__.__self__);
    },
    getTitle(field) {
      let result = Object.values(field)
        .sort((a, b) => (a.length > b.length ? 1 : -1))
        .filter((t) => typeof t == "string" && t.length != 36)[0];
      if (!result) {
        result = Object.values(field).find((t) => t?.url);
      }
      return result;
    },
    async setSchemas() {
      const { data } = await this.$api.DataLoaderSchema.get({
        ids: _.flatten([this.settings.schema_id]),
      });
      this.schemas = data;
    },
    moveUp(idx) {
      if (idx < 1) return;
      const element = { ...this.fields[idx] };
      this.fields.splice(idx, 1);
      this.fields.splice(idx - 1, 0, element);
    },
    moveDown(idx) {
      if (idx >= this.fields.length - 1) return;
      const element = { ...this.fields[idx] };
      this.fields.splice(idx, 1);
      this.fields.splice(idx + 1, 0, element);
    },
    duplicate(field) {
      console.info("duplicate field", field);
      const newField = this.duplicateChild(field);

      this.fields.push(newField);
      this.panel.push(this.fields.length - 1);
    },
    duplicateChild(field) {
      console.info("duplicateChild field", field);

      if (_.isArray(field))
        return _.map(field, (item) => this.duplicateChild(item));
      if (!field.uuid) return JSON.parse(JSON.stringify(field));

      const newField = _.omitBy(
        _.omit(field, ["uuid", "panel_title", "__links__"]),
        _.isObject
      );

      Object.keys(field)
        .filter((key) => {
          return _.isObject(field[key]);
        })
        .forEach((key) => {
          console.info("object keys key", key);
          newField[key] = this.duplicateChild(field[key]);
        });

      const panelTitle = field.panel_title || this.findSchemaTitle(field);
      if (panelTitle) newField.panel_title = `${panelTitle} (Copy)`;
      newField.uuid = getRanHex(8);
      let parentUuid;
      try {
        parentUuid = field.__links__.__parent__;
      } catch (e) {
        parentUuid = getRanHex(8);
      }
      newField.__links__ = {
        __self__: this.generateEasyUpdateLink(newField.uuid),
        __parent__: parentUuid,
      };
      console.info("newField", newField);

      return newField;
    },
    deleteEntity(idx) {
      this.fields.splice(idx, 1);
    },
    addEntity(schema) {
      const uuid = getRanHex(8);
      let parentUuid;
      try {
        parentUuid = this.fields[0].__links__.__parent__;
      } catch (e) {
        parentUuid = getRanHex(8);
      }
      const target = {
        schema_id: schema.id,
        schema_tech_name: schema.tech_name,
        uuid,
        panel_title: schema.title,
      };
      target.__links__ = {
        __self__: this.generateEasyUpdateLink(uuid),
        __parent__: this.generateEasyUpdateLink(parentUuid),
      };
      this.fields.push(target);

      this.panel.push(this.fields.length - 1);
    },
    findSchemaTitle(field) {
      const schema = this.schemas.find(
        (schema) => schema.id == field.schema_id
      );
      return schema ? schema.title : "";
    },
  },
  watch: {
    fields: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("input", [...this.fields]);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.has-many {
  &.link-mode:not(.as-parent) {
    padding: 0;
    margin: 0;

    .has-many-title {
      display: none;
    }

    .has-many-field {
      padding: 0;
    }
    &::v-deep {
      .v-expansion-panel::before {
        box-shadow: none !important;
      }
    }
  }
  &.link-mode.as-parent {
    .has-many-title {
      display: flex;
    }

    .form-panel {
      margin-bottom: 20px;
    }

    .has-many-field {
      padding: 40px;
    }
    &::v-deep {
      .v-expansion-panel {
        display: block !important;
        &::before {
          box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
            0px 2px 2px 0px rgba(0, 0, 0, 0.14),
            0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
        }
      }
    }
  }
  &::v-deep {
    .v-expansion-panel-header {
      border: none;
    }
  }
}

.visible-fields {
  &::v-deep {
    .schema-field {
      display: block !important;
    }
  }
}

.has-many-field {
  padding: 40px;
  position: relative;
  background: white;
}

.has-many__actions {
  position: absolute;
  top: 25px;
  right: 80px;
}

.has-many {
  background: #0000000c;
  margin: 25px 0;
  padding: 15px;
}

.has-many-title {
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.has-many__schema-label {
  font-size: 14px;
  font-weight: 400;
}

.panels {
  margin-bottom: 10px;

  &::v-deep {
    .v-expansion-panel-content__wrap {
      padding: 0;
    }
  }
}

.env-production {
  .has-many-field {
    background: #262729 !important;
  }
}
</style>
