<template>
  <v-menu offset-y :disabled="disabled">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on" class="dropdown-selected">
        {{ label }}
        <span class="nowrap"
          >{{ selectedItem ? selectedItem.text : ""
          }}<v-icon>$chevron_down</v-icon></span
        >
      </div>
    </template>
    <v-list>
      <v-list-item
        @click="
          $emit('input', item.value);
          $emit('changed', item.value);
        "
        v-for="(item, idx) in items"
        :key="idx"
        >{{ item.text }}</v-list-item
      >
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    items: {
      type: Array,
      default: () => [],
    },
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    selectedItem() {
      return this.items.find((t) => t.value == this.value);
    },
  },
};
</script>

<style scoped lang="scss">
.dropdown {
  &-selected {
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;

    span {
      color: var(--v-primary-base);
    }

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
