<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Team Member Profile">
        <FormRow required label="First Name">
          <TextField
            placeholder="First Name"
            v-model="form.first_name"
            :rules="[validation.required]"
          ></TextField>
        </FormRow>
        <FormRow required label="Last Name">
          <TextField
            placeholder="Last Name"
            v-model="form.last_name"
            :rules="[validation.required]"
          ></TextField>
        </FormRow>
        <FormRow label="Company">
          <TextField placeholder="Company" v-model="form.company"></TextField>
        </FormRow>
        <FormRow label="Title">
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow>
          <template #tooltip
            >This image will be used across the Admin site.</template
          >
          <template #label>Profile Picture<br />(200x200)</template>
          <Uploader v-model="form.avatar" :ratio="1" enableCropper />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" v-if="btnText" :submit="submit">{{
      btnText
    }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";

export default {
  extends: mixin,
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
</style>
