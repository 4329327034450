<template>
  <v-app :class="`env-${currentUser.active_project_env}`">
    <SideBar v-if="currentUser.active_project" v-model="drawer" />
    <AppBar @toggleMenu="drawer = !drawer" />

    <v-main>
      <vue-page-transition>
        <router-view :key="currentUser.active_project_env" />
      </vue-page-transition>
    </v-main>

    <!-- <v-btn fab fixed bottom right>
      <v-icon>$question</v-icon>
    </v-btn> -->
    <ConfirmDialog />
    <Alert />
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import SideBar from "@/components/SideBar.vue";
export default {
  components: { AppBar, SideBar },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>

<style lang="scss" scoped>
#app {
  background: var(--v-background-base);
  font-family: "Source Sans Pro", sans-serif;
  color: var(--v-text-base);
}

.v-main::v-deep .v-main__wrap {
  padding: 56px 85px;
}

@media screen and (max-width: 980px) {
  .v-main::v-deep .v-main__wrap {
    padding: 56px 30px;
  }
}

.env-production::v-deep {
  .app-bar,
  .sidebar,
  .env-selector,
  .direct-chat__body-footer,
  & {
    background-color: #262729 !important;
  }
  &#app {
    color: white;
  }

  .sidebar {
    border: none !important;
  }

  .project-selector__title,
  .env-selector__title,
  .page-title,
  .projects-panel-icon,
  .page-subtitle,
  .projects-page__featured-selector {
    color: white !important;
  }

  .panel-box {
    background: #262729 !important;
  }

  .stories {
    background: transparent;
  }
  .dashboard-entity {
    background: transparent !important;
    color: white !important;

    .v-icon {
      color: white;
    }
  }

  .v-card__title {
    color: white;
  }

  .dashboard-entity__item {
    background: transparent !important;
    border: 1px solid white;
  }

  .project-selector {
    background: transparent !important;
    .v-icon {
      color: white !important;
    }
    &:hover .v-icon {
      color: black !important;
    }
  }

  .v-btn.v-btn--flat {
    color: white;
  }

  .project-selector__container:hover,
  .project-selector__arrow:hover {
    background: rgba(255, 255, 255, 0.1) !important;
  }

  .project-selector__arrow .v-icon {
    color: white !important;
  }

  .page-subtitle {
    .tag-selector {
      &,
      .v-label,
      .v-icon {
        color: white !important;
      }

      fieldset {
        border-color: white !important;
      }
    }
  }

  .banner {
    border: 2px solid;
    background: #ffffff21 !important;
    svg {
      display: none;
    }
  }

  .v-data-table {
    color: white !important;

    .v-data-footer__select {
      .v-icon,
      .v-select__selection--comma {
        color: white !important;
      }
    }

    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
      border-color: white;
    }
    .theme--light.v-btn.v-btn--icon {
      color: white;
    }

    .theme--light.v-btn.v-btn--disabled .v-icon {
      color: rgba(255, 255, 255, 0.7) !important;
    }
  }

  .list-panel {
    background: transparent !important;
    .v-icon,
    .list-panel-title__top {
      color: white !important;
    }
  }

  .sidebar-group .v-icon,
  .sidebar-subitem:not(.v-list-item--active):not(.v-list-item--disabled) {
    color: white !important;
  }

  .list-panel-title {
    color: white !important;
  }

  .index-page-actions__item,
  .no-entries {
    color: white;
  }

  .dashboard__counter {
    background: transparent;
  }
  .dashboard__counter-title {
    color: white;
  }
  .dashboard__counter-count__blur {
    display: none;
  }

  .form-panel {
    background: transparent !important;
    color: white !important;

    .v-expansion-panel-header {
      color: white;
      font-weight: bold;
    }

    .v-expansion-panel-header__icon .v-icon {
      color: white !important;
    }
  }

  .index-page-actions__view:not(.active) {
    color: white !important;
  }

  .addons-group__title {
    color: white !important;
  }
  .addon {
    background: transparent;
    color: white;

    .v-icon {
      color: white;
    }
  }

  .addon-dialog {
    background: #262729 !important;
  }
  .form-question,
  .form-subhead,
  .form-detail,
  .spinner-card {
    color: white;
  }

  .search .v-input:before {
    border: 1px solid #ffffff;
  }

  .event-datacard:before {
    opacity: 0.2;
  }

  .form-label {
    &,
    .v-icon {
      color: white;
    }
  }

  .v-input fieldset {
    border: 1px solid white !important;
    background: transparent;
  }
  .v-input .v-input__slot {
    background: transparent;
  }
  .context-search .v-input:before {
    border: 1px solid white !important;
  }
  .v-label,
  textarea,
  .v-text-field__suffix,
  textarea::placeholder,
  .theme--light.v-select .v-select__selection--comma,
  .v-input .v-icon,
  input::placeholder,
  input {
    color: white !important;
  }

  input::placeholder {
    opacity: 0.5;
  }

  .v-radio .v-icon,
  .checkbox .v-icon {
    color: white;
  }
  .form-inputs {
  }

  .project__actions .v-icon {
    color: white !important;
  }

  .add-member {
    background: transparent;
    border-color: white;
  }

  .app-bar__item-create {
    background: transparent !important;
    color: white !important;
    border: 1px solid white !important;
  }

  .submit-footer {
    .v-btn--outlined {
      color: white;
    }
  }

  .list-panel-label {
    color: white !important;
    opacity: 0.9;
  }
  .page-content-block__title,
  .page-content-block__subtitle {
    opacity: 1;
    color: white;
  }

  .submit-footer {
    background: transparent;
  }

  .themes-tabs-panel {
    background: transparent;
  }

  .submission-forms-list .list-panel-header.v-expansion-panel-header--active {
    background: rgba(255, 255, 255, 0.1) !important;
  }

  .banner-content,
  .sidebar-title,
  .env-selector-dialog-list__header,
  .env-selector-dialog-list__item-title,
  .env-selector-dialog-list__search input,
  .env-selector-dialog-list__search input::placeholder {
    color: white !important;
  }

  .env-selector-dialog-list__search .v-input__slot:before {
    border-color: white;
  }

  .v-card,
  .env-selector-dialog {
    background: #262729;
  }

  .env-selector-dialog__new-release {
    background: transparent;
  }

  .env-selector-dialog-list__items:after {
    box-shadow: inset 0 0 5px rgb(255 255 255 / 20%);
  }

  .uploader {
    border-color: white !important;
    &:hover {
      border-color: rgba(255, 255, 255, 0.5) !important;
    }
  }

  .form-builder__type {
    background: transparent;
    border: 1px solid white;
    .v-icon {
      color: white;
    }
  }

  .form-builder__actions {
    .v-btn--disabled .v-icon {
      color: rgba(255, 255, 255, 0.4) !important;
    }
    .v-btn {
      color: white;
    }
  }

  .form-builder {
    .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
      border-color: white;
    }
  }

  .projects-page a.project {
    cursor: pointer;
    background: rgb(255 255 255 / 10%);
    color: white;
    border: 2px solid white;

    .v-btn {
      border-color: white !important;
      color: white !important;
    }

    .project__description {
      &,
      u {
        color: white !important;
      }
    }
  }

  .add-member,
  .member {
    background: transparent;
    border-color: white;
  }

  .member-label {
    color: white;
  }

  .v-text-field > .v-input__control > .v-input__slot:before {
    border-color: white;
  }

  .data-table.v-data-table {
    th {
      &,
      .v-icon {
        color: white !important;
      }
    }
    & > .v-data-table__wrapper > table td {
      background: transparent !important;
      color: white;
      border-color: white !important;
    }

    .actions .v-icon {
      color: white;
    }

    &
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
      background: transparent;
    }
  }

  .projects-page__featured-selector {
    &,
    .v-select__selection--comma,
    .v-icon {
      color: white !important;
    }
  }

  .v-btn.v-btn--text {
    color: white !important;
  }

  .theme--light.v-data-table {
    background: transparent !important;
  }

  .v-data-table tr {
    background: transparent !important;
  }

  .schedule .actions span.v-icon {
    color: black !important;
  }
  .theme--light.v-data-table
    > .v-data-table__wrapper
    > table
    > thead
    > tr
    > th {
    border-color: white !important;
    &,
    .v-icon {
      color: white !important;
    }
  }

  .env-selector__current-env {
    background: transparent !important;
    border-color: white !important;

    .v-btn__content {
      color: white !important;
    }
  }

  .sidebar-item {
    .v-list-item__title,
    .v-icon {
      color: white !important;
    }
  }
}
</style>
