<template>
  <div>
    <v-data-table
      :headers="headers"
      :hide-default-footer="showMore"
      :items="list"
      item-key="id"
      :items-per-page="-1"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr
            class="data-table__row"
            :class="{ 'hidden-row': item.fields['hidden'] }"
          >
            <td />

            <td class="nowrap w0">
              {{
                item.created_at
                  | moment(
                    "timezone",
                    currentUser.active_project.timezone,
                    "MM/DD/YY h:mm A"
                  )
              }}
            </td>
            <td>
              <router-link
                :to="`/content-management/${entity.int_id}/entities/${item.int_id}/edit`"
              >
                {{ item.title }}
              </router-link>
            </td>
            <td class="nowrap w0" v-if="hasPartnerNameField">
              {{ item.fields.partner_name }}
            </td>
            <td class="text-center nowrap w0">
              <Actions
                hide-show
                @duplicate="duplicateEntity(item)"
                :path="`/content-management/${entity.int_id}/entities/${item.int_id}`"
                @destroy="$emit('destroy', item)"
                :type="type"
              >
                <template #prepend>
                  <v-btn icon @click="$emit('up', item.id)">
                    <v-icon>$arrow_up</v-icon>
                  </v-btn>
                  <v-btn icon @click="$emit('down', item.id)">
                    <v-icon>$arrow_down</v-icon>
                  </v-btn>
                </template>
              </Actions>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    showMore: Boolean,
    entity: Object,
    list: {
      type: Array,
      default: () => [],
    },
    type: String,
  },
  methods: {
    async duplicateEntity({ id, schema_id }) {
      await this.$api.DataLoaderEntity.duplicate({ id, schema_id });
      this.$emit("fetch-list");
    },
  },
  computed: {
    hasPartnerNameField() {
      return this.entity.schema.find((t) => t.tech_name == "partner_name");
    },
    headers() {
      return [
        {
          text: "Date",
          align: "start",
          value: "created_at",
          sortable: true,
        },
        {
          text: "Title",
          align: "start",
          value: "title",
          sortable: false,
        },
        ...(this.hasPartnerNameField
          ? [
              {
                text: "Partner",
                align: "start",
                value: "partner_name",
                sortable: false,
              },
            ]
          : []),
        {
          text: "",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style></style>
