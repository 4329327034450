<template>
  <div>
    <div class="page-title">Google Analytics Settings</div>
    <TextField
      label="Production Domain to Track"
      v-model="currentUser.production_domain"
      disabled
    />

    <TextField
      placeholder="Example: UA-XXX-X"
      label="Primary Google Analytics ID"
      v-model="localValue.data.ga_id"
    />

    <div class="ga-lines">
      <div
        class="ga-line"
        v-for="(ga, idx) in localValue.data.ga_ids"
        :key="idx"
      >
        <TextField
          placeholder="Example: UA-XXX-X"
          label="Secondary Google Analytics ID"
          v-model="localValue.data.ga_ids[idx]"
        />
        <v-btn color="primary" fab x-small @click="deleteGaId(idx)">
          <v-icon>$delete</v-icon>
        </v-btn>
      </div>

      <v-btn color="primary" outlined rounded @click="addGaId"
        >+ Add One More</v-btn
      >
    </div>
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
  created() {
    if (!this.localValue.data.ga_ids) {
      this.localValue.data.ga_ids = [];
    }
  },
  methods: {
    addGaId() {
      this.localValue.data.ga_ids = this.localValue.data.ga_ids || [];
      this.localValue.data.ga_ids.push("");
      this.localValue = { ...this.localValue };
    },
    deleteGaId(idx) {
      this.localValue.data.ga_ids.splice(idx, 1);
      this.localValue = { ...this.localValue };
    },
  },
};
</script>

<style scoped lang="scss">
.ga-lines {
  background: #f7f7f7;
  padding: 10px;
  border: 1px solid #e7e7e7;
}
.ga-line {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &::v-deep {
    .text-field {
      margin: 0;
      margin-right: 15px;
    }

    fieldset {
      background: white;
    }
  }
}

.env-production {
  .ga-lines {
    background: #262729!important;
    border: none;
  }
}
</style>
