<template>
  <div class="direct-chat">
    <div class="direct-chat__sidebar">
      <div class="direct-chat__sidebar-header">
        <div class="direct-chat__sidebar-header-title">Direct Chats</div>
        <div class="direct-chat__sidebar-header-search">
          <v-text-field
            dense
            hide-details="auto"
            placeholder="Search"
            v-model="searchChat"
          ></v-text-field>
        </div>
      </div>
      <div class="direct-chat__sidebar-contacts-wrapper">
        <div class="direct-chat__sidebar-contacts" v-if="chats !== null">
          <div
            class="direct-chat__sidebar-contact"
            v-for="chat in filteredChats"
            :key="chat.id"
            @click="setActiveChat(chat.id)"
            :class="{ active: activeChat && chat.id == activeChat.id }"
          >
            <Avatar
              :name="chat.user.full_name"
              :id="chat.user.id"
              :avatar="chat.user.avatar.thumb.url"
              :size="40"
              :colors="colors"
            />
            <div
              v-if="chat.unread_count"
              class="direct-chat__sidebar-contact-unread"
            >
              {{ chat.unread_count }}
            </div>
            <div class="direct-chat__sidebar-contact-info">
              <div class="direct-chat__sidebar-contact__name">
                <span>
                  {{ chat.user.full_name }}
                </span>
                <div
                  :class="{ online: chat.online }"
                  class="direct-chat__sidebar-contact__online"
                ></div>
              </div>

              <div class="direct-chat__sidebar-contact__short-desc">
                {{ chat.last_message }}
              </div>
              <div class="direct-chat__sidebar-contact__time">
                {{
                  chat.updated_at
                    | moment(
                      "timezone",
                      currentUser.active_project.timezone,
                      "MM/DD h:mm A"
                    )
                }}
              </div>
            </div>
          </div>
        </div>
        <Spinner v-else />
        <v-btn
          @click="showNewModal"
          class="direct-chat__new"
          fab
          dark
          color="primary"
          ><v-icon>$plus</v-icon></v-btn
        >
      </div>
    </div>
    <div class="direct-chat__body" v-if="activeChat">
      <div class="direct-chat__body-header">
        <div class="direct-chat__user">
          <div class="direct-chat__user-avatar">
            <Avatar
              :name="activeChat.user.full_name"
              :id="activeChat.user.id"
              :avatar="activeChat.user.avatar.thumb.url"
              :size="40"
              :colors="colors"
            />
          </div>
          <div class="direct-chat__user-fullname">
            {{ activeChat.user.full_name }}
          </div>
        </div>
      </div>
      <div class="direct-chat__body-messages" ref="messages">
        <div
          class="direct-chat__messages"
          v-if="messages[activeChat.id] !== null"
        >
          <div
            class="direct-chat__message"
            :class="[
              message.sender,
              message.sender != 'admin' && !message.read ? 'unread' : '',
              message.deleted ? 'hidden' : '',
            ]"
            v-for="message in messages[activeChat.id]"
            :key="message.id"
            v-observe-visibility="
              (visible) => (visible ? readMessage(message) : false)
            "
          >
            <div
              class="direct-chat__message-actions"
              v-if="message.sender == 'admin'"
            >
              <v-btn icon small @click="setEditMessage(message)">
                <v-icon>$pencil</v-icon>
              </v-btn>
              <v-btn icon small @click="deleteMessage(message)">
                <v-icon>$delete</v-icon>
              </v-btn>
            </div>
            <div class="direct-chat__message-user">
              {{ message.sender_name }}
            </div>
            <div
              class="direct-chat__message-edit"
              v-if="editMessageId == message.id"
            >
              <v-textarea
                hide-details="auto"
                auto-grow
                rows="4"
                row-height="15"
                placeholder="Write a message"
                flat
                solo
                v-model="editBody"
                ref="textarea"
              ></v-textarea>
              <div style="margin-top: 10px">
                <v-btn
                  @click="editMessageId = null"
                  small
                  style="margin-right: 10px"
                  >Cancel</v-btn
                >
                <v-btn @click="editMessage" small color="primary">Save</v-btn>
              </div>
            </div>
            <div v-else>
              <div class="direct-chat__message-body">
                {{ message.body }}
              </div>
              <div class="direct-chat__message-timestamp">
                {{
                  message.created_at
                    | moment(
                      "timezone",
                      currentUser.active_project.timezone,
                      "MM/DD/YY h:mm A zz"
                    )
                }}
              </div>
            </div>
          </div>
        </div>
        <Spinner v-else />
      </div>
      <div class="direct-chat__body-footer">
        <div class="direct-chat__body-input">
          <v-textarea
            hide-details="auto"
            auto-grow
            rows="4"
            row-height="15"
            placeholder="Write a message"
            flat
            solo
            v-model="enter[activeChat.id]"
            ref="textarea"
            @keydown.enter.exact.prevent="sendMessage"
            @keydown.enter.shift.exact.prevent="enter[activeChat.id] += '\n'"
          ></v-textarea>
        </div>
      </div>
    </div>
    <div class="direct-chat__empty" v-else>
      <div class="direct-chat__empty-placeholder">
        Select a chat to start messaging
      </div>
    </div>
    <div
      class="new-chat-modal-wrapper"
      v-if="newModal"
      @click="newModal = false"
    >
      <div class="new-chat-modal" @click="$event.stopPropagation()">
        <v-btn class="new-chat-modal__close" icon @click="newModal = false"
          ><v-icon>$close</v-icon></v-btn
        >
        <div class="new-chat-modal__title">
          <span> Select a User to start chat </span>
        </div>
        <v-text-field
          v-model="searchUser"
          dense
          flat
          placeholder="Search"
        ></v-text-field>
        <div class="new-chat-modal__tabs">
          <div
            class="new-chat-modal__tab"
            v-for="val in ['all', 'online', 'offline']"
            :key="val"
            @click="activeModalTab = val"
            :class="{ active: activeModalTab == val }"
          >
            {{ val }}
          </div>
        </div>
        <div class="new-chat-modal__list">
          <div
            class="new-chat-modal__user"
            v-for="user in filteredUsers"
            :key="user.id"
            @click="createChat(user)"
          >
            <Avatar
              :name="user.full_name"
              :id="user.id"
              :avatar="user.avatar.thumb.url"
              :size="40"
              :colors="colors"
            />
            <div class="new-chat-modal__user-info">
              <div class="new-chat-modal__user-name">
                <div>{{ user.full_name }}</div>
                <div
                  :class="{ online: user.online }"
                  class="new-chat-modal__user-name__online"
                ></div>
              </div>
              <div class="new-chat-modal__user-email">{{ user.email }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  data() {
    return {
      chats: null,
      searchChat: "",
      searchUser: "",
      activeChat: null,
      newModal: false,
      users: null,
      enter: {},
      messages: {},
      colors: ["#ff0015", "#ff9000", "#001d64"],
      editBody: "",
      editMessageId: null,
      activeModalTab: "all",
    };
  },
  created() {
    this.$cable.subscribe({
      channel: "Admin::DirectChatsChannel",
      env: this.currentUser.active_project_env,
      token: this.authToken,
    });
  },
  computed: {
    filteredChats() {
      if (this.chats == null) return null;
      return this.chats.filter((t) =>
        t.user.full_name.toLowerCase().includes(this.searchChat.toLowerCase())
      );
    },
    filteredUsers() {
      if (this.users == null) return null;
      return this.users.filter((t) =>
        t.full_name.toLowerCase().includes(this.searchUser.toLowerCase())
      );
    },
  },
  channels: {
    "Admin::DirectChatsChannel": {
      connected() {
        this.init();
      },
      received({ type, payload }) {
        switch (type) {
          case "new_chat":
            this.fetchChats();
            break;
          case "new_message":
            this.newMessageCallback(payload);
            break;
          case "delete_message":
            this.deleteMessageCallback(payload);
            break;
          case "update_message":
            this.updateMessageCallback(payload);
            break;
        }
      },
    },
  },
  methods: {
    setEditMessage(message) {
      this.editMessageId = message.id;
      this.editBody = message.body;
    },
    async editMessage() {
      const enter = this.editBody;
      const editMessageId = this.editMessageId;
      this.editMessageId = null;
      try {
        await this.$api.DirectChat.updateMessage({
          text: enter,
          id: this.activeChat.id,
          message_id: editMessageId,
        });
      } catch (e) {
        this.editMessageId = editMessageId;
      }
    },
    updateMessageCallback(payload) {
      if (payload.direct_chat_id != this.activeChat.id) {
        return false;
      }
      this.messages[payload.direct_chat_id] = this.messages[
        payload.direct_chat_id
      ].map((t) => {
        if (t.id == payload.id) return payload;
        return t;
      });
      if (_.last(this.messages[payload.direct_chat_id]).id == payload.id) {
        this.fetchChats();
      }
    },
    deleteMessageCallback(payload) {
      this.messages[payload.direct_chat_id] = this.messages[
        payload.direct_chat_id
      ].filter((t) => t.id != payload.id);
    },
    newMessageCallback(payload) {
      this.fetchChats();
      if (payload.direct_chat_id != this.activeChat.id) {
        return false;
      }
      this.messages[payload.direct_chat_id] = [
        ...(this.messages[payload.direct_chat_id] || []),
        payload,
      ];

      this.scrollDown();
    },
    async init() {
      this.fetchChats();
      if (this.$route.query.chat) this.setActiveChat(this.$route.query.chat);
    },
    async fetchMessages() {
      const { data } = await this.$api.DirectChat.messages({
        id: this.activeChat.id,
      });
      this.messages = { ...this.messages, [this.activeChat.id]: data };
      this.scrollDown();
    },
    async sendMessage(e) {
      const enter = this.enter[this.activeChat.id] || "";
      if (e.shiftKey || enter.length < 1) return e.preventDefault();

      this.enter[this.activeChat.id] = "";
      try {
        await this.$api.DirectChat.sendMessage({
          text: enter,
          id: this.activeChat.id,
        });
      } catch (e) {
        this.enter[this.activeChat.id] = enter;
      }
    },
    async deleteMessage(message) {
      const confirmed = await this.$confirmWindow({
        title: "Do you really want to delete this message?",
        description: message.body,
      });
      if (!confirmed) return;

      await this.$api.DirectChat.deleteMessage({
        message_id: message.id,
        id: this.activeChat.id,
      });
    },
    async scrollDown() {
      await this.$nextTick();
      this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
    },
    async readMessage(message) {
      if (message.sender == "admin" || message.read) return false;

      const { data } = await this.$api.DirectChat.readMessage({
        id: this.activeChat.id,
        message_id: message.id,
      });
      message.read = true;
      this.chats = this.chats.map((t) => {
        return t.id == data.id ? data : t;
      });
    },
    async fetchChats() {
      const { data } = await this.$api.DirectChat.get();
      this.chats = data;
    },
    async fetchUsers() {
      const { data } = await this.$api.User.lite({
        online: this.activeModalTab,
      });
      this.users = data;
    },
    async createChat(user) {
      const { data } = await this.$api.DirectChat.create({ user_id: user.id });
      await this.setActiveChat(data.id);
      this.newModal = false;
    },
    async setActiveChat(id) {
      window.history.replaceState(null, "", "?chat=" + id);
      const { data } = await this.$api.DirectChat.get({ id });
      this.activeChat = data;
      await this.fetchMessages();
      await this.$nextTick();
      this.$refs.textarea.$el.querySelector("textarea").focus();
    },
    showNewModal() {
      this.fetchUsers();
      this.newModal = true;
    },
  },
  watch: {
    activeModalTab() {
      this.fetchUsers();
    },
  },
};
</script>

<style scoped lang="scss">
.direct-chat {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;

  &__new {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &__sidebar {
    height: 100%;
    width: 250px;
    min-width: 250px;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e7e7e7;

    &-header {
      height: 80px;
      min-height: 80px;
      padding: 15px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      position: relative;
      z-index: 1;

      &-title {
        font-weight: bold;
      }
    }
    &-contacts {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      &-wrapper {
        position: relative;
        height: calc(100% - 80px);
      }
    }
    &-contact {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      position: relative;

      &:hover {
        background: #e7e7e7;
      }

      &.active {
        background: red;
        background: white;
        border-right: 4px solid #ee3723;
        border-top: 1px solid #f0f0f0;
        border-bottom: 1px solid #f0f0f0;
      }

      &-unread {
        position: absolute;
        right: 10px;
        font-size: 12px;
        min-width: 20px;
        height: 20px;
        border-radius: 3px;
        background: #ee3723d0;
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__short-desc {
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 180px;
        overflow: hidden;
        color: #979191;
        font-size: 14px;
        font-style: italic;
      }

      &__online {
        width: 5px;
        height: 5px;
        border-radius: 10px;
        margin: 0 5px;
        background: red;

        &.online {
          background: green;
        }
      }

      &__name {
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      &__time {
        font-size: 12px;
        color: #979191ab;
      }
    }
  }

  &__messages {
    padding: 25px;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  &__message {
    padding: 10px 15px;
    width: 500px;
    border-radius: 10px;
    margin: 10px 0;
    align-self: flex-start;
    white-space: pre-line;
    background: rgba(0, 0, 255, 0.1);
    box-shadow: 0 0 0 3px #939bd800;
    transition: 0.3s;
    position: relative;

    &.hidden {
      display: none;
    }

    &.unread {
      box-shadow: 0 0 0 3px #939bd8;
    }

    &.admin {
      background: rgba(255, 0, 0, 0.1);
      align-self: flex-end;
    }
    &.admin &-user {
      color: #ee3723;
    }
    &.user {
    }
    &-user {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
    }
    &-body {
      font-size: 14px;
    }
    &-timestamp {
      font-size: 12px;
      color: #00000058;
    }

    &-actions {
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  &__body {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    z-index: 1;

    &-header {
      height: 80px;
      min-height: 80px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      padding: 15px;
      display: flex;
    }
    &-messages {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
    &-footer {
      border-top: 1px solid #e7e7e7;
      min-height: 100px;
      flex: none;
      &::v-deep textarea {
        padding: 5px;
      }
      &::v-deep .v-input__slot {
        background: transparent !important;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;

    &-fullname {
      font-weight: bold;
    }
  }

  &__empty {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 15px;
    justify-content: center;
    background: #2378ee0a;

    &-placeholder {
      background: white;
      padding: 2px 10px;
      border-radius: 10px;
    }
  }
}

.new-chat-modal {
  max-width: 400px;
  width: 100%;
  background: white;
  border-radius: 15px;
  min-height: 300px;
  max-height: 80%;
  height: 100%;
  padding: 15px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;

  &__tabs {
    display: flex;
  }
  &__tab {
    width: 100%;
    text-align: center;
    padding: 5px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: 0.3s;
    text-transform: capitalize;

    &:hover {
      background: #f7f7f7;
    }

    &.active {
      font-weight: bold;
      background: #f7f7f7;
      border-color: red;
    }
  }

  &-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  &__title {
    font-weight: bold;
    font-size: 20px;
    padding: 20px 0;
    min-height: 30px;
  }

  &__list {
    height: 100%;
    overflow: auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1) inset;
  }

  &__user {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;

    &:hover {
      background: #f7f7f7;
    }

    &-name {
      font-weight: bold;
      line-height: 1;
      display: flex;
      align-items: center;

      &__online {
        width: 5px;
        height: 5px;
        border-radius: 10px;
        margin: 0 5px;
        background: red;

        &.online {
          background: green;
        }
      }
    }

    &-email {
      font-size: 12px;
      color: #898989;
    }
  }
}
</style>
