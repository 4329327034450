import { mapState } from "vuex";
import Vue from "vue";

Vue.mixin({
  methods: {
    scrollToInvalidInput(inputs) {
      const inputError = inputs.find((input) => input.hasError);
      if (inputError) {
        const el = inputError.$el;
        const y =
          el.getBoundingClientRect().top +
          window.scrollY -
          document.querySelector(".app-bar")?.offsetHeight -
          20;

        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    },
    async $confirmWindow({ title, description }) {
      return new Promise((resolve) => {
        this.$root.$emit("confirmDialog", {
          title,
          description,
          callback: (value) => {
            resolve(value);
          },
        });
      });
    },
    $allertWindow(message, type = "success") {
      this.$root.$emit("alert", {
        message,
        type,
      });
    },
  },
  computed: {
    ...mapState({
      authToken: (state) => state.auth.token,
      currentUser: (state) => state.user.current,
      datasources: (state) => state.datasources.data,
    }),
    $size() {
      return this.$vuetify.breakpoint.name;
    },
  },
});
