<template>
  <div class="new-team">
    <div class="page-title">New Team</div>

    <Form v-if="form" v-model="form" :submit="create" btnText="Add Team" />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.teams.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.Team.new();
      this.form = data;
    },
    async create() {
      const { data } = await this.$api.Team.create(this.form);
      await this.$api.Team.makeActive({ id: data.id });
      this.$router.push("/projects/new");
    },
  },
};
</script>

<style scoped lang="scss">
.new-team {
  max-width: 800px;
  margin: auto;
}
</style>
