<template>
  <div class="event-datacard">
    <div
      class="event-datacard__container"
      :class="{ 'event-datacard__container--dark': event.preview }"
      :style="{
        backgroundImage: event.preview ? `url(${event.preview})` : '',
      }"
    >
      <div class="event-datacard__body">
        <div class="event-datacard__title">
          {{ event.title }}
        </div>
        <div class="event-datacard__description">
          <div
            v-if="event.datetime_override"
            class="event-datacard__description--overridden"
          >
            {{
              event.datetime_override
                | moment(
                  "timezone",
                  currentUser.active_project.timezone,
                  "MM/DD/YY h:mm A"
                )
            }}
            * (overridden)
          </div>
          <div v-else>
            {{
              event.datetime
                | moment(
                  "timezone",
                  currentUser.active_project.timezone,
                  "MM/DD/YY h:mm A"
                )
            }}
          </div>
        </div>

        <span v-for="page in event.pages" :key="page.id" class="mr-3">
          <router-link :to="`/pages/${page.id}/edit`">{{
            page.title
          }}</router-link>
        </span>
      </div>

      <div class="event-datacard__footer">
        <Actions
          class="event-datacard__actions"
          :path="`/venue-events/${event.id}`"
          hide-duplicate
          @destroy="$emit('destroy', event)"
        />
        <div v-if="event.hidden" class="event-datacard__cancelled">Hidden</div>
        <div v-if="event.offsale" class="event-datacard__cancelled">
          Offsale
        </div>
        <div v-if="event.cancelled" class="event-datacard__cancelled">
          Cancelled
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideDestroy: Boolean,
    hideDuplicate: Boolean,
    event: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="scss">
.event-datacard {
  min-height: 165px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  a:hover {
    text-decoration: none;
  }

  &::before {
    content: "";
    background: var(--v-border-base);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.5;
  }

  &__container {
    position: relative;
    padding: 20px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-size: cover;
    background-position: center;

    &--dark:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.405);
    }
  }
  &__container--dark &__title,
  &__container--dark &__body,
  &__container--dark a {
    color: white;
  }

  &__container--dark::v-deep .v-icon__svg {
    fill: white;
  }

  &__body {
    position: relative;
  }

  &__title {
    font-weight: 900;
    font-size: 20px;
    line-height: 1.1;
  }

  &__description {
    font-size: 12px;

    &--overridden {
      font-weight: bold;
    }
  }

  &__actions {
    margin-left: -5px;
  }

  &__footer {
    display: flex;
    align-items: flex-end;
  }

  &__cancelled {
    font-size: 75%;
    font-weight: bold;
    background-color: var(--v-primary-base);
    color: white;
    padding: 0.25em 0.75em;
    border-radius: 0.25em;
    z-index: 1;
    margin-bottom: 0.5em;
    margin-right: 0.5em;
  }
}
</style>
