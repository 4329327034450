<template>
  <!-- <TextField :label="label" :value="value" @input="$emit('input', $event)" /> -->
  <component
    :is="field && field.enable_background_styles ? 'UploaderWithBgPosition' : 'Uploader'"
    :rules="rules"
    :value="value"
    @input="$emit('input', $event)"
    :ratio="ratioEvaled"
    :backgroundStyles="(value && value.background_styles) ? value.background_styles : value ? (value.background_styles = {backgroundPositionX: 50, backgroundPositionY: 50}) : {}"
  />
</template>

<script>
export default {
  props: {
    label: String,
    value: [String, File, Object],
    rules: {
      type: Array,
      default: () => [],
    },
    ratio: {
      type: String,
      default: "500 / 276",
    },
    field: Object,
  },
  computed: {
    ratioEvaled() {
      return eval(this.ratio);
    }
  }
};
</script>

<style></style>
