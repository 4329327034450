<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Presentation Info">
        <FormRow required label="Title">
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
      </FormPanel>

      <FormPanel required label="Presentation Slides">
        <div
          class="presentation-slide"
          v-for="slide in calcSlides"
          :key="slide.id || slide.uuid"
        >
          <div class="presentation-slide__actions">
            <v-btn icon @click="moveUp(slide)">
              <v-icon>$arrow_up</v-icon>
            </v-btn>
            <v-btn style="margin-right: 15px" icon @click="moveDown(slide)">
              <v-icon>$arrow_down</v-icon>
            </v-btn>
            <v-btn @click="deleteSlide(slide)" fab color="primary" small>
              <v-icon>
                $delete
              </v-icon>
            </v-btn>
          </div>
          <FormRow required label="Slide Title">
            <TextField
              placeholder="Slide Title"
              v-model="slide.title"
            ></TextField>
          </FormRow>

          <FormRow label="Slide Type" required>
            <RadioGroup :items="slideTypes" v-model="slide.slide_type" />
          </FormRow>
          <FormRow
            label="Slide YouTube ID"
            required
            v-if="slide.slide_type == 'youtube'"
          >
            <TextField
              placeholder="Slide YouTube ID"
              v-model="slide.youtube_id"
            ></TextField>
          </FormRow>

          <FormRow
            label="Slide Vimeo ID"
            required
            v-if="slide.slide_type == 'vimeo'"
          >
            <TextField
              placeholder="Slide Vimeo ID"
              v-model="slide.vimeo_id"
            ></TextField>
          </FormRow>

          <FormRow required v-if="slide.slide_type == 'image'">
            <template #label>Slide Image<br />(1920x1080)</template>
            <Uploader :ratio="1920 / 1080" v-model="slide.preview" enableCropper />
          </FormRow>
        </div>
        <v-btn color="primary" @click="addSlide">Add Slide</v-btn>
      </FormPanel>
      <FormPanel label="Presentation Details">
        <FormRow label="Display on Pages">
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

import mixin from "../../_extends/form.extends.js";
export default {
  extends: mixin,
  data() {
    return {
      slideTypes: [
        { value: "image", title: "Image" },
        // { value: "vimeo", title: "Vimeo" },
        { value: "youtube", title: "Youtube" },
      ],
    };
  },
  computed: {
    sortedSlides() {
      return [...this.form.slides].sort((a, b) => a.position - b.position);
    },
    calcSlides() {
      return this.sortedSlides.filter((t) => !t._destroy);
    },
  },
  methods: {
    moveUp(slide) {
      const currentPosition = this.sortedSlides.findIndex(
        (t) => (t.id && t.id == slide.id) || (t.uuid && t.uuid == slide.uuid)
      );

      if (currentPosition === 0) return;

      const slides = [...this.sortedSlides];
      slides[currentPosition].position = currentPosition - 1;
      slides[currentPosition - 1].position = currentPosition;

      this.form = { ...this.form, slides: [...slides] };
    },
    moveDown(slide) {
      const currentPosition = this.sortedSlides.findIndex(
        (t) => (t.id && t.id == slide.id) || (t.uuid && t.uuid == slide.uuid)
      );

      if (currentPosition === this.sortedSlides.length - 1) return;

      const slides = [...this.sortedSlides];
      slides[currentPosition].position = currentPosition + 1;
      slides[currentPosition + 1].position = currentPosition;

      this.form = { ...this.form, slides: [...slides] };
    },
    addSlide() {
      this.form.slides.push({
        uuid: uuidv4(),
        position: this.form.slides.length,
      });
    },
    deleteSlide(slide) {
      let slides = this.form.slides.map((t) => {
        if (t.id && slide.id == t.id) {
          return { ...t, _destroy: true };
        }
        return { ...t };
      });

      slides = slides.filter(
        (t) => !t.uuid || (t.uuid && t.uuid != slide.uuid)
      );

      this.form = {
        ...this.form,
        slides: [...slides],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.presentation-slide {
  padding: 15px;
  border: 1px solid #dcdce1;
  margin-bottom: 20px;
  border-radius: 5px;
  position: relative;

  &__actions {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
</style>
