<template>
  <div>
    <v-data-table
      :headers="headers"
      hide-default-footer
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr class="data-table__row">
            <td />
            <td class="nowrap">
              {{ item.id }}
            </td>
            <td>
              <a :href="`mailto:${item.email}`">{{ item.email }}</a>
            </td>
            <td class="nowrap">
              <router-link :to="`/users/admins/${item.id}`">
                {{ item.name }}
              </router-link>
            </td>
            <td class="nowrap">
              {{ item.title }}
            </td>
            <td class="text-center nowrap">
              <Actions
                hide-duplicate
                :path="`/users/admins/${item.id}`"
                @destroy="$emit('destroy', item)"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Admin ID#',
          align: 'start',
          value: 'id',
        },
        {
          text: 'Email',
          align: 'start',
          value: 'email',
        },
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Title',
          align: 'start',
          value: 'title',
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style>
</style>
