<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Basic Info">
        <FormRow
          required
          label="Title"
          tooltip="This is the title of the Submission Form as it will be displayed on the front-end of your site."
        >
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow label="Description">
          <template #tooltip>
            Add any finer detail to be displayed on the front-end of your site
            under the "title."
          </template>
          <TextArea
            placeholder="Description"
            v-model="form.description"
          ></TextArea>
        </FormRow>
        <FormRow label="Submission Button Text">
          <template #tooltip>
            This is the text for the submit button, once the user has completed the form and is ready to register.
          </template>
          <TextField v-model="form.btn_text"></TextField>
        </FormRow>
        <FormRow required label="Mark Required">
          <template #tooltip>
            Checking this box will add an asterisk (*) to the end of the labels of required fields.
          </template>
          <Checkbox v-model="form.mark_required" />
        </FormRow>
      </FormPanel>

      <FormPanel required label="Custom Fields">
        <FormBuilder v-model="form.registration_form" :allow-conditionals="true"/>
      </FormPanel>
      <FormPanel
        label="Form Details"
        tooltip="Select the page or pages on which you'd like this Registration Form to appear."
      >
        <FormRow label="Display Page(s)">
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
        <FormRow required label="Destination Page">
          <PagesSelector
            v-model="form.destination_page_id"
          />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
export default {
  extends: mixin,
};
</script>

<style></style>
