<template>
  <div>
    <div class="page-title">Site Support</div>
    <Banner theme="green">
      <template v-slot:title> About this feature </template>
      <template v-slot:description>
        Here you can create Site Support forms for guests to use if they have
        any questions or need to get in touch with a Commons admin.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/support.svg" alt="Site Support" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Support Form"
      btn-to="/support/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Ticket"
      :search-text.sync="searchText"
    />

    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length" style="width: 100%">
        <ListPanel
          v-for="section in computedList"
          :key="section.id"
          :action-path="`/support/${section.id}`"
          :title="section.title"
          @destroy="destroySection(section)"
          @duplicate="duplicateSection(section)"
        >
          <SupportDataTable show-more :entity="section" />
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Support Forms</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from "./index-page";
import SupportDataTable from "@/components/dataview/support.datatable";

export default {
  name: "SupportPage",
  components: {
    SupportDataTable,
  },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.SupportForm.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroySection(section) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.SupportForm.destroy({ id: section.id });
      this.list = this.list.filter((t) => t.id != section.id);
    },
    async duplicateSection(section) {
      await this.$api.SupportForm.duplicate({ id: section.id });
      this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss"></style>
