<template>
  <div>
    <div class="page-title">Edit Venue</div>
    <Form v-if="form" v-model="form" :submit="update" btnText="Update Venue" />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.ticketmaster-venues.page";

export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async update() {
      await this.$api.TicketmasterVenue.update(this.form);

      this.$router.push("/ticketmaster-venues");
    },
    async setForm() {
      const { data } = await this.$api.TicketmasterVenue.get({
        id: this.$route.params.id,
      });
      this.form = data;
    },
  },
};
</script>

<style></style>
