import { Iframe } from "element-tiptap";

export default class CustomIframe extends Iframe {
  get schema() {
    return {
      attrs: {
        src: {
          default: null,
        },
      },
      group: "block",
      selectable: false,
      parseDOM: [
        {
          tag: "iframe",
          getAttrs: (dom) => ({
            src: dom.getAttribute("src"),
          }),
        },
      ],
      toDOM(node) {
        const url = node.attrs.src;
        const regExp =
          /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        const match = url.match(regExp);
        const id = match[7];

        return [
          "div",
          { class: "iframe-player" },
          [
            "iframe",
            {
              src: `https://www.youtube.com/embed/${id}`,
              frameborder: 0,
              allowfullscreen: "true",
            },
          ],
        ];
      },
    };
  }

  get view() {
    return {
      props: ["node", "updateAttrs", "view"],
      computed: {
        src: {
          get() {
            const url = this.node.attrs.src;
            const regExp =
              /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            const match = url.match(regExp);
            const id = match && match[7].length === 11 ? match[7] : false;

            return `https://www.youtube.com/embed/${id}`;
          },
          set(src) {
            this.updateAttrs({
              src,
            });
          },
        },
        style() {
          let width = "50%";
          return {
            width,
          };
        },
      },
      template: `
        <div style="display: inline-block;" :style="style">
          <div class="iframe">
            <iframe class="iframe__embed" :src="src"></iframe>
          </div>
        </div>
      `,
    };
  }
}
