<template>
  <div>
    <SchemaFieldRelationSelector
      :value="value"
      :schema-uuid="schemaUuid"
      @update="$emit('update', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    schemaUuid: String,
  },
};
</script>

<style></style>
