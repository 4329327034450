<template>
  <div>
    <RadioGroup
      :rules="[validation.required]"
      v-model="localValue.source"
      :items="[
        { title: 'Custom Variants', value: 'custom' },
        { title: 'Existing Schema', value: 'schema' },
      ]"
    />
    <div v-if="localValue.source == 'custom'">
      <div>
        <b>Variants:</b>
      </div>
      <div
        class="variant"
        v-for="(variant, idx) in localValue.variants"
        :key="idx"
      >
        <v-text-field
          :label="`Variant ${idx + 1}`"
          v-model="localValue.variants[idx]"
        ></v-text-field>
        <v-btn icon @click="deleteVariant(idx)">
          <v-icon>$delete</v-icon>
        </v-btn>
      </div>
      <v-btn block outlined @click="addVariant">+ Add Variant</v-btn>
    </div>
    <div class="if-schema" v-if="localValue.source == 'schema'">
      <SchemaFieldRelationSelector
        :rules="[validation.required]"
        @update="localValue.schema = $event"
        :value="localValue.schema"
      />
      <v-text-field
        label="Text Tech Name"
        v-model="localValue.item_text"
        :rules="[validation.required]"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      localValue: {
        ...this.value,
        variants: [...(this.value.variants || [])],
      },
    };
  },
  methods: {
    addVariant() {
      this.localValue.variants.push("");
    },
    deleteVariant(idx) {
      this.localValue.variants.splice(idx, 1);
    },
  },
  watch: {
    localValue: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("update", {
          ...this.localValue,
        });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.variant {
  display: flex;
  align-items: center;
}

.if-schema {
  display: flex;

  &::v-deep {
    .v-input {
      margin-right: 10px;
    }
  }
}
</style>
