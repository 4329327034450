<template>
  <div class="ticketmaster-venue-selector" v-if="list.length">
    <TagSelector
      :multiple="multiple"
      :items="list"
      :value="value"
      @input="$emit('input', $event)"
      itemText="name"
      itemValue="id"
      class="ticketmaster-venue-selector__tag-selector"
    />
    <v-btn
      small
      outlined
      color="primary"
      @click="dialog = true;"
      class= "ticketmaster-venue-search-btn"
      v-if="!value && enabled == false"
    >
      Find a new Ticketmaster Venue
    </v-btn>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-btn class="dialog-close" icon @click="cancel">
          <v-icon>$close</v-icon>
        </v-btn>
        <v-card-title>Search for a Ticketmaster Venue</v-card-title>
        <v-card-text>
          <div class="ticketmaster-venue-search-holder">
            <TextField
              placeholder="Name or Keyword for the venue"
              v-model="search"
              class= "ticketmaster-venue-dialog__search-input"
            ></TextField>
            <TextField
              placeholder="Filter by U.S. state code"
              v-model="searchStateCode"
              class= "ticketmaster-venue-dialog__search-input"
            ></TextField>
            <v-btn
              color="primary"
              @click="searchTicketmasterVenues()"
              class= "ticketmaster-venue-dialog-btn"
            >
              Search
            </v-btn>
          </div>
          <v-data-table
            class="ticketmaster-venue__search-results elevation-0 data-table"
            :headers="headers"
            hide-default-footer
            :items="searchResults"
            item-key="Id"
            v-if="searchResults.length"
          >
            <template v-slot:body="{ items }">
              <tbody v-for="item in items" :key="item.Id">
                <tr class="data-table__row">

                  <td>{{ item.name }}</td>
                  <td >{{ item.state ? `${item.city}, ${item.state}` : item.city }}</td>
                  <td>
                    <v-btn
                      small
                      outlined
                      color="primary"
                      @click="selectResult(item)"
                    >
                      {{ item.id ? "Select" : "Import and Select" }}
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
          <Spinner v-else-if="searching" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TextField from '../TextField.vue';
export default {
  components: { TextField },
  props: {
    value: [String, Number, Array],
    multiple: Boolean,
    enabled: Boolean,
  },
  data() {
    return {
      list: [],
      dialog: false,
      search: "",
      searchStateCode: "",
      searching: false,
      searchResults: [],
      headers: [
        {
          text: 'Name',
          align: 'start',
          value: 'name',
        },
        {
          text: 'Location',
          align: 'start',
        },
      ],
    };
  },
  created() {
    this.setTicketmasterVenues();
  },
  methods: {
    async setTicketmasterVenues() {
      const { data } = await this.$api.TicketmasterVenue.lite({ enabled: this.enabled });
      this.list = [
        { name: "N/A", id: null },
        ...data,
      ];
    },
    async searchTicketmasterVenues() {
      this.searchResults = [];
      this.searching = true;
      const { data } = await this.$api.TicketmasterVenue.search(
        {
          search: this.search,
          search_state_code: this.searchStateCode
        }
      );
      this.searching = false;
      console.info("data", data);
      this.searchResults = data;
    },
    async selectResult(item) {
      console.info('selectResult item', item);
      if(!item.id){
        const { data } = await this.$api.TicketmasterVenue.create({
          tm_venue_id: item.tm_venue_id,
          name: item.name,
          city: item.city,
          state: item.state
        });
        console.info('selectResult data', data);
        await this.setTicketmasterVenues();
        this.dialog = false;
        this.$emit("input", data.id);

      } else {
        await this.setTicketmasterVenues();
        this.dialog =  false;
        this.$emit("input", item.id);
      }
    },
    cancel() {
      this.dialog = false;
    }
  },
};
</script>

<style lang="scss" scoped>

.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}
.ticketmaster-venue-search-btn {
  margin-top: .75em;
}
.ticketmaster-venue-dialog__search-input {
  margin-right: 1em;
  margin-bottom: .75em;
}
.spinner.relative {
  min-height: 100px;
}
.ticketmaster-venue-selector__tag-selector {
  padding-right: 1em;
  max-width: unset;
}
</style>
