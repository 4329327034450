var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"submissions-table"},[_c('div',{staticClass:"bulk",class:{ 'bulk-hidden': !_vm.selected.length }},[_c('v-btn',{staticStyle:{"margin-right":"10px"},attrs:{"color":"primary"},on:{"click":_vm.publish}},[_vm._v("Bulk Publish")]),_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":_vm.unpublish}},[_vm._v("Bulk Unpublish")])],1),(_vm.list)?_c('div',[(_vm.list.length)?_c('div',[_c('v-data-table',{staticClass:"elevation-0 data-table",attrs:{"headers":_vm.headers,"items":_vm.list,"item-key":"id","show-expand":"","disable-pagination":"","items-per-page":-1,"hide-default-footer":true,"show-select":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('tr',{staticClass:"data-table__row"},[_c('td',{on:{"click":function($event){$event.stopPropagation();}}},[_c('v-checkbox',{attrs:{"value":isSelected},on:{"change":function($event){return select($event)}}})],1),_vm._l((_vm.headers),function(td,idx){return _c('td',{key:idx},[(_vm.entity.manual_order && td.type == 'order')?_c('div',{staticClass:"submissions-table__order-actions"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.moveUp(item.id)}}},[_c('v-icon',[_vm._v("$arrow_up")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.moveDown(item.id)}}},[_c('v-icon',[_vm._v("$arrow_down")])],1)],1):_vm._e(),(td.type == 'text')?_c('div',[_vm._v(_vm._s(item[td.value]))]):(td.type == 'created_at')?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at, "timezone", _vm.currentUser.active_project.timezone, "MM/DD/YY h:mm A zz" ))+" ")]):(td.type == 'status')?_c('div',[(item.published)?_c('div',{staticClass:"status status--published"},[_vm._v(" Published ")]):_c('div',{staticClass:"status status--not-published"},[_vm._v(" Not published ")])]):(td.type == 'Select')?_c('div',[_vm._v(" "+_vm._s(_vm.getSelectLabel(item, td))+" ")]):(td.generic)?_c('div',[(item.indexed_formatted_data[td.uuid])?_c('div',[(
                      td.type == 'MediaField' &&
                      item.indexed_formatted_data[td.uuid].value
                    )?_c('div',[_c('a',{attrs:{"target":"_blank","href":item.indexed_formatted_data[td.uuid].value.url}},[_c('span',[_vm._v(_vm._s(_vm.getName( item.indexed_formatted_data[td.uuid].value.url )))])])]):_c('div',[(
                        item.indexed_formatted_data[td.uuid] &&
                        item.indexed_formatted_data[td.uuid].value
                      )?_c('span',[_vm._v(" "+_vm._s(item.indexed_formatted_data[td.uuid].value)+" ")]):_c('span',[_vm._v("N/A")])])]):_c('span',[_vm._v("N/A")])]):(td.type == 'actions')?_c('div',[_c('Actions',{attrs:{"hide-duplicate":"","path":("submission-forms/" + (_vm.entity.id) + "/submissions/" + (item.id))},on:{"destroy":function($event){return _vm.destroy(item)}}})],1):_vm._e()])})],2)]}}],null,false,4022667457),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_c('div',{staticClass:"no-entries"},[_vm._v("No Submissions")])]):_c('Spinner')],1)}
var staticRenderFns = []

export { render, staticRenderFns }