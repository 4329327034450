<template>
  <div>
    <div class="page-title">Edit Member</div>
    <div v-if="currentUser.active_team_role == 'super_admin'">
      <Form
        v-if="form"
        v-model="form"
        :submit="submit"
        btn-text="Update Member"
      />
    </div>
    <div v-else>You do not have access to member settings.</div>
  </div>
</template>

<script>
import Form from "./_form.team-members.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.TeamMember.get({
        id: this.$route.params.id,
      });
      this.form = data;
    },
    async submit() {
      await this.$api.TeamMember.update(this.form);
      if (this.form.id == this.currentUser.id)
        this.$store.dispatch("user/fetch");
      this.$router.push(`/teams/${this.currentUser.active_team_id}/edit`);
      this.$allertWindow("Team member updated successfully");
    },
  },
};
</script>

<style scoped lang="scss"></style>
