<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    :items="items"
    :chips="multiple"
    outlined
    rounded
    height="40"
    :label="label"
    :multiple="multiple"
    class="tag-selector"
    :class="{ multiple }"
    :item-text="itemText"
    :item-value="itemValue"
    :deletable-chips="multiple"
    :clearable="!multiple"
    hide-details="auto"
    :rules="rules"
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    placeholder: String,
    label: String,
    value: [String, Number, Array],
    multiple: Boolean,
    returnObject: Boolean,
    rules: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "title",
    },
    itemValue: {
      type: [Number, String],
      default: "id",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
.tag-selector {
  width: 100%;
  max-width: 270px;

  &.multiple {
    max-width: 100%;
  }

  &::v-deep button.v-icon.v-chip__close {
    &:after {
      content: "✕";
      position: relative;
      color: var(--v-text-base);
      font-size: 12px;
      opacity: 1;
      background: transparent;
      padding: 3px;
    }

    &:hover:after {
      color: var(--v-primary-base);
    }

    svg {
      display: none;
    }
  }

  &::v-deep {
    fieldset {
      border-color: var(--v-border-base);
    }

    .v-input__slot:hover fieldset {
      border-color: rgba(0, 0, 0, 0.3);
    }

    &.v-text-field--outlined.v-input--has-state fieldset {
      border-color: red;
    }
  }
}
</style>
