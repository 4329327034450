<template>
  <div
    @paste="handlePaste"
    :class="{ 'no-drag': blockDrag }"
    @dragover="blockDrag = true"
    @mouseleave="blockDrag = false"
  >
    <el-tiptap v-model="content" :extensions="extensions" />
  </div>
</template>

<script>
import {
  Doc,
  Text,
  Paragraph,
  // text extensions
  Bold,
  Underline,
  Italic,
  Strike,
  Code,
  FontType,
  TextColor,
  TextHighlight,
  FormatClear,
  // paragraph extensions
  Heading,
  ListItem,
  BulletList,
  OrderedList,
  TextAlign,
  LineHeight,
  Indent,
  Blockquote,
  // rich and tools extensions
  Link,
  Image,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  // Iframe,
  HorizontalRule,
  Fullscreen,
  Print,
  Preview,
  SelectAll,
  History,
  HardBreak,
} from "element-tiptap";

import CustomIframe from "./blog/CustomIframe";

export default {
  props: {
    value: String,
    simple: Boolean,
  },

  data() {
    var self = this;
    return {
      blockDrag: false,
      content: this.value,
      extensions: this.simple
        ? [
            new Doc(),
            new Text(),
            new Paragraph(),
            new Bold({ bubble: true }),
            new Underline({ bubble: true }),
            new Italic({ bubble: true }),
            new FormatClear(),
            new Heading({ level: 5 }),
            new ListItem(),
            new BulletList(),
            new OrderedList(),
            new TextAlign(),
            new Indent(),
            new Blockquote(),
            new Link({ bubble: true }),
          ]
        : [
            new Doc(),
            new Text(),
            new Paragraph(),
            new Bold({ bubble: true }),
            new Underline({ bubble: true }),
            new Italic({ bubble: true }),
            new Strike({ bubble: true }),
            new Code(),
            new FontType(),
            new TextColor({ bubble: true }),
            new TextHighlight({ bubble: true }),
            new FormatClear(),
            new Heading({ level: 5 }),
            new ListItem(),
            new BulletList(),
            new OrderedList(),
            new TextAlign(),
            new LineHeight(),
            new Indent(),
            new Blockquote(),
            new Link(),
            new Image({
              uploadRequest: async function (file) {
                return self.uploadRequest(file);
              },
            }),
            new CustomIframe(),
            // new Iframe(),
            new Table({ resizable: true }),
            new TableHeader(),
            new TableCell(),
            new TableRow(),
            new HorizontalRule(),
            new Print(),
            new Preview(),
            new SelectAll(),
            new Fullscreen(),
            new History(),
            new HardBreak(),
          ],
    };
  },
  methods: {
    async uploadRequest(file) {
      try {
        const response = await this.$api.ImageAsset.create({
          title: `Asset for Rich Text Editor`,
          asset: file,
        });

        if (response && response.data.asset.large.url) {
          // get the img; response's urls give a weird DO/tmp url that 404s
          const img = await this.$api.ImageAsset.get({
            id: response.data.id,
          });
          return Promise.resolve(img.data.asset.large.url);
        } else {
          throw new Error("Invalid response data");
        }
      } catch (error) {
        return Promise.reject(error);
      }
    },

    handlePaste(e) {
      const items = Array.from(e.clipboardData?.items || []);

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.type.indexOf("image") === 0) {
          // Image detected, prevent default behavior (i.e., pasting)
          e.preventDefault();
          return;
        }
      }
    },
  },
  watch: {
    value(v) {
      if (v != this.content) this.content = v;
    },
    content(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-tiptap-editor__content p {
  margin-bottom: 12px;
  line-height: 130%;
}
.no-drag ::v-deep * {
  pointer-events: none;
}
</style>
