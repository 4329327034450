<template>
  <div>
    <div class="page-title">GoogleDrive Settings</div>
    <TextField label="Api Key" v-model="localValue.data.api_key" />
    <TextField label="Client ID" v-model="localValue.data.client_id" />
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
};
</script>

<style></style>
