<template>
  <div>
    <SchemaFieldRelationSelector :value="value" @update="update" multiple />
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  methods: {
    update($event) {
      console.log($event);
      this.$emit("update", $event);
    },
  },
};
</script>

<style></style>
