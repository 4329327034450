<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Notification Info">
        <FormRow
          label="Title"
          required
          tooltip="This is the title of the Notifcation as it will be displayed on the Admin side of your site."
        >
          <TextField v-model="form.title" placeholder="Title" />
        </FormRow>
        <FormRow
          label="Notification Type"
          required
          tooltip="There are three types of notifications you can use on Commons, pick the one that's best for your site."
        >
          <NotificationTypeSelector v-model="form.notification_type" />
        </FormRow>
        <div v-if="['popup', 'sidebar'].includes(form.notification_type)">
          <FormRow label="Body Text">
            <TextArea v-model="form.description" placeholder="Body Text" />
          </FormRow>
          <FormRow label="Link URL">
            <TextField
              v-model="form.link_to"
              placeholder="Link URL"
              :rules="[validation.url]"
            />
          </FormRow>
          <FormRow label="Link Display Name">
            <TextField
              v-model="form.link_text"
              placeholder="Link Display Name"
            />
          </FormRow>
        </div>
      </FormPanel>
      <FormPanel
        label="Notification Timing"
        tooltip="Here you can schedule your notification to activate at a certain time."
      >
        <FormRow label="Send At">
          <DateTimePicker
            v-model="form.send_at"
            placeholder="Select Date/Time"
          />
        </FormRow>
      </FormPanel>
      <FormPanel
        label="Notification Details"
        tooltip="Select the page or pages on which you'd like this notification to appear."
      >
        <FormRow label="Display Page(s)">
          <PagesSelector v-model="form.page_ids" multiple />
        </FormRow>
        <FormRow
          label="Display Event(s)"
          tooltip="If you only want visitors to see this notification if they are currently viewing certain event(s), add them here."
        >
          <EventsSelector v-model="form.event_ids" multiple />
        </FormRow>
        <FormRow
          label="Excluded Event(s)"
          tooltip="If you don't want visitors to see this notification if they are currently viewing certain event(s), add them here."
        >
          <EventsSelector v-model="form.excluded_event_ids" multiple />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ btnText }}
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from '../../_extends/form.extends.js';

export default {
  extends: mixin,
};
</script>

<style></style>
