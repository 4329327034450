<template>
  <div>
    <v-autocomplete
      v-model="schemaId"
      label="Schema"
      :items="list"
      hide-no-data
      hide-details
      item-text="title"
      item-value="id"
      :rules="rules"
      :multiple="multiple"
    ></v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    rules: Array,
    multiple: Boolean,
    schemaUuid: String,
  },
  data() {
    return {
      list: [],
      schemaId: this.value?.schema_id || (this.multiple ? [] : null),
    };
  },
  created() {
    this.setList();
  },
  methods: {
    async setList() {
      const { data } = await this.$api.DataLoaderSchema.lite();
      if (this.schemaUuid) {
        this.list = data.filter((s) => s.id !== this.schemaUuid);
      } else {
        this.list = data;
      }
    },
  },
  watch: {
    schemaId: {
      deep: true,
      handler() {
        this.$emit("update", { ...this.value, schema_id: this.schemaId });
      },
    },
  },
};
</script>

<style></style>
