<template>
  <div class="event-slide-form">
    <div class="event-slide-form__head">
      <div class="event-slide-form__title">Event Slide</div>
      <div class="event-slide-form__head-actions">
        <v-btn icon @click="$emit('up')">
          <v-icon size="24"> $arrow_up </v-icon>
        </v-btn>
        <v-btn icon @click="$emit('down')">
          <v-icon size="24"> $arrow_down </v-icon>
        </v-btn>
        <v-btn icon @click="$emit('delete')">
          <v-icon size="24"> $delete </v-icon>
        </v-btn>
      </div>
    </div>
    <div class="event-slide-form__body">
      <FormRow>
        <template #label> Slide Image<br />(1920x1080) </template>
        <Uploader
          :ratio="1920 / 1080"
          :value="value.preview"
          @input="$emit('input', { ...value, preview: $event })"
          enableCropper
        />
      </FormRow>
      <FormRow label="Link URL">
        <TextField
          placeholder="URL"
          :value="value.url"
          :rules="[validation.url]"
          @input="$emit('input', { ...value, url: $event })"
        />
      </FormRow>
      <FormRow label="Link opens in">
        <Select
          placeholder="Open URL"
          :items="openUrlItems"
          :value="value.open_url"
          @input="$emit('input', { ...value, open_url: $event })"
        />
      </FormRow>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      openUrlItems: [
        { name: 'New Tab', value: '_blank' },
        { name: 'Current Tab', value: '_self' },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.event-slide-form {
  border: 1px solid var(--v-border-base);
  border-radius: 20px;
  margin-bottom: 20px;
  margin-top: 10px;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid var(--v-border-base);
  }

  &__title {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
  }

  &__body {
    padding: 20px;
  }
}
</style>
