var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projects-page"},[(['super_admin', 'admin'].includes(_vm.currentUser.active_team_role))?_c('v-btn',{staticClass:"pull-right",attrs:{"to":("/teams/" + (_vm.currentUser.active_team.id) + "/edit"),"large":"","outlined":"","color":"primary"}},[_vm._v("Edit Team")]):_vm._e(),_c('div',{staticClass:"page-title"},[_vm._v("Projects")]),_c('div',{staticClass:"projects"},_vm._l((_vm.activeProjects),function(project){return _c(['super_admin', 'admin'].includes(_vm.currentUser.active_team_role)
          ? 'router-link'
          : 'div',{key:project.id,tag:"component",staticClass:"project",class:{
        active:
          _vm.currentUser.active_project &&
          _vm.currentUser.active_project.id == project.id,
      },attrs:{"to":("/projects/" + (project.id) + "/edit")}},[(['super_admin', 'admin'].includes(_vm.currentUser.active_team_role))?_c('div',{staticClass:"project__actions"},[_c('v-btn',{attrs:{"icon":"","to":("/projects/" + (project.id) + "/edit")}},[_c('v-icon',[_vm._v("$cog")])],1)],1):_vm._e(),_c('div',{staticClass:"project__preview",style:({ backgroundImage: ("url(" + (project.preview.thumb.url) + ")") })},[(!project.preview.thumb.url)?_c('Avatar',{attrs:{"id":project.id,"name":project.title}}):_vm._e(),_c('v-icon',{staticClass:"project__selected",attrs:{"color":"primary"}},[_vm._v("$circle_checkbox_on")])],1),_c('div',{staticClass:"project__container"},[_c('div',{staticClass:"project__title"},[_vm._v(_vm._s(project.title))]),_c('div',{staticClass:"project__description"},[_vm._v(" by "),_c('u',{staticStyle:{"color":"#8e8989"}},[_vm._v(_vm._s(project.team_name))])]),_c('div',{staticClass:"project__overlay"},[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.selectProject(project)}}},[_vm._v("Go to Project")])],1)])])}),1),(
      _vm.currentUser.active_team_role == 'super_admin' &&
      _vm.archivedProjects &&
      _vm.archivedProjects.length
    )?_c('div',[_c('div',{staticClass:"page-title"},[_vm._v("Archived Projects")]),_c('div',{staticClass:"projects"},_vm._l((_vm.archivedProjects),function(project){return _c('div',{key:project.id,staticClass:"project project-archived",class:{
          active:
            _vm.currentUser.active_project &&
            _vm.currentUser.active_project.id == project.id,
        },attrs:{"to":("/projects/" + (project.id) + "/edit")}},[_c('div',{staticClass:"project__preview",style:({ backgroundImage: ("url(" + (project.preview.thumb.url) + ")") })},[(!project.preview.thumb.url)?_c('Avatar',{attrs:{"id":project.id,"name":project.title}}):_vm._e()],1),_c('div',{staticClass:"project__container"},[_c('div',{staticClass:"project__title"},[_vm._v(_vm._s(project.title))]),_c('div',{staticClass:"project__description"},[_vm._v(" by "),_c('u',{staticStyle:{"color":"#8e8989"}},[_vm._v(_vm._s(project.team_name))]),_c('br'),_vm._v(" Will be deleted on "),_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.willBeDeletedOn(project.archived_at), "timezone", _vm.currentUser.active_project ? _vm.currentUser.active_project.timezone : "America/New_York", "MM/DD" )))])]),_c('div',{staticClass:"project__overlay"},[_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){$event.preventDefault();return _vm.unarchiveProject(project)}}},[_vm._v("Restore")])],1)])])}),0)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }