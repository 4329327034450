<template>
  <div>
    <v-checkbox
      label="Enable Cropper"
      v-model="localValue.enableCropper"
      hide-details
    ></v-checkbox>
    <div class="cropper-settings" v-if="localValue.enableCropper">
      <div class="label">Proportions:</div>
      <div class="proportions">
        <div
          class="proportion"
          :key="idx"
          v-for="(proportion, idx) in localValue.proportions"
        >
          <v-text-field
            :rules="[validation.required]"
            type="number"
            v-model="proportion.w"
          ></v-text-field>
          <span class="proportion-x">X</span>
          <v-text-field
            :rules="[validation.required]"
            type="number"
            v-model="proportion.h"
          ></v-text-field>
          <v-text-field
            type="text"
            label="Techname"
            :rules="[validation.required]"
            class="proportion-techname"
            v-model="proportion.techname"
          ></v-text-field>
          <v-btn icon @click="localValue.proportions.splice(idx, 1)">
            <v-icon>$delete</v-icon>
          </v-btn>
        </div>
      </div>
      <v-btn small outlined @click="localValue.proportions.push({ w: 1, h: 1 })"
        >+ Add Proportion</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
  },
  data() {
    return {
      localValue: {
        ...this.value,
        enableCropper: false,
        cropperProportions: [1, 1], //legacy
        proportions: [],
      },
    };
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        this.$emit("update", { ...this.localValue });
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.value) != JSON.stringify(this.localValue)) {
          this.localValue = { ...this.value };
          if (!this.localValue.proportions) {
            const defaultProportion = [];
            if (this.localValue.cropperProportions) {
              defaultProportion.push({
                w: this.localValue.cropperProportions[0],
                h: this.localValue.cropperProportions[1],
                techname: "",
              });
            }
            this.localValue = {
              ...this.localValue,
              proportions: defaultProportion,
            };
          }
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.proportions {
  .v-input {
    max-width: 50px;
  }
}

.proportion-techname {
  max-width: 100px !important;
  margin-left: 15px;
}

.proportion-x {
  margin: 0 20px;
}

.proportion {
  display: flex;
  align-items: center;
}
</style>
