<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Variable Info">
        <FormRow required label="Tech name">
          <TextField
            :disabled="form.id || form.predefined"
            placeholder="Tech name"
            v-model="form.slug"
          ></TextField>
        </FormRow>
        <FormRow label="Variable Type" required>
          <RadioGroup :items="variableTypes" v-model="form.source_type" />
        </FormRow>
        <FormRow
          required
          label="Value (HTML Support)"
          v-if="form.source_type == 'text'"
        >
          <CodeEditor lang="text/html" v-model="form.en" />
        </FormRow>
        <FormRow required label="Image" v-if="form.source_type == 'image'">
          <Uploader
            :ratio="1920 / 1080"
            v-model="form.en_image"
            enableCropper
          />
        </FormRow>
      </FormPanel>
    </FormPanels>

    <SubmitFooter :refs="$refs" :submit="submit"
      >{{ btnText }}
      <template v-if="form.id" #actions>
        <div>
          <v-btn
            @click="$emit('destroy')"
            outlined
            color="primary"
            elevation="0"
            rounded
            large
          >
            Delete
          </v-btn>
        </div>
      </template>
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";
export default {
  extends: mixin,
  data() {
    return {
      variableTypes: [
        { value: "text", title: "Text" },
        { value: "image", title: "Image" },
      ],
    };
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.warning {
  border: 3px solid rgb(255, 85, 0);
  border-radius: 8px;
  background: orange;
  font-size: 18px;
  padding: 15px;
  margin: 20px 0;
}
.timeframe {
  position: relative;

  &-destroy-overlay {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    background: rgba(255, 255, 255, 0.8);
    align-items: center;
  }

  &.deleted {
    .timeframe-destroy-overlay {
      display: flex;
    }

    .delete-timeframe {
      display: none;
    }
  }
  &-deleted {
    position: relative;
    left: 200px;
    text-align: center;
    line-height: 2;
    font-size: 13px;
  }
}
</style>
