<template>
  <Choise @update="$emit('update', $event)" :value="value" />
</template>

<script>
import Choise from "./Choise.vue";
export default {
  components: { Choise },
  props: {
    value: Object,
  },
};
</script>

<style scoped lang="scss"></style>
