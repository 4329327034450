<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Custom Component Info">
        <FormRow label="Title" required>
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
      </FormPanel>
      <FormPanel label="Custom Component Code">
        <CustomComponentsSelector v-model="form.imported_component_ids" />
        <br />
        <br />

        <v-tabs v-model="isRemote">
          <v-tab>Source Code</v-tab>
          <v-tab>Remote URL</v-tab>
        </v-tabs>
        <div v-if="isRemote">
          <FormRow label="Remote URL" required>
            <TextField
              style="width: 400px"
              placeholder="Remote URL"
              v-model="form.remote"
            ></TextField>
          </FormRow>
        </div>
        <div class="custom-component-code" v-else>
          <div class="custom-component-code__editor">
            <CodeEditor lang="text/x-vue" v-model="form.code" />
          </div>
          <div class="custom-component-code__result"></div>
        </div>
      </FormPanel>
      <FormPanel label="Custom Component Details">
        <FormRow label="Display on Pages">
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
        <FormRow label="Place in Layout">
        <Select
            placeholder="Place in Layout"
            :items="placesInLayout"
            v-model="form.place_in_layout"
            item-value="value"
            item-text="title"
          />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";

export default {
  extends: mixin,
  data() {
    return {
      isRemote: 0,
      placesInLayout: [
        {
          value: 'none',
          title: "None"
        },
        {
          value: 'sidebar_top',
          title: "Sidebar, top"
        },
        {
          value: 'sidebar_bottom',
          title: "Sidebar, bottom"
        },
        {
          value: 'header_top',
          title: "Header, top"
        },
        {
          value: 'header_bottom',
          title: "Header, bottom"
        },
      ]
    };
  },
  created() {
    this.isRemote = this.form.is_remote ? 1 : 0;
  },
};
</script>

<style scoped lang="scss"></style>
