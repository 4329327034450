<template>
  <div>
    <div class="page-title">Admin Logs</div>
    <Banner theme="green">
      <template v-slot:title> About this feature </template>
      <template v-slot:description>
        Here you can find logs about admin's interactions with the current
        project.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/support.svg" alt="Site Support" />
      </template>
    </Banner>

    <IndexPageActions
      search-placeholder="Search Logs"
      :search-text.sync="searchText"
    />

    <LogsDataTable v-if="list" show-more v-model="list" />

    <InfiniteLoading :identifier="infiniteId" @infinite="fetchList">
      <div slot="no-more"></div>
      <div slot="no-results">No results</div>
    </InfiniteLoading>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import LogsDataTable from "@/components/dataview/logs.datatable";
import _ from "lodash";

export default {
  name: "LogsPage",
  components: {
    LogsDataTable,
    InfiniteLoading,
  },
  data() {
    return {
      list: null,
      searchText: "",
      page: 1,
      infiniteId: 1,
    };
  },
  methods: {
    async fetchList($state) {
      const { data } = await this.$api.Logs.get({
        search: this.searchText,
        page: this.page,
      });
      this.list = [...(this.list || []), ...data];
      this.page += 1;

      if ($state && data.length) {
        $state.loaded();
      } else {
        $state.complete();
      }
    },
    async destroySection(section) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.SupportForm.destroy({ id: section.id });
      this.list = this.list.filter((t) => t.id != section.id);
    },
    async duplicateSection(section) {
      await this.$api.SupportForm.duplicate({ id: section.id });
      this.fetchList();
    },
    debounceFetch: _.debounce(function () {
      this.fetchList();
    }, 300),
  },
  watch: {
    searchText() {
      this.infiniteId += 1;
      this.page = 1;
      this.list = null;
      this.debounceFetch();
    },
  },
};
</script>

<style scoped lang="scss"></style>
