<template>
  <div>
    <div class="page-title">New Entity</div>
    <Form v-if="form" v-model="form" :submit="create" btnText="Add Entity" />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.data-loader-entities.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.DataLoaderEntity.new({
        schema_id: this.$route.params.schema_id,
      });
      delete data.page;

      if (data.schema.schema_type === "page") {
        const { data: data2 } = await this.$api.Page.new();
        data2.project_id = this.currentUser.active_project.id;
        this.form = {
          ...data,
          schema_id: this.$route.params.schema_id,
          page_attributes: data2,
        };
      } else {
        this.form = {
          ...data,
          schema_id: this.$route.params.schema_id,
        };
      }
    },
    async create() {
      await this.$api.DataLoaderEntity.create(this.form);
      this.$router.push("/content-management");
    },
  },
};
</script>

<style></style>
