import Vue from "vue";

/* eslint-disable no-useless-escape */
const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/* eslint-disable no-useless-escape */
const urlRegexp =
  /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

const numberRegexp = /[0-9]/;

Vue.prototype.validation = {
  email: (v) => !v || emailRegexp.test(v) || "Invalid email",
  required: (v) => {
    return !!(v || "").toString().trim() || "Required field";
  },
  urlOrMailto: (v) => {
    if (!v) return true;
    if (v.indexOf("mailto:") == 0) return true;
    return Vue.prototype.validation.url(v);
  },
  url: (v) => {
    if (!v) return true;
    if (v.toString().trim().indexOf("/") == 0) return true;
    if (!v.toString().trim().indexOf("https://") == 0)
      return "Url must start with “https://” for external links, or “/” for relative links.";
    if (!urlRegexp.test(v)) return "Incorrect URL";
    return true;
  },
  password: (v) =>
    (v && v.length >= 6) || "Password must be at least 6 characters long",
  passwordConfirmation: (password) => (v) =>
    v == password || "Password and confirmation are different",
  project: {
    stagingDomain: (v) => {
      const notAllowed = v.match(/[^a-zA-Z0-9-]/);
      if (notAllowed && notAllowed[0]) {
        return `Incorrect Character "${notAllowed[0]}"`;
      }
      return true;
    },
    productionDomain: (v) => {
      if (!v) return true;

      const valid = v.match(
        /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/
      );

      if (valid) {
        return true;
      }
      return "Invalid Domain";
    },
  },
  storageLimit: (v) => !v || numberRegexp.test(v) || "That is not a number",
};
