<template>
  <div>
    <Banner theme="green">
      <template v-slot:title> People Lists </template>
      <template v-slot:description>
        Lists allow you to create and edit running catalogs based on the data
        input. Think people lists, sponsor lists, etc.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/widgets/lists.svg" alt="People Lists" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New List"
      btn-to="/widgets/lists/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Lists"
      :search-text.sync="searchText"
    />

    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length">
        <ListPanel
          v-for="section in computedList"
          :key="section.id"
          :action-path="`/widgets/lists/${section.id}`"
          :title="section.title"
          add-btn-text="Add entry"
          :add-btn-link="`/widgets/lists/${section.id}/entries/new`"
          @destroy="destroyList(section)"
          @duplicate="duplicateList(section)"
        >
          <ListsItemsDataTable
            :entity="section"
            :list="section.entries"
            @moveUp="moveUp($event, section)"
            @moveDown="moveDown($event, section)"
          />
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Lists</div>
    </ListPanels>
  </div>
</template>

<script>
import _ from "lodash";
import IndexPage from "../index-page";
import ListsItemsDataTable from "@/components/dataview/lists-entries.datatable";

export default {
  name: "WidgetsListsPage",
  components: { ListsItemsDataTable },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.List.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroyList(section) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.List.destroy({ id: section.id });
      this.list = this.list.filter((t) => t.id != section.id);
    },
    async duplicateList(section) {
      await this.$api.List.duplicate(section);
      this.fetchList();
    },
    moveUp(idx, section) {
      if (idx < 1) return;
      const entry = { ...section.entries[idx] };
      section.entries.splice(idx, 1);
      section.entries.splice(idx - 1, 0, entry);
      this.updateEntryPositions(section);
    },
    moveDown(idx, section) {
      if (idx >= section.entries.length - 1) return;
      const entry = { ...section.entries[idx] };
      section.entries.splice(idx, 1);
      section.entries.splice(idx + 1, 0, entry);
      this.updateEntryPositions(section);
    },
    updateEntryPositions: _.debounce(async function (section) {
      if (section.sort_by == "position") {
        section.entries.forEach((entry, index) => {
          entry.position = index;
        });
        const listParams = _.pick(section, ["id", "entries"]);
        await this.$api.List.updateEntries(listParams);
      }
    }, 300),
  },
};
</script>

<style scoped lang="scss"></style>
