<template>
  <div class="project-selector">
    <router-link
      :to="`/projects/${currentUser.active_project.id}/edit`"
      class="project-selector__container"
    >
      <div class="project-selector__preview">
        <div
          :style="{
            backgroundImage: `url(${currentUser.active_project.preview.thumb.url})`,
          }"
          v-if="currentUser.active_project.preview.thumb.url"
          class="project-selector__preview-image"
        ></div>
        <Avatar
          :id="currentUser.active_project.id"
          :name="currentUser.active_project.title"
          v-else
        />
      </div>
      <div class="project-selector__content">
        <div class="project-selector__title">
          {{ currentUser.active_project.title }}
        </div>
        <div class="project-selector__sub-title">
          {{ currentUser.active_team.title }}
        </div>
      </div>
    </router-link>
    <router-link to="/projects" class="project-selector__arrow">
      <v-icon>$chevron_right</v-icon>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.project-selector {
  border-radius: 5px;
  cursor: pointer;
  justify-content: space-between;
  text-decoration: none;
  background: #ebebeb87;
  margin-left: 25px;
  margin-bottom: 25px;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  border: 2px solid #dcdce0;

  &__title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: -2px;
  }

  &__sub-title {
    font-size: 12px;
    font-weight: 500;
    color: #a5a5a5;
  }

  &__container {
    padding: 10px 15px;
    width: calc(100% - 34px);
    color: var(--v-text-base);
    text-decoration: none;

    &:hover {
      background: #ebebeb;
    }
  }

  .v-icon {
    display: block;
  }

  &,
  &__container {
    display: flex;
    align-items: center;
  }

  &__arrow {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0 5px;
    display: flex;
    align-items: center;
    transition: 0.3s;
    background: transparent;
    border-left: 1px solid #dcdce073;

    &:hover {
      background: #ebebeb;
    }
  }

  &__preview {
    width: 30px;
    min-width: 30px;
    height: 30px;
    min-height: 30px;
    border-radius: 30px;
    margin-right: 15px;
    background: #e7e7e7;
    background-size: cover;
    background-position: center;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
    background: white;
    overflow: hidden;

    &-image {
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: cover;
    }
  }
}
</style>
