<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Venue Events List Info">
        <FormRow label="Venue Events List Title" required>
          <TextField
            placeholder="Venue Events List Title"
            v-model="form.title"
          ></TextField>
        </FormRow>
        <FormRow label="Show Title">
          <Checkbox
            v-model="form.show_title"
          ></Checkbox>
        </FormRow>
        <FormRow label="Max Items">
          <TextField
            placeholder="Max Items"
            v-model="form.max_items"
          ></TextField>
        </FormRow>
        <FormRow label="See More Link">
          <TextField
            placeholder="See More Link"
            v-model="form.see_more_link"
          ></TextField>
        </FormRow>
        <FormRow label="See More Text">
          <TextField
            placeholder="See More Text"
            v-model="form.see_more_text"
          ></TextField>
        </FormRow>
      </FormPanel>

      <FormPanel label="Venue Events List Details">
        <FormRow label="Display on Pages">
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";

export default {
  extends: mixin,
};
</script>

<style></style>
