<template>
  <div class="venue-event-selector" v-if="list.length">
    <TagSelector
      :multiple="multiple"
      :items="computedList"
      :value="value"
      @input="$emit('input', $event)"
      itemText="label"
      itemValue="id"
      class="venue-event-selector__tag-selector"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number, Array],
    excludeId: String,
    multiple: Boolean,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.setVenueEvents();
  },
  methods: {
    async setVenueEvents() {
      const { data } = await this.$api.VenueEvent.lite();
      this.list = [
        { label: "N/A", id: null },
        ...data,
      ];
    },
  },
  computed: {
    computedList() {
      if(this.excludeId)
        return this.list.filter((item) => item.id != this.excludeId );
      else
        return this.list;
    }
  }
};
</script>

<style lang="scss" scoped>
.venue-event-selector__tag-selector {
  max-width: unset;
}
</style>
