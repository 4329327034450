<template>
  <v-dialog
    @keydown.enter="confirm"
    @click:outside="cancel"
    max-width="400"
    v-model="dialog"
  >
    <v-card>
      <v-btn class="dialog-close" icon @click="cancel">
        <v-icon>$close</v-icon>
      </v-btn>
      <v-card-title class="dialog-title">
        {{ data.title }}
      </v-card-title>

      <v-card-text class="dialog-description">
        {{ data.description }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="margin-right: 15px" text @click="cancel"> Cancel </v-btn>

        <v-btn color="primary" @click="confirm"> Confirm </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  data() {
    return {
      data: {},
      dialog: null,
    };
  },
  mounted() {
    this.$root.$on("confirmDialog", async (data) => {
      this.dialog = true;
      this.data = data;
    });
  },
  methods: {
    confirm() {
      this.data.callback(true);
      this.dialog = false;
      this.data = {};
    },
    cancel() {
      this.data.callback(false);
      this.dialog = false;
      this.data = {};
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.dialog-title {
  padding-top: 25px !important;
  line-height: 1.2;
  word-break: break-word;
}

.dialog-description {
  white-space: pre-line;
}
</style>
