<template>
  <div>
    <div v-if="list">
      <div v-if="list.length">
        <div
          v-for="item in list"
          :key="item.id"
          :style="{ backgroundImage: `url(${item.preview.large.url})` }"
          class="schedule"
          :class="{ dark: item.dark }"
        >
          <div class="schedule-title">
            {{ item.title }}
          </div>
          <div class="schedule-start-at">
            {{
              item.start_at
                | moment(
                  "timezone",
                  currentUser.active_project.timezone,
                  "MM/DD/YY h:mm A"
                )
            }}
          </div>
          <Actions
            :hide-duplicate="true"
            :path="`/widgets/schedules/${entity.id}/items/${item.id}`"
            @destroy="destroy(item)"
          />
        </div>
      </div>
      <div v-else class="no-entries">No Schedule Items</div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  props: {
    entity: Object,
  },
  data() {
    return {
      list: null,
    };
  },
  created() {
    this.setList();
  },
  methods: {
    async setList() {
      const { data } = await this.$api.ScheduleItem.lite({
        schedule_id: this.entity.id,
      });
      this.list = data;
    },
    async destroy(item) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.ScheduleItem.destroy({ id: item.id });
      this.list = this.list.filter((t) => t.id != item.id);
    },
  },
};
</script>

<style scoped lang="scss">
.schedule {
  margin-bottom: 8px;
  background: var(--v-border-lighten1);
  padding: 20px;
  border-radius: 10px;
  background-size: cover;
  background-position: top;

  &.dark {
    color: white;

    &::v-deep .v-btn--icon {
      color: white;
    }
  }

  &-title {
    font-weight: 900;
    font-size: 20px;
  }
  &-start-at {
    font-size: 12px;
    margin-bottom: 25px;
  }
}
</style>
