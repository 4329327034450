import { render, staticRenderFns } from "./new.teams.page.vue?vue&type=template&id=50df2f14&scoped=true&"
import script from "./new.teams.page.vue?vue&type=script&lang=js&"
export * from "./new.teams.page.vue?vue&type=script&lang=js&"
import style0 from "./new.teams.page.vue?vue&type=style&index=0&id=50df2f14&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50df2f14",
  null
  
)

export default component.exports