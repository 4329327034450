<template>
  <div>
    <div class="page-title">Edit Participant</div>
    <Form
      v-if="form"
      v-model="form"
      :submit="update"
      btnText="Update Participant"
    />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.participants.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.User.get({ id: this.$route.params.id });
      this.form = data;
    },
    async update() {
      await this.$api.User.update(this.form);
      this.$router.push("/users/participants");
    },
  },
};
</script>

<style></style>
