<template>
  <div>
    <TextArea placeholder="Label" v-model="localValue.label" />
    <br />
    <TextArea placeholder="Tip text (optional)" v-model="localValue.tip" />
    <br />
    <TextField placeholder="Button Text" v-model="localValue.button_text" />
    <Checkbox
      class="file-input-checkbox"
      label="File Input"
      v-model="localValue.file_input"
    />
    <Checkbox
      class="file-input-checkbox"
      label="Webcam"
      v-model="localValue.webcam"
    />
    <Checkbox
      v-if="localValue.webcam"
      class="file-input-checkbox"
      label="Enable select still"
      v-model="localValue.select_still"
    />
    <TextField
      v-if="!localValue.webcam"
      label="Accept formats"
      v-model="localValue.accept"
      :placeholder="localValue.file_input ? '*' : 'image/*,video/*'"
    />
    <Checkbox
      class="file-input-checkbox"
      label="Ignore Vimeo Uploading"
      v-model="localValue.ignore_vimeo"
    />
    <Checkbox
      class="file-input-checkbox"
      label="Enable Background Position Sliders"
      v-model="localValue.enable_background_styles"
    />
  </div>
</template>

<script>
import mixin from "./form-builder.mixin.js";
export default {
  extends: mixin,
};
</script>

<style lang="scss" scoped>
.file-input-checkbox {
  margin-top: -1px;
  margin-left: 20px;
}
</style>
