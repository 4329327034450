<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Blog Articles </template>
      <template v-slot:description> Blog Articles </template>
      <template v-slot:image>
        <img src="@/assets/banners/pages.svg" alt="Blog" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Article"
      btn-to="/blog-articles/new"
      :sort-by.sync="sortBy"
      :search-text.sync="searchText"
      search-placeholder="Search Article"
    />

    <div class="page-subtitle">Tags</div>
    <div v-if="rolesList">
      <Stories
        :click-path="(item) => `/blog-tags/${item.id}/edit`"
        create-path="/blog-tags/new"
        :list="rolesList"
        add-btn-text="New Tag"
      />
    </div>
    <Spinner v-else relative />

    <div class="page-subtitle">
      <span> Articles </span>
    </div>
    <div v-if="list">
      <div v-if="computedList.length">
        <BlogArticlesDataTable :list="computedList" @destroy="destroy" />
        <!-- <Pagination :perPage.sync="perPage" :page.sync="page" /> -->
      </div>
      <div v-else class="no-entries">No Articles</div>
    </div>
    <Spinner v-else relative />
  </div>
</template>

<script>
import BlogArticlesDataTable from "@/components/dataview/blog.datatable";
import IndexPage from "@/pages/index-page";

export default {
  name: "BlogPage",
  components: { BlogArticlesDataTable },
  extends: IndexPage,
  data() {
    return {
      rolesList: null,
      searchFields: ["title", "blog_tags"],
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      const { data } = await this.$api.BlogTag.get();
      this.rolesList = data;
    },
    async fetchList() {
      const { data } = await this.$api.BlogArticle.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroy({ id }) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.BlogArticle.destroy({ id });
      this.list = this.list.filter((t) => t.id != id);
    },
  },
};
</script>

<style scoped lang="scss">
.page-subtitle {
  display: flex;
  justify-content: space-between;
}
.actions {
  display: flex;
}
.download-schedule-btn {
  margin-right: 1em;
}
</style>
