<template>
  <div>
    <div class="page-title">New Venue Event</div>
    <Form v-if="form" v-model="form" :submit="create" btnText="Add Venue Event" />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.venue-events.page.vue";

export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async create() {
      await this.$api.VenueEvent.create(this.form);

      this.$router.push("/venue-events");
    },
    async setForm() {
      const { data } = await this.$api.VenueEvent.new();
      this.form = data;
    },
  },
};
</script>

<style></style>
