<template>
  <div class="spinner" :class="{ relative }">
    <slot></slot>
    <HollowDotsSpinner
      :animation-duration="1000"
      :size="100"
      :color="'#EE3624'"
    />
  </div>
</template>

<script>
import { HollowDotsSpinner } from "epic-spinners";

export default {
  components: {
    HollowDotsSpinner,
  },
  props: {
    relative: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scope>
.spinner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 7;
}

.relative {
  position: relative !important;
  min-height: 200px;
}
</style>
