<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr class="data-table__row">
            <td />
            <td>
              <router-link :to="`/events/${item.id}/edit`">
                {{ (item.code ? `${ item.code }: ` : '') + item.title }}
              </router-link>
            </td>
            <td class="nowrap">
              {{
                item.start_at
                  | moment(
                    'timezone',
                    currentUser.active_project.timezone,
                    'MM/DD/YY h:mm A'
                  )
              }}
            </td>
            <td class="nowrap">
              {{
                item.display_time
                  | moment(
                    'timezone',
                    currentUser.active_project.timezone,
                    'MM/DD/YY h:mm A'
                  )
              }}
            </td>
            <td class="nowrap">
              {{
                item.end_at
                  | moment(
                    'timezone',
                    currentUser.active_project.timezone,
                    'MM/DD/YY h:mm A'
                  )
              }}
            </td>
            <td>
              <span v-for="page in item.pages" :key="page.id" class="mr-3">
                <router-link class="nowrap" :to="`/pages/${page.id}/edit`">{{
                  page.title
                }}</router-link>
              </span>
            </td>
            <td class="text-center nowrap">
              <Actions
                :path="`/events/${item.id}`"
                @destroy="$emit('destroy', item)"
                @duplicate="$emit('duplicate', item)"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Event Title',
          align: 'start',
          value: 'title',
        },
        {
          text: 'Start Time',
          align: 'start',
          value: 'start_at',
        },
        {
          text: 'Display Time',
          align: 'start',
          value: 'display_time',
        },
        {
          text: 'End Time',
          align: 'start',
          value: 'end_at',
        },
        {
          text: 'Pages',
          align: 'start',
          value: 'pages',
          sortable: false,
        },
        {
          text: 'Actions',
          align: 'center',
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style></style>
