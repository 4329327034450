<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Tag Info">
        <FormRow required label="Tag Title">
          <TextField placeholder="Tag Title" v-model="form.title"></TextField>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ btnText }}

      <template v-if="action == 'edit'" #actions>
        <div>
          <v-btn
            @click="$emit('destroy')"
            outlined
            color="primary"
            elevation="0"
            rounded
            large
          >
            Delete Tag
          </v-btn>
        </div>
      </template>
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "@/pages/_extends/form.extends.js";

export default {
  extends: mixin,
  props: {
    action: String,
  },
};
</script>

<style></style>
