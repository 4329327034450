<template>
  <v-text-field
    ref="input"
    :value="value"
    hide-details="auto"
    rounded
    outlined
    class="text-field"
    :class="`text-field--${type}`"
    :placeholder="placeholder"
    :label="label"
    :type="localType"
    :rules="rules"
    validate-on-blur
    :suffix="suffix"
    :min="min"
    :max="max"
    :disabled="disabled"
    :dense="dense"
    @input="$emit('input', $event)"
    @change="$emit('change')"
  >
    <template #prepend>
      <slot name="prepend"></slot>
    </template>
    <template #append>
      <div v-if="type == 'password'">
        <v-icon
          class="input-icon-toggle"
          @mousedown.stop.prevent="togglePassword"
        >
          {{ localType == "password" ? "$eye" : "$eye_off" }}
        </v-icon>
      </div>
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    placeholder: String,
    label: String,
    value: [String, Number, Boolean, Object, Array],
    type: String,
    rules: Array,
    suffix: String,
    min: [String, Number],
    max: [String, Number],
    dense: Boolean,
    disabled: Boolean,
  },
  data() {
    return {
      localType: this.type,
    };
  },
  methods: {
    togglePassword() {
      if (this.localType == "password") {
        this.localType = "text";
      } else {
        this.localType = "password";
      }
    },
    validate() {
      this.$refs.input.validate(true);
    },
  },
};
</script>

<style scoped lang="scss">
.text-field {
  width: 270px;

  &--password {
    &::v-deep {
      .v-input__append-inner {
        margin-top: 13px;
      }
    }
  }

  .input-icon-toggle {
    cursor: pointer;
  }

  &::v-deep {
    fieldset {
      border-color: var(--v-border-base);
    }

    .v-input__slot {
      min-height: 50px;
    }

    .v-label {
      margin-top: -3px;
    }

    .v-input__slot:hover fieldset {
      border-color: rgba(0, 0, 0, 0.3);
    }

    &.v-input--has-state fieldset {
      border-color: var(--v-primary-base) !important;
    }

    .v-text-field__details {
      height: 0;
      position: relative;
      margin: 0 !important;
      top: -5px;
      left: 12px;
      min-height: 0;
      overflow: visible;
    }
  }
}
</style>
