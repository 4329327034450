var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.confirm($event)},"click:outside":_vm.cancel},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-btn',{staticClass:"dialog-close",attrs:{"icon":""},on:{"click":_vm.cancel}},[_c('v-icon',[_vm._v("$close")])],1),_c('v-card-title',{staticClass:"dialog-title"},[_vm._v(" Duplicate this project? ")]),_c('v-card-text',[_c('p',{staticClass:"form-subhead"},[_vm._v("This will create a new project based on "),_c('b',[_vm._v(_vm._s(_vm.source_title))]),_vm._v(", including duplicates of all pages, content and widgets. Please set the basic settings for your new site.")]),_c('FormRow',{attrs:{"tooltip":"This is the name your site will be referenced as on the Admin portal.","required":"","label":"Site Name"}},[_c('TextField',{attrs:{"placeholder":"Site Name","rules":[_vm.validation.required]},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('FormRow',{attrs:{"required":_vm.form.restrict_production_access,"label":"Live Site URL","tooltip":"This will be the public URL for your Commons site."}},[_c('v-layout',{attrs:{"align-center":""}},[_c('span',{staticClass:"mr-3 form-detail"},[_vm._v("https://")]),_c('div',[_c('TextField',{attrs:{"placeholder":"example.com","rules":_vm.form.restrict_production_access
                  ? [
                      _vm.validation.required,
                      _vm.validation.project.productionDomain ]
                  : [_vm.validation.project.productionDomain]},model:{value:(_vm.form.production_domain),callback:function ($$v) {_vm.$set(_vm.form, "production_domain", $$v)},expression:"form.production_domain"}})],1)])],1),(_vm.form.production_domain)?_c('div',[_c('FormRow',{attrs:{"label":"Redirect to another Website"}},[_c('RadioGroup',{attrs:{"items":[
              { title: 'No', value: false },
              { title: 'Yes', value: true } ]},model:{value:(_vm.form.production_redirect_enabled),callback:function ($$v) {_vm.$set(_vm.form, "production_redirect_enabled", $$v)},expression:"form.production_redirect_enabled"}})],1),(_vm.form.production_redirect_enabled)?_c('FormRow',{attrs:{"required":"","label":"Redirect URL"}},[_c('TextField',{attrs:{"placeholder":"Redirect URL","rules":[_vm.validation.required, _vm.validation.url]},model:{value:(_vm.form.production_redirect_url),callback:function ($$v) {_vm.$set(_vm.form, "production_redirect_url", $$v)},expression:"form.production_redirect_url"}})],1):_vm._e()],1):_vm._e(),_c('FormRow',{attrs:{"label":"Password Protect Live Site"},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Select \"Yes\" if you want to have password access to the URL above. Note, this is not user authentication, purely domain access. ")]},proxy:true}])},[_c('RadioGroup',{attrs:{"items":[
            { title: 'No', value: false },
            { title: 'Yes', value: true } ]},model:{value:(_vm.form.restrict_production_access),callback:function ($$v) {_vm.$set(_vm.form, "restrict_production_access", $$v)},expression:"form.restrict_production_access"}})],1),(_vm.form.restrict_production_access)?_c('div',[_c('FormRow',{attrs:{"required":"","label":"Live Site Access Username","tooltip":"The username to access the Live Site URL"}},[_c('TextField',{attrs:{"placeholder":"Live Site Access Username","rules":[_vm.validation.required]},model:{value:(_vm.form.production_login),callback:function ($$v) {_vm.$set(_vm.form, "production_login", $$v)},expression:"form.production_login"}})],1),_c('FormRow',{attrs:{"required":"","label":"Live Site Access Password","tooltip":"The password to access the Live Site URL"}},[_c('TextField',{attrs:{"placeholder":"Live Site Access Password","rules":[_vm.validation.required],"type":"password"},model:{value:(_vm.form.production_password),callback:function ($$v) {_vm.$set(_vm.form, "production_password", $$v)},expression:"form.production_password"}})],1)],1):_vm._e(),_c('FormRow',{attrs:{"required":"","label":"Staging Site URL Prefix","tooltip":"This will be the staging url for your Commons site. If the staging domain isn't working, please contact the iU web dev team to set it up."}},[_c('v-layout',{attrs:{"align-center":""}},[_c('span',{staticClass:"mr-3 form-detail"},[_vm._v("https://")]),_c('div',[_c('TextField',{attrs:{"placeholder":"example","rules":[
                _vm.validation.required,
                _vm.validation.project.stagingDomain ]},model:{value:(_vm.form.staging_domain),callback:function ($$v) {_vm.$set(_vm.form, "staging_domain", $$v)},expression:"form.staging_domain"}})],1),_c('span',{staticClass:"mr-3 form-detail"},[_vm._v("."+_vm._s(_vm.rootDomain))])])],1),_c('FormRow',{attrs:{"label":"Password Protect Staging Site"},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" Select \"Yes\" if you want to have password access to the URL above. Note, this is not user authentication, purely domain access. ")]},proxy:true}])},[_c('RadioGroup',{attrs:{"items":[
            { title: 'No', value: false },
            { title: 'Yes', value: true } ]},model:{value:(_vm.form.restrict_staging_access),callback:function ($$v) {_vm.$set(_vm.form, "restrict_staging_access", $$v)},expression:"form.restrict_staging_access"}})],1),(_vm.form.restrict_staging_access)?_c('div',[_c('FormRow',{attrs:{"required":"","label":"Staging Site Access Username"},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" The username to access the Staging Site URL ")]},proxy:true}],null,false,3634692800)},[_c('TextField',{attrs:{"placeholder":"Staging Site Access Username","rules":[_vm.validation.required]},model:{value:(_vm.form.staging_login),callback:function ($$v) {_vm.$set(_vm.form, "staging_login", $$v)},expression:"form.staging_login"}})],1),_c('FormRow',{attrs:{"required":"","label":"Staging Site Access Password"},scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(" The password to access the Staging Site URL ")]},proxy:true}],null,false,1676268873)},[_c('TextField',{attrs:{"placeholder":"Staging Site Access Password","rules":[_vm.validation.required]},model:{value:(_vm.form.staging_password),callback:function ($$v) {_vm.$set(_vm.form, "staging_password", $$v)},expression:"form.staging_password"}})],1)],1):_vm._e(),_vm._t("default")],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticStyle:{"margin-right":"15px"},attrs:{"text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirm(true)}}},[_vm._v(" Duplicate Project ")])],1),_c('Spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.working),expression:"working"}],attrs:{"relative":false}},[_c('v-card',{staticClass:"spinner-card"},[_vm._v(" Working... This might take a while. ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }