import { render, staticRenderFns } from "./lists.page.vue?vue&type=template&id=e74db564&scoped=true&"
import script from "./lists.page.vue?vue&type=script&lang=js&"
export * from "./lists.page.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e74db564",
  null
  
)

export default component.exports