import { axios } from "@/api/api.config";
import objectToFormData from "./object-to-formdata";
import _ from "lodash";

export default (name) => {
  const getName = (params = {}) => {
    return _.template(name)(params);
  };
  return {
    new: (params) =>
      axios.get(`/${getName(params)}/new`, { params, jwt: true }),
    get: (params = {}) => {
      return axios.get(
        `/${getName(params)}${params.id ? "/" + params.id : ""}`,
        {
          params,
          jwt: true,
        }
      );
    },
    create: (params, additional) =>
      axios.post(`/${getName(params)}`, objectToFormData(params), {
        jwt: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const loaded = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (additional?.onUploadProgress) {
            return additional.onUploadProgress(loaded);
          }
        },
      }),
    update: (params, additional) =>
      axios.put(`/${getName(params)}/${params.id}`, objectToFormData(params), {
        jwt: true,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const loaded = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (additional?.onUploadProgress) {
            return additional.onUploadProgress(loaded);
          }
        },
      }),
    destroy: (params) =>
      axios.delete(`/${getName(params)}/${params.id}`, { params, jwt: true }),
  };
};
