<template>
  <div>
    <div class="page-title">New Easy Update</div>
    <Form
      v-if="form"
      v-model="form"
      :submit="create"
      btnText="Add Easy Update"
    />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.variables.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.Variable.new();
      const { slug, content } = this.$route.query;
      let extend = {};
      if (slug) {
        extend = { predefined: true, slug: slug, en: content };
      }
      this.form = { ...data, ...extend };
    },
    async create() {
      await this.$api.Variable.create(this.form);
      this.$router.push("/variables");
    },
  },
};
</script>

<style></style>
