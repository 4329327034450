<template>
  <div>
    <div v-if="ticket">
      <div class="page-title">{{ ticket.title || `Ticket #${ticket.id}` }}</div>
      <div class="panel-box">
        <div class="panel-box__dl">
          <div class="panel-box__dr" v-for="(val, key) in ticket" :key="key">
            <div class="panel-box__dt" v-if="key != 'formatted_data'">
              {{ key }}
            </div>
            <div class="panel-box__dd">
              <a target="_blank" :href="`mailto:${val}`" v-if="key == 'email'">
                {{ val }}
              </a>
              <span v-else-if="['created_at', 'updated_at'].includes(key)">{{
                val
                  | moment(
                    "timezone",
                    currentUser.active_project.timezone,
                    "MM/DD/YY h:mm A"
                  )
              }}</span>
              <div v-else-if="key == 'status'" class="statuses-select">
                <Dropdown
                  @changed="updateStatus"
                  :items="statuses"
                  v-model="ticket.status"
                />
              </div>
              <div v-else-if="key == 'formatted_data'">
                <div :key="index" v-for="(fd, index) in ticket.formatted_data">
                  <div class="panel-box__dt">
                    {{ fd.label }}
                  </div>
                  <a
                    :href="fd.value.url"
                    target="_blank"
                    v-if="fd.type == 'MediaField'"
                    >{{ fd.value.url }}</a
                  >
                  <span v-else>{{ fd.value }}</span>
                </div>
              </div>
              <span v-else>{{ val }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
export default {
  data() {
    return {
      ticket: null,
      statuses: [
        { text: "New", value: "new" },
        { text: "In Progress", value: "in_progress" },
        { text: "Resolved", value: "closed" },
      ],
    };
  },
  created() {
    this.setTicket();
  },
  methods: {
    async setTicket() {
      const { data } = await this.$api.SupportTicket.get({
        id: this.$route.params.id,
      });
      this.ticket = data;
    },
    async updateStatus() {
      await this.$api.SupportTicket.update({
        id: this.$route.params.id,
        status: this.ticket.status,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.statuses-select::v-deep .dropdown-selected {
  width: 270px;
}
</style>
