<template>
  <div>
    <div class="page-title">GDPR Compliance Settings</div>
    <TextField label="Popup Body" v-model="localValue.data.gdpr_popup_body" />

    <TextField
      label="Popup 'Accept' Text"
      v-model="localValue.data.gdpr_popup_accept"
    />

    <TextField
      label="Popup 'Reject' Text"
      v-model="localValue.data.gdpr_popup_deny"
    />

    <!-- <div class="ga-lines">
      <div
        class="ga-line"
        v-for="(ga, idx) in localValue.data.ga_ids"
        :key="idx"
      >
        <TextField
          placeholder="Example: UA-XXX-X"
          label="Secondary Google Analytics ID"
          v-model="localValue.data.ga_ids[idx]"
        />
        <v-btn color="primary" fab x-small @click="deleteGaId(idx)">
          <v-icon>$delete</v-icon>
        </v-btn>
      </div>

      <v-btn color="primary" outlined rounded @click="addGaId"
        >+ Add One More</v-btn
      >
    </div> -->
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
  created() {
    if (!this.localValue.data.gdpr_popup_body) {
      this.$set(
        this.localValue.data,
        "gdpr_popup_body",
        'By clicking "Accept All Cookies", you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.'
      );
      this.$set(
        this.localValue.data,
        "gdpr_popup_accept",
        "Accept All Cookies"
      );
      this.$set(this.localValue.data, "gdpr_popup_deny", "Reject All Cookies");
    }
  },
};
</script>

<style scoped lang="scss">
.ga-lines {
  background: #f7f7f7;
  padding: 10px;
  border: 1px solid #e7e7e7;
}
.ga-line {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &::v-deep {
    .text-field {
      margin: 0;
      margin-right: 15px;
    }

    fieldset {
      background: white;
    }
  }
}

.env-production {
  .ga-lines {
    background: #262729 !important;
    border: none;
  }
}
</style>
