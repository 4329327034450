import _ from "lodash";

export default {
  methods: {
    normalizeComponents(list) {
      return list.filter((t) => !["Notification"].includes(t.type));
    },
    pageLabel(page) {
      switch (page.url_type) {
        case "absolute":
          return page.absolute_url;
        case "anchor_on_page":
          return `/#${page.url}`;
        case "open_submission_form":
          return `Open submission form`;
        case "call_custom_component":
          return `Call to custom component: "${page.url}"`;
        case "parent_menu":
          return `/${page.parent_menu_link_to_url}`;
        default:
          return `/${page.url}`;
      }
    },
    save: _.debounce(async function (page) {
      const pageParams = _.pick(page, ["id", "components"]);
      await this.$api.Page.updateComponents(pageParams);
    }, 300),
  },
};
