<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Venue Event Info">
        <FormRow required label="Title">
          <TextField placeholder="Title" v-model="form.title" />
        </FormRow>
        <FormRow label="Date & Time">
          <div class="form__date">
            {{
              form.datetime
                | moment("timezone", form.timezone, "MMMM DD, YYYY h:mm A zz")
            }}
          </div>
        </FormRow>
        <FormRow label="Date & Time Override">
          <template #tooltip>
            If set, this will override the Date & Time imported from
            Ticketmaster.
          </template>
          <!-- <div class="form__date">
            {{
              form.datetime
                | moment("timezone", form.timezone, "MMMM DD, YYYY h:mm A zz")
            }}
          </div> -->
          <DateTimePicker v-model="form.datetime_override" />
        </FormRow>

        <FormRow label="Description">
          <RichTextEditor v-model="form.description" simple />
        </FormRow>

        <FormRow v-if="form.preview">
          <template #tooltip>
            This is the image provided by Ticketmaster that best fits the
            requirements for preview images in event lists (826x600).
          </template>
          <template #label>Preview Image<br /></template>
          <div class="form__preview">
            <v-responsive
              :aspect-ratio="826 / 600"
              class="form__preview__img"
              :style="{ backgroundImage: `url(${form.preview})` }"
            />
          </div>
        </FormRow>

        <FormRow>
          <template #tooltip>
            If provided, this is the preview image that will override the
            ticketmaster preview image.
          </template>
          <template #label>Preview Image Override<br />(826x600)</template>
          <Uploader
            :ratio="413 / 300"
            v-model="form.preview_override"
            enableCropper
          />
        </FormRow>

        <FormRow v-if="form.preview">
          <template #tooltip>
            This is the image provided by Ticketmaster that best fits the
            requirements for banner images on event pages (as large as is
            available, ideally 2880 x 1000).
          </template>
          <template #label>Banner Image<br /></template>
          <div class="form__banner">
            <v-responsive
              :aspect-ratio="2880 / 1000"
              class="form__banner__img"
              :style="{ backgroundImage: `url(${form.banner})` }"
            />
          </div>
        </FormRow>

        <FormRow>
          <template #tooltip>
            If provided, this is the banner image that will override the
            ticketmaster banner image.
          </template>
          <template #label>Banner Image Override<br />(2880 x 1000)</template>
          <Uploader
            :ratio="2880 / 1000"
            v-model="form.banner_override"
            enableCropper
          />
        </FormRow>

        <FormRow label="Buy Tickets Link">
          <TextField
            class="buy_tickets_link-field"
            placeholder="Title"
            v-model="form.buy_tickets_link"
          />
        </FormRow>

        <FormRow label="Venue">
          <TicketmasterVenueSelector
            v-model="form.ticketmaster_venue_id"
            enabled
          />
        </FormRow>

        <FormRow label="Venue Event Type">
          <Select
            placeholder="Venue Event Type"
            :items="venueEventTypes"
            v-model="form.venue_event_type"
            item-value="value"
            item-text="title"
          />
        </FormRow>

        <FormRow label="Combine with another Venue Event">
          <template #tooltip>
            If this is part of a group of events that is the same event at
            different days or times, they can be “combined” under a main event
            so that they only appear once in Venue Events Lists.
          </template>
          <div
            v-if="form.other_venue_events.length"
            class="form-label form__other-events"
          >
            <div>
              This is a main event. The events that are combined with this one
              are:
            </div>
            <ul class="form__other-events__list">
              <li
                v-for="otherEvent in form.other_venue_events"
                :key="otherEvent.id"
              >
                <span>{{
                  otherEvent.effective_datetime
                    | moment(
                      "timezone",
                      currentUser.active_project.timezone,
                      "MM/DD/YY h:mm A"
                    )
                }}</span>
                |
                <span>{{ otherEvent.title }}</span>
              </li>
            </ul>
          </div>
          <VenueEventSelector
            v-else
            v-model="form.main_venue_event_id"
            enabled
            :excludeId="form.id"
          />
        </FormRow>

        <FormRow label="Is an Add-on Event">
          <template #tooltip>
            If this is an add-on event, check this to exclude it from Venue
            Events Lists.
          </template>
          <Checkbox v-model="form.is_addon" />
        </FormRow>
        <FormRow label="Hidden">
          <template #tooltip>
            Hide this event, regardless of the event's status in the
            Ticketmaster API response.
          </template>
          <Checkbox v-model="form.hidden" />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../_extends/form.extends.js";

export default {
  extends: mixin,
  data() {
    return {
      venueEventTypes: [
        {
          value: "concert",
          title: "Concert",
        },
        {
          value: "theatrical_performance",
          title: "Theatrical Performance",
        },
        {
          value: "broadway",
          title: "Broadway",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.form__preview {
  max-width: 413px;
}
.form__preview,
.form__banner {
  &__img {
    background-size: cover;
    background-position: center;
  }
}
.form__date {
  display: flex;
  align-items: center;
  height: 100%;
}
.buy_tickets_link-field.text-field {
  width: 100%;
}
.form__other-events__list {
  font-weight: normal;
}
</style>
