<template>
  <v-navigation-drawer
    class="sidebar"
    floating
    width="290"
    clipped
    :value="value"
    app
    :permanent="$size == 'xl' || $size == 'lg'"
    @input="$emit('input', $event)"
  >
    <div class="logo">
      <img src="@/assets/logo-red.svg" alt="Logo" />
    </div>
    <v-list dense shaped>
      <ProjectSelector />

      <div v-for="(item, idx) in items" :key="idx">
        <v-list-group
          v-if="item.children"
          :group="item.to"
          class="sidebar-group"
        >
          <template v-slot:appendIcon>
            <v-icon class="sidebar-group__toggle-icon"> $chevron_down </v-icon>
          </template>
          <template v-slot:activator>
            <v-tooltip
              open-delay="500"
              :disabled="!item.tooltip"
              max-width="300"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" class="sidebar-item" v-on="on">
                  <v-list-item-icon>
                    <v-icon>${{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> {{ item.text }} </v-list-item-title>
                </v-list-item>
              </template>
              <span>{{ item.tooltip }}</span>
            </v-tooltip>
          </template>

          <div>
            <v-tooltip
              v-for="(subItem, sidx) in item.children"
              :key="sidx"
              open-delay="500"
              :disabled="!subItem.tooltip"
              max-width="300"
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  :to="subItem.to"
                  class="sidebar-subitem"
                  active-class="active"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-title>{{ subItem.text }}</v-list-item-title>
                </v-list-item>
              </template>
              <span>{{ subItem.tooltip }}</span>
            </v-tooltip>
          </div>
        </v-list-group>
        <v-tooltip
          v-else
          open-delay="500"
          :disabled="!item.tooltip"
          max-width="300"
          right
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              :to="item.to"
              class="sidebar-item"
              active-class="active"
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon>
                <v-icon>${{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item>
          </template>
          <span>{{ item.tooltip }}</span>
        </v-tooltip>
      </div>
    </v-list>
    <EnvSelector />
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "SideBar",
  props: {
    value: Boolean,
  },
  data() {
    return {
      activeGroup: 0,
    };
  },
  computed: {
    items() {
      const items = [
        ...[
          {
            icon: "eye",
            text: "Dashboard",
            to: "/",
            tooltip: `This is your home base. It displays an overview of events, UGC submissions, site views, and more. It also shows a preview of your main “timeline”`,
          },
          {
            icon: "cog",
            text: "Theme",
            to: "/themes",
            tooltip: `Themes allow you to change the appearance of your Commons Page(s). Colors, fonts, default images, and more can be customized here.`,
          },
          {
            icon: "file",
            text: "Pages",
            to: "/pages",
            tooltip: `Pages are the hubs for any type of content on Commons. It’s where you should start when building your site. Pages can house as much or as little as you’d like from a series of live events to a simple submission form.`,
          },
          ...(!this.currentUser.active_project.disable_online_events
            ? [
                {
                  icon: "calendar",
                  text: "Events",
                  to: "/events",
                  tooltip: `Events are the foundation of the guest experience. They can be live broadcasts, pre-recorded video, Zoom meetings, or static images. Every event has a “start time” and play on the “stage” so you can build out the way your attendees experience your content.`,
                },
              ]
            : []),
          ...(this.currentUser.active_project.available_addon_names.includes(
            "Addon::Ticketmaster"
          )
            ? [
                {
                  icon: "calendar",
                  text: "Venue Events",
                  to: "/venue-events",
                  tooltip: `Venue Events are live, in-person concerts, performances, musicals, etc.`,
                },
                {
                  icon: "map_marker",
                  text: "Venues",
                  to: "/ticketmaster-venues",
                  tooltip: `Venues are where Venue Events take place.`,
                },
              ]
            : []),
          {
            icon: "view_module",
            text: "Widgets",
            to: "/widgets",
            tooltip: `Widgets are all of the types of content that you can share that exist outside of the Commons’ “stage.” Click the down karet to explore.`,
            children: [
              {
                text: "Content Sections",
                to: "/widgets/content-sections",
                tooltip:
                  "Content Sections are rows of thumbnails (content blocks) that can be used to house pre-recorded video content, downloads, popups w/ imagery & copy, or external links. These can exist on any page but are not a part of the timeline.",
              },
              ...(this.currentUser.active_project.available_addon_names.includes(
                "Addon::Ticketmaster"
              )
                ? [
                    {
                      icon: "calendar",
                      text: "Venue Events Lists",
                      to: "/widgets/venue-events-lists",
                      tooltip: `Lists of Venue Events.`,
                    },
                  ]
                : []),
              {
                text: "Text Blocks",
                to: "/widgets/text-blocks",
                tooltip:
                  "Text Blocks are big, graphic buttons. Each has a headline and description, but can also be paired with a unique background image. These can be linked to other pages on your Commons site or externally.",
              },
              {
                text: "People List",
                to: "/widgets/lists",
                tooltip:
                  "Lists allow you to create and edit running catalogs based on the data input. Think people lists, sponsor lists, etc.",
              },
              {
                text: "Submission Forms",
                to: "/widgets/submission-forms",
                tooltip:
                  "Submission Forms are a tool you can use to request User Generated Content (UGC) and allow guests to submit photos, video, and/or messages. You can then curate, display, and unpublish the content as you see fit.",
              },
              {
                text: "Schedules",
                to: "/widgets/schedules",
                tooltip:
                  "Schedules are a high-level visualization of the events you want your attendees to know about. They can be tied to “events” or function as more general guides.",
              },
              {
                text: "Countdown Clocks",
                to: "/widgets/countdowns",
                tooltip:
                  "The Countdown Clock is a countdown clock :) Set a date & time and you can display the clock on any page on your site.",
              },

              {
                text: "Notifications",
                to: "/widgets/notifications",
                tooltip:
                  "Notifications allow you to create and edit system generated alert popups that share information or drive users to another page via a call to action.",
              },

              {
                text: "HTML Blocks",
                to: "/widgets/html-blocks",
                tooltip:
                  "HTML Blocks allow you to create custom HTML-based widgets for display on the site.",
              },
            ],
          },
          {
            icon: "account",
            text: "Users",
            to: "/users/participants",
            tooltip:
              "Users are all your guests and team members for use on the live site. You can create custom “roles” for guests, which can then be used to create unique experiences within Commons. This is also where all contact info for you guests and team will live.",
          },
        ],

        {
          icon: "message",
          text: "Chat Boxes",
          to: "/chats",
          tooltip:
            "Chat Boxes allow you to create spaces for guests to come together and communicate with simple text messages. Each chat box is fully moderated by your team. Create as many as you’d like and place them on specific pages within the site.",
        },
        ...(this.currentUser.active_project.available_addon_names.includes(
          "Addon::DirectChat"
        )
          ? [
              {
                icon: "direct_chat",
                text: "Direct Chat Boxes",
                to: "/direct-chat",
                tooltip: "Direct users messages",
              },
            ]
          : []),
        {
          icon: "blog",
          text: "Blog",
          to: "/blog",
          tooltip: "Here you can create Blog Articles",
        },
        {
          icon: "content",
          text: "Content Management",
          to: "/content-management",
          tooltip: "Here you can manage content",
        },
        {
          icon: "help_circle",
          text: "Site Support",
          to: "/support",
          tooltip:
            "Here you can create Site Support forms for guests to use if they have any questions or need to get in touch with a Commons admin.",
        },
        {
          icon: "variables",
          text: "Easy Updates",
          to: "/variables",
          tooltip: "Easy Updates are used to replace text and images",
        },
        {
          icon: "addons",
          text: "Tools & Authentication",
          to: "/addons",
          tooltip:
            "Here you can enable site authentication using a CAS or social media platform for Page or Chat Box access. Also, you'll have access to other tools including Google Analytics.",
        },
        {
          icon: "math_log",
          text: "Admin Logs",
          to: "/logs",
          tooltip:
            "With Admin Logs you can watch on admin's interactions in current project.",
        },
      ];

      if (
        this.currentUser.active_project.available_addon_names.includes(
          "Addon::RegistrationForms"
        )
      ) {
        items
          .find((item) => item.text == "Widgets")
          .children.push({
            text: "Registration Forms",
            to: "/widgets/registration-forms",
            tooltip:
              "Registration Forms are custom forms for user sign ups, where you can add custom input fields beyond email and password.",
          });
      }

      if (
        this.currentUser.active_project.available_addon_names.includes(
          "Addon::DeveloperTools"
        )
      ) {
        items.push({
          icon: "dev_to",
          text: "Developer Tools",
          to: "/dev-tools",
          children: [
            {
              text: "Custom Components",
              to: "/dev-tools/custom-components",
            },
            {
              text: "Presentations",
              to: "/dev-tools/presentations",
            },
            {
              text: "Image Library",
              to: "/dev-tools/image-library",
              tooltip: `The Image Library is where you can upload images that can be referenced when writing code for custom components, CSS styles, and HTML blocks.`,
            },
          ],
        });
      }

      return items;
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  border-right: 2px solid #dcdce0;

  &::v-deep .v-navigation-drawer__content {
    padding: 20px 17px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &-subitem {
    padding-left: 88px;
  }

  &-item {
    padding-left: 45px;

    &::v-deep .v-list-item__title {
      color: var(--v-text-base) !important;
      font-weight: bold !important;
    }

    &::v-deep .v-list-item__icon {
      margin-right: 18px;
    }
  }

  &-item,
  &-subitem {
    &.active {
      background: var(--v-primary-base);

      &,
      &::v-deep .v-list-item__title {
        color: white !important;
      }

      &:before {
        opacity: 0;
      }
    }
  }
  &-group {
    &::v-deep .v-list-group__header {
      padding: 0 !important;
    }

    &__toggle-icon {
      position: relative;
      left: -8px;
    }
  }
}

.logo {
  display: none;
}

@media screen and (max-width: 1263px) {
  .logo {
    display: block;
    padding-left: 55px;
    margin-bottom: 20px;
  }

  .sidebar {
    &::v-deep .v-navigation-drawer__content {
      padding-top: 20px;
    }
  }
}
</style>
