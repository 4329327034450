<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr class="data-table__row">
            <td />
            <td>
              <router-link :to="`/ticketmaster-venues/${item.id}/edit`">
                {{ item.name }}
              </router-link>
            </td>

            <td class="text-center nowrap w0">
              <Actions
                :path="`/ticketmaster-venues/${item.id}`"
                hideDuplicate
                hideDestroy
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Title',
          align: 'start',
          value: 'name',
        },
        {
          align: 'center',
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
</style>
