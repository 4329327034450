export default {
  props: {
    value: Object,
    btnText: String,
    submit: Function,
  },
  data() {
    return {
      form: { ...this.value },
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.form = { ...this.value };
      },
    },
    form: {
      deep: true,
      handler() {
        if (JSON.stringify(this.form) != JSON.stringify(this.value)) {
          this.$emit("input", this.form);
        }
      },
    },
  },
};
