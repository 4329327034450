<template>
  <div>
    <slot name="label">
      <div class="code-editor-label" v-if="label">{{ label }}</div>
    </slot>
    <div class="code-editor">
      <codemirror
        :value="value"
        @input="$emit('input', $event)"
        :options="cmOptions"
      />
    </div>
  </div>
</template>

<script>
import "codemirror/lib/codemirror.css";
import { codemirror } from "vue-codemirror";
// import "codemirror/theme/base16-dark.css";
import "codemirror/mode/css/css.js";
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/vue/vue.js";
import "codemirror/addon/display/placeholder.js";

export default {
  props: {
    value: String,
    label: String,
    placeholder: String,
    lang: {
      type: String,
      default: "text/css",
    },
  },
  components: {
    codemirror,
  },
  data() {
    return {
      cmOptions: {
        tabSize: 2,
        mode: this.lang,
        // theme: "base16-dark",
        lineWrapping: true,
        lineNumbers: true,
        height: "auto",
        viewportMargin: Infinity,
        line: true,
        placeholder: this.placeholder,
        // more CodeMirror options...
      },
    };
  },
};
</script>

<style scoped lang="scss">
.code-editor {
  border: 1px solid #ddd;
  margin: 5px 0;

  &-label {
    margin-top: 5px;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: -5px;
  }

  &::v-deep {
    .CodeMirror {
      height: auto;
    }
    .CodeMirror-placeholder {
      color: #b5b5b5;
    }
  }
}
</style>
