<template>
  <v-dialog
    @keydown.enter="confirm"
    @click:outside="cancel"
    max-width="800"
    v-model="dialog"
  >
    <v-card>
      <v-btn class="dialog-close" icon @click="cancel">
        <v-icon>$close</v-icon>
      </v-btn>
      <v-card-title class="dialog-title">
        Duplicate this project?
      </v-card-title>

      <v-card-text>
        <p class="form-subhead">This will create a new project based on <b>{{ source_title }}</b>, including duplicates of all pages, content and widgets. Please set the basic settings for your new site.</p>
        <FormRow
          tooltip="This is the name your site will be referenced as on the Admin portal."
          required
          label="Site Name"
        >
          <TextField
            v-model="form.title"
            placeholder="Site Name"
            :rules="[validation.required]"
          />
        </FormRow>
        <FormRow
          :required="form.restrict_production_access"
          label="Live Site URL"
          tooltip="This will be the public URL for your Commons site."
        >
          <v-layout align-center>
            <span class="mr-3 form-detail">https://</span>
            <div>
              <TextField
                v-model="form.production_domain"
                placeholder="example.com"
                :rules="
                  form.restrict_production_access
                    ? [
                        validation.required,
                        validation.project.productionDomain,
                      ]
                    : [validation.project.productionDomain]
                "
              />
            </div>
          </v-layout>
        </FormRow>
        <div v-if="form.production_domain">
          <FormRow label="Redirect to another Website">
            <RadioGroup
              v-model="form.production_redirect_enabled"
              :items="[
                { title: 'No', value: false },
                { title: 'Yes', value: true },
              ]"
            />
          </FormRow>
          <FormRow
            v-if="form.production_redirect_enabled"
            required
            label="Redirect URL"
          >
            <TextField
              v-model="form.production_redirect_url"
              placeholder="Redirect URL"
              :rules="[validation.required, validation.url]"
            />
          </FormRow>
        </div>
        <FormRow label="Password Protect Live Site">
          <template #tooltip>
            Select "Yes" if you want to have password access to the URL above.
            Note, this is not user authentication, purely domain access.
          </template>
          <RadioGroup
            v-model="form.restrict_production_access"
            :items="[
              { title: 'No', value: false },
              { title: 'Yes', value: true },
            ]"
          />
        </FormRow>
        <div v-if="form.restrict_production_access">
          <FormRow
            required
            label="Live Site Access Username"
            tooltip="The username to access the Live Site URL"
          >
            <TextField
              v-model="form.production_login"
              placeholder="Live Site Access Username"
              :rules="[validation.required]"
            />
          </FormRow>
          <FormRow
            required
            label="Live Site Access Password"
            tooltip="The password to access the Live Site URL"
          >
            <TextField
              v-model="form.production_password"
              placeholder="Live Site Access Password"
              :rules="[validation.required]"
              type="password"
            />
          </FormRow>
        </div>
        <FormRow
          required
          label="Staging Site URL Prefix"
          tooltip="This will be the staging url for your Commons site. If the staging domain isn't working, please contact the iU web dev team to set it up."
        >
          <v-layout align-center>
            <span class="mr-3 form-detail">https://</span>
            <div>
              <TextField
                v-model="form.staging_domain"
                placeholder="example"
                :rules="[
                  validation.required,
                  validation.project.stagingDomain,
                ]"
              />
            </div>
            <span class="mr-3 form-detail">.{{ rootDomain }}</span>
          </v-layout>
        </FormRow>
        <FormRow label="Password Protect Staging Site">
          <template #tooltip>
            Select "Yes" if you want to have password access to the URL above.
            Note, this is not user authentication, purely domain access.
          </template>
          <RadioGroup
            v-model="form.restrict_staging_access"
            :items="[
              { title: 'No', value: false },
              { title: 'Yes', value: true },
            ]"
          />
        </FormRow>
        <div v-if="form.restrict_staging_access">
          <FormRow required label="Staging Site Access Username">
            <template #tooltip>
              The username to access the Staging Site URL
            </template>
            <TextField
              v-model="form.staging_login"
              placeholder="Staging Site Access Username"
              :rules="[validation.required]"
            />
          </FormRow>
          <FormRow required label="Staging Site Access Password">
            <template #tooltip>
              The password to access the Staging Site URL
            </template>
            <TextField
              v-model="form.staging_password"
              placeholder="Staging Site Access Password"
              :rules="[validation.required]"
            />
          </FormRow>
        </div>
        <slot></slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn style="margin-right: 15px" text @click="cancel">
          Cancel
        </v-btn>

        <v-btn color="primary" @click="confirm(true)">
          Duplicate Project
        </v-btn>
      </v-card-actions>
      <Spinner v-show="working" :relative="false">
        <v-card class="spinner-card">
          Working... This might take a while.
        </v-card>
      </Spinner>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ProjectDuplicateDialog",
  data() {
    return {
      data: {},
      form: {},
      source_title: null,
      dialog: null,
      rootDomain: process.env.VUE_APP_ROOT_DOMAIN,
      working: false,
    };
  },
  mounted() {
    this.$root.$on("ProjectDuplicateDialog", async (data) => {
      this.dialog = true;
      this.data = data;
      this.form = data.project;
      this.source_title = data.project.title;
    });
  },
  methods: {
    async confirm() {
      // send request to api
      this.working = true;
      const response = await this.$api.Project.duplicate(this.form);
      this.working = false;
      if(response.status == 200) {
        this.data.callback({
          confirmed: true,
          project_id: response.data.project,
        });
        this.dialog = false;
        this.data = {};
        this.form = {};
      }
    },
    cancel() {
      this.data.callback({
        confirmed: false
      });
      this.dialog = false;
      this.data = {};
      this.form = {};
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.dialog-title {
  padding-top: 25px !important;
}

.spinner {
  background: rgba(33, 33, 33, .25);
  &-card {
    padding: 1em;
    margin-bottom: .5em;
  }
}
</style>
