<template>
  <v-dialog v-model="open" :width="width" @click:outside="$emit('cancel')">
    <v-card>
      <Cropper
        ref="cropper"
        class="cropper"
        :src="filePath"
        :auto-zoom="true"
        :stencil-props="{
          aspectRatio: ratio,
        }"
        :class="cropperClass"
        image-restriction="stencil"
      />

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          large
          color="#262729"
          dark
          class="elevation-0"
          @click="$emit('cancel')"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          large
          color="#262729"
          dark
          class="elevation-0"
          @click="useUncropped"
        >
          Use Uncropped
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" large class="elevation-0" @click="onConfirm">
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
export default {
  name: "ImageCropper",
  components: {
    Cropper,
  },
  props: {
    srcFile: [File, Object],
    open: {
      type: Boolean,
      required: false,
    },
    width: {
      type: Number,
      default: 500,
    },
    ratio: {
      type: Number,
    },
    stencilSize: Object,
    cropperClass: String,
  },
  methods: {
    useUncropped() {
      this.$emit("confirm", this.srcFile);
    },
    onConfirm() {
      const { canvas } = this.$refs.cropper.getResult();
      const name =
        this.srcFile.name || this.srcFile.url.split("/").slice(-1)[0];
      canvas.toBlob((blob) => {
        this.$emit(
          "confirm",
          new File([blob], name, {
            type: "image/jpeg",
            lastModified: new Date().getTime(),
          })
        );
      }, "image/jpeg");
    },
  },
  computed: {
    filePath() {
      if (this.srcFile) {
        if (this.srcFile instanceof File) {
          return URL.createObjectURL(this.srcFile);
        } else {
          return this.srcFile.url;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.cropper {
  background: #ddd;
  height: 460px;
  max-height: calc(90vh - 60px);
}
</style>
