<template>
  <div>
    <div v-if="currentUser.active_team_role == 'super_admin'">
      <div class="page-title">New Project</div>

      <Form v-if="form" v-model="form" :submit="create" btnText="Add Project" />
      <Spinner v-else />
    </div>
    <div v-else>
      <div class="page-title">Projects</div>
      <div>
        You don't have active projects. Please, contact your admin to get more
        details.
      </div>
    </div>
  </div>
</template>

<script>
import Form from "./_form.projects.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async setForm() {
      const { data } = await this.$api.Project.new();
      this.form = data;
    },
    async create() {
      const { data } = await this.$api.Project.create(this.form);
      await this.$api.Project.makeActive({ id: data.id });
      this.$router.push("/");
    },
  },
};
</script>

<style></style>
