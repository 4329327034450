<template>
  <div>
    <div class="page-title">JustGiving</div>
    <TextField
      label="App ID"
      v-model="localValue.data.app_id"
    />
    <p><b>URL:&nbsp;</b><a>https://{{url}}</a></p>
    <p><b>Params:&nbsp;</b>short_names[]</p>
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
  computed: {
    url () {
      const rootUrl = this.currentUser.active_project.domains[this.currentUser.active_project_env]
      return `${rootUrl}/api/v1/public_side/just_giving`
    }
  }
};
</script>

<style></style>
