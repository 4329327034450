<template>
  <div>
    <div class="page-title">TikTok Pixel Settings</div>
    <TextField
      label="Production Domain to Track"
      v-model="currentUser.production_domain"
      disabled
    />

    <TextField
      placeholder="Pixel ID"
      label="Pixel ID"
      v-model="localValue.data.pixel_id"
    />
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
};
</script>

<style scoped lang="scss"></style>
