<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="List Info">
        <FormRow
          label="Name/Title"
          required
          tooltip="The name of the person, group, or entity you'd like to acknowledge."
        >
          <TextField
            :rules="[validation.required]"
            placeholder="Title"
            v-model="form.title"
          ></TextField>
        </FormRow>
        <FormRow
          label="Subtitle"
          tooltip="Use this field to add a job title, role, or another supplementary label to the entity above."
        >
          <TextArea placeholder="Subtitle" v-model="form.subtitle"></TextArea>
        </FormRow>
        <FormRow
          tooltip="Here you can add an image to be used as a thumbnail that will be displayed on the front-end of your site."
        >
          <template #label>
            Image<br />
            (000x000)
          </template>
          <Uploader :ratio="320 / 180" v-model="form.preview" enableCropper />
        </FormRow>
        <FormRow
          label="Url"
          tooltip="If this item should link somewhere, add that here."
        >
          <TextField
            placeholder="Url"
            v-model="form.url"
          ></TextField>
        </FormRow>
        <FormRow
          label="Display List(s)"
          tooltip="Select the list or lists on which you'd like this entry to appear."
        >
          <ListsSelector multiple v-model="form.list_ids" />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../../_extends/form.extends.js";

export default {
  extends: mixin,
  data() {
    return {
      lists: [],
      sortByItems: [
        { title: "A-Z", value: "az" },
        { title: "Z-A", value: "za" },
        { title: "Ascending ID#", value: "id_asc" },
        { title: "Descending ID#", value: "id_desc" },
        { title: "Custom Position", value: "custom" },
      ],
    };
  },
};
</script>

<style></style>
