<template>
  <div class="pages">
    <Banner theme="green">
      <template v-slot:title> Pages </template>
      <template v-slot:description>
        Pages are the hubs for any type of content on Commons. It’s where you
        should start when building your site. Pages can house as much or as
        little as you’d like from a series of live events to a simple submission
        form.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/pages.svg" alt="Pages" />
      </template>
    </Banner>

    <v-tabs class="pages__tabs" v-model="tab">
      <v-tab>All Pages</v-tab>
      <v-tab>Menu Pages</v-tab>
    </v-tabs>

    <div v-if="tab == 0">
      <IndexPageActions
        :sort-list="sortList"
        btn-text="New Page"
        btn-to="/pages/new"
        :sort-by.sync="sortBy"
        search-placeholder="Search Pages"
        :search-text.sync="searchText"
      />
      <ListPanels :loaded="!!list" ref="pageList">
        <div class="pages-group" v-if="computedList && computedList.length">
          <div v-for="page in computedList" :key="page.id">
            <ListPanel
              v-if="!page.is_virtual_page"
              :hide-destroy="page.url == '/'"
              :icon="page.icon"
              :action-path="`/pages/${page.id}`"
              @destroy="destroy(page)"
              @duplicate="duplicate(page)"
            >
              <template #title>
                {{ page.title }}
              </template>
              <template #label>
                {{ pageLabel(page) }}
              </template>

              <div v-if="normalizeComponents(page.components).length">
                <component
                  :is="'Page' + component.type"
                  v-for="(component, idx) in normalizeComponents(
                    page.components
                  )"
                  :key="component.id"
                  :item="component"
                  :widgets="page.widgets"
                  @up="up(idx, page)"
                  @down="down(idx, page)"
                />
              </div>

              <div v-else class="no-entries">No Content</div>
            </ListPanel>
          </div>
          <div
            class="pages-group"
            v-if="computedVirtualPages && computedVirtualPages.length"
          >
            <div class="pages-group__title">VIRTUAL PAGES</div>

            <ListPanel
              v-for="page in computedVirtualPages"
              :key="page.id"
              hide-destroy
              hide-duplicate
              hide-expand
              :action-path="`/content-management/${page.data_loader_entity.schema_int_id}/entities/${page.data_loader_entity.int_id}`"
            >
              <template #title>
                {{ page.title }}
              </template>
              <template #label>
                {{ pageLabel(page) }}
              </template>
            </ListPanel>
          </div>
        </div>
        <div v-else class="no-entries">No Pages</div>
      </ListPanels>
    </div>
    <div v-if="tab == 1">
      <IndexPageActions
        btn-text="New Page"
        btn-to="/pages/new"
        search-placeholder="Search Pages"
        :search-text.sync="searchText"
      />
      <ListPanels :loaded="!!list" ref="pageList">
        <div
          class="pages-group"
          v-if="computedMenuPages && computedMenuPages.length"
        >
          <div class="pages-group__title">MENU PAGES</div>
          <div v-for="page in computedMenuPages" :key="page.id">
            <MenuPageListPanel
              :page="page"
              @refresh="refresh"
              @destroy="destroy($event)"
              @duplicate="duplicate($event)"
            />
          </div>
        </div>
        <div v-else class="no-entries">No Pages</div>
      </ListPanels>
    </div>
    <PageDuplicateDialog />
  </div>
</template>

<script>
import _ from "lodash";
import IndexPage from "./index-page";
import PageDuplicateDialog from "@/components/PageDuplicateDialog.vue";
import MenuPageListPanel from "./pages/MenuPageListPanel.vue";
import pageindexMixin from "./pages/page-index.mixin";

export default {
  name: "PagesPage",
  extends: IndexPage,
  mixins: [pageindexMixin],
  components: { PageDuplicateDialog, MenuPageListPanel },
  data() {
    return {
      panel: [],
      tab: 0,
    };
  },
  methods: {
    up(idx, page) {
      if (idx < 1) return;
      const element = { ...page.components[idx] };
      page.components.splice(idx, 1);
      page.components.splice(idx - 1, 0, element);
      this.save(page);
    },
    down(idx, page) {
      if (idx >= page.components.length - 1) return;
      const element = { ...page.components[idx] };
      page.components.splice(idx, 1);
      page.components.splice(idx + 1, 0, element);
      this.save(page);
    },
    async destroy(page) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Page.destroy({ id: page.id });
      this.refresh();
    },
    async duplicate(page) {
      const confirmResponse = await new Promise((resolve) => {
        this.$root.$emit("PageDuplicateDialog", {
          callback: (value) => {
            resolve(value);
          },
        });
      });
      if (!confirmResponse.confirmed) return;
      await this.$api.Page.duplicate({
        id: page.id,
        components: page.components,
        duplicate_content: confirmResponse.duplicateContent,
      });
      this.refresh();
    },
    async fetchList() {
      const { data } = await this.$api.Page.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async refresh() {
      await this.fetchList();
      const y =
        this.$refs.pageList.$el.getBoundingClientRect().top +
        window.scrollY -
        100;
      if (window.scrollY > y) {
        window.scroll({
          top: y,
          behavior: "smooth",
        });
      }
    },
    addMenuChildren(pages) {
      pages.forEach((page) => {
        const menuChildren = _.sortBy(
          _.sortBy(
            this.computedList.filter(
              (p) => p.add_to_menu == "menu_bar" && p.parent_menu_id == page.id
            ),
            (p) => p.created_at
          ),
          (p) => p.menu_position
        );
        if (menuChildren.length) {
          this.addMenuChildren(menuChildren);
          page.menuChildren = menuChildren;
        }
      });
    },
  },
  computed: {
    computedMenuPages() {
      const result = _.sortBy(
        _.sortBy(
          this.computedList.filter(
            (p) => p.add_to_menu == "menu_bar" && !p.parent_menu_id
          ),
          (p) => p.created_at
        ),
        (p) => p.menu_position
      );
      this.addMenuChildren(result);
      return result;
    },
    computedVirtualPages() {
      return this.computedList.filter((x) => x.is_virtual_page);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./pages/pages-index.scss";

.pages {
  .index-page-actions {
    margin-top: 2em;
  }
  &__tabs {
    margin-top: 1em;
    &::v-deep {
      .v-item-group {
        background: transparent !important;
      }
      .v-tab {
        background: white;
        border-radius: 6px 6px 0 0;
        margin-right: 0.5em;
      }
    }
  }
}
</style>
