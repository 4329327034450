<template>
  <div class="context-search" :style="{ minWidth }">
    <v-text-field
      solo
      rounded
      hide-details
      :label="placeholder"
      flat
      @input="$emit('input', $event)"
      :value="value"
      clearable
    >
      <template v-slot:prepend-inner>
        <v-icon size="20">$search</v-icon>
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    placeholder: String,
    minWidth: {
      type: String,
      default: "200px",
    },
  },
};
</script>

<style scoped lang="scss">
.context-search {
  &::v-deep {
    .v-input {
      position: relative;
    }
    .v-input:before {
      content: "";
      position: absolute;
      left: -1px;
      right: -1px;
      top: -1px;
      bottom: -1px;
      border: 1px solid transparent;
      border-radius: 28px;
      transition: 0.3s;
    }

    .v-input--is-focused {
      &.v-input:before {
        border: 1px solid var(--v-primary-base);
      }
      .v-icon__svg {
        fill: var(--v-primary-base);
      }
    }

    .v-input__slot {
      padding: 0 34px !important;
    }

    .v-input__prepend-inner {
      padding-right: 16px !important;
    }

    .v-label {
      color: var(--v-text-base);
      opacity: 0.5;
      font-size: 14px;
      max-width: 95%;
    }
  }
}

@media screen and (max-width: 980px) {
  .context-search {
    min-width: 0 !important;
  }
}
</style>
