<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Presentations </template>
      <template v-slot:description />
      <template v-slot:image>
        <img
          src="@/assets/banners/widgets/presentations.svg"
          alt="Presentations"
        />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Presentation"
      btn-to="/dev-tools/presentations/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Presentation"
      :search-text.sync="searchText"
    />
    <ListPanels :loaded="!!list">
      <div v-if="computedList && computedList.length">
        <ListPanel
          v-for="section in computedList"
          :key="section.id"
          :action-path="`/dev-tools/presentations/${section.id}`"
          :title="section.title"
          :label="section.label"
          @destroy="destroySection(section)"
          @duplicate="duplicate(section)"
        >
          <template #addAction>
            <v-btn
              :to="`/dev-tools/presentations/${section.id}`"
              color="primary"
              elevation="0"
              rounded
              fab
              x-small
            >
              <slot name="btn">
                <v-icon size="20"> $play </v-icon>
              </slot>
            </v-btn>
          </template>
          <div v-if="section.slides.length">
            <PresentationsDatagrid
              :list="section.slides"
              :entity="section"
              @destroy="destroyBlock($event, section)"
            />
          </div>
          <div v-else class="no-entries">No Slides</div>
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Presentations</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from "../index-page";
import PresentationsDatagrid from "@/components/dataview/presentations.datagrid";

export default {
  name: "WidgetsPresentationsPage",
  components: { PresentationsDatagrid },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.Presentation.get();
      this.list = data;
    },
    async destroySection(section) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Presentation.destroy(section);
      this.list = this.list.filter((t) => t.id != section.id);
    },
    async duplicate(presentation) {
      await this.$api.Presentation.duplicate({ id: presentation.id });
      this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss"></style>
