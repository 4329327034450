<template>
  <div class="submissions-table">
    <div class="bulk" :class="{ 'bulk-hidden': !selected.length }">
      <v-btn style="margin-right: 10px" color="primary" @click="publish"
        >Bulk Publish</v-btn
      >
      <v-btn color="primary" @click="unpublish" outlined>Bulk Unpublish</v-btn>
    </div>
    <div v-if="list">
      <div v-if="list.length">
        <v-data-table
          :headers="headers"
          :items="list"
          item-key="id"
          show-expand
          class="elevation-0 data-table"
          disable-pagination
          :items-per-page="-1"
          :hide-default-footer="true"
          show-select
          v-model="selected"
        >
          <template v-slot:item="{ item, isSelected, select }">
            <tr class="data-table__row">
              <td @click.stop>
                <v-checkbox
                  @change="select($event)"
                  :value="isSelected"
                ></v-checkbox>
              </td>
              <td v-for="(td, idx) in headers" :key="idx">
                <div
                  v-if="entity.manual_order && td.type == 'order'"
                  class="submissions-table__order-actions"
                >
                  <v-btn icon @click.stop="moveUp(item.id)">
                    <v-icon>$arrow_up</v-icon>
                  </v-btn>
                  <v-btn icon @click.stop="moveDown(item.id)">
                    <v-icon>$arrow_down</v-icon>
                  </v-btn>
                </div>
                <div v-if="td.type == 'text'">{{ item[td.value] }}</div>
                <div v-else-if="td.type == 'created_at'">
                  {{
                    item.created_at
                      | moment(
                        "timezone",
                        currentUser.active_project.timezone,
                        "MM/DD/YY h:mm A zz"
                      )
                  }}
                </div>
                <div v-else-if="td.type == 'status'">
                  <div class="status status--published" v-if="item.published">
                    Published
                  </div>
                  <div class="status status--not-published" v-else>
                    Not published
                  </div>
                </div>
                <div v-else-if="td.type == 'Select'">
                  {{ getSelectLabel(item, td) }}
                </div>
                <div v-else-if="td.generic">
                  <div v-if="item.indexed_formatted_data[td.uuid]">
                    <div
                      v-if="
                        td.type == 'MediaField' &&
                        item.indexed_formatted_data[td.uuid].value
                      "
                    >
                      <a
                        target="_blank"
                        :href="item.indexed_formatted_data[td.uuid].value.url"
                      >
                        <span>{{
                          getName(
                            item.indexed_formatted_data[td.uuid].value.url
                          )
                        }}</span>
                      </a>
                    </div>
                    <div v-else>
                      <span
                        v-if="
                          item.indexed_formatted_data[td.uuid] &&
                          item.indexed_formatted_data[td.uuid].value
                        "
                      >
                        {{ item.indexed_formatted_data[td.uuid].value }}
                      </span>
                      <span v-else>N/A</span>
                    </div>
                  </div>
                  <span v-else>N/A</span>
                </div>
                <div v-else-if="td.type == 'actions'">
                  <Actions
                    hide-duplicate
                    :path="`submission-forms/${entity.id}/submissions/${item.id}`"
                    @destroy="destroy(item)"
                  >
                  </Actions>
                </div>
              </td>
            </tr>
          </template>
          <!-- <template v-slot:body="{ items }">
            <tbody v-for="item in items" :key="item.id"></tbody>
          </template> -->
        </v-data-table>
      </div>
      <div v-else class="no-entries">No Submissions</div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    entity: Object,
  },
  data() {
    return {
      list: null,
      selected: [],
    };
  },
  created() {
    this.setList();
  },
  methods: {
    getSelectLabel(item, td) {
      try {
        return this.entity.submission_form
          .find((t) => t.uuid == td.uuid)
          .options.find(
            (t) => t.uuid == item.indexed_formatted_data[td.uuid].value
          ).title;
      } catch {
        return item.indexed_formatted_data[td.uuid] &&
          item.indexed_formatted_data[td.uuid].value
          ? item.indexed_formatted_data[td.uuid].value
          : "N/A";
      }
    },
    getName(str) {
      const t = (str || "").split("/");
      return t[t.length - 1];
    },
    async publish() {
      await this.$api.Submission.bulkPublish({
        submission_ids: this.selectedIds,
      });
      this.setList();
    },
    async unpublish() {
      await this.$api.Submission.bulkUnpublish({
        submission_ids: this.selectedIds,
      });
      this.setList();
    },
    async destroy(submission) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Submission.destroy({ id: submission.id });

      this.list = this.list.filter((t) => t.id != submission.id);
    },
    async setList() {
      const { data } = await this.$api.Submission.lite({
        submission_form_id: this.entity.id,
      });
      this.list = data.map((t) => ({
        ...t,
        indexed_formatted_data: _.keyBy(t.formatted_data, "uuid"),
      }));
    },
    async moveUp(id) {
      const res = await this.$api.Submission.moveUp({
        id,
      });
      if (res.data.msg) {
        this.$allertWindow(res.data.msg, "error");
      }
      this.setList();
    },
    async moveDown(id) {
      const res = await this.$api.Submission.moveDown({
        id,
      });
      if (res.data.msg) {
        this.$allertWindow(res.data.msg, "error");
      }
      this.setList();
    },
  },
  computed: {
    selectedIds() {
      return this.selected.map((t) => t.id);
    },
    headers() {
      let headers = (this.list || []).map((t) => {
        return t.formatted_data.map((t) => {
          const val = t.short_name || t.label || "";
          const text = val.substr(0, 15);
          return {
            text: text.length < val.length ? `${text}...` : text,
            align: "start",
            uuid: t.uuid,
            type: t.type,
            generic: true,
            sortable: !this.entity.manual_order,
          };
        });
      });

      headers = _.sortBy(_.uniqBy(_.flatten(headers), "uuid"), "short_name");

      const allHeaders = [
        {
          text: "Submitted date",
          align: "left",
          value: "created_at",
          type: "created_at",
          sortable: !this.entity.manual_order,
        },
        {
          text: "Status",
          align: "left",
          value: "published",
          type: "status",
          sortable: !this.entity.manual_order,
        },
        {
          text: "User",
          align: "left",
          value: "user_name",
          type: "text",
        },
        {
          text: "Email",
          align: "left",
          value: "email",
          type: "text",
        },
        ...headers,
        {
          text: "Actions",
          align: "center",
          type: "actions",
          sortable: false,
        },
      ];

      if (this.entity.manual_order) {
        allHeaders.unshift({
          text: "Order",
          align: "left",
          value: "position",
          type: "order",
        });
      }

      return allHeaders.filter(
        (h) => !["MediaField", "DateTime"].includes(h.type)
      );
    },
  },
};
</script>

<style scoped lang="scss">
.submissions-table {
  &::v-deep th:first-child {
    display: none !important;
  }
  &__order-actions {
    display: flex;
  }
}

.bulk-hidden {
  pointer-events: none;
  opacity: 0;
}

.bulk {
  margin-top: -30px;
}

.status {
  padding: 3px 10px;
  border-radius: 3px;
  white-space: nowrap;
}
.status--published {
  background: #099b09c4;
  color: white;
}
.status--not-published {
  background: gray;
  color: white;
}
</style>
