<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Content Section Info">
        <FormRow
          required
          label="Title"
          tooltip="This is the title of the Content Section as it will be displayed on the front-end of your site."
        >
          <TextField v-model="form.title" placeholder="Title" />
        </FormRow>

        <FormRow
          label="URL to More Content"
          tooltip="If you want to link to another page on your site or another site, add the URL here."
        >
          <TextField
            v-model="form.more_link_url"
            placeholder="URL to More Content"
            :rules="[validation.url]"
          />
        </FormRow>
        <FormRow
          label="URL Display Text"
          tooltip="This is the display name for the URL above as it will appear on your site."
        >
          <TextField
            v-model="form.more_link_text"
            placeholder="URL Display Text"
          />
        </FormRow>
      </FormPanel>
      <FormPanel label="Content Section Timing">
        <FormRow
          label="Content Section Start Time"
          tooltip="The time at which this Content Section will go live on your site."
        >
          <DateTimePicker
            v-model="form.start_at"
            placeholder="Select Date/Time"
          />
        </FormRow>

        <FormRow
          label="Content Section End Time"
          tooltip="The time at which this Content Section will disappear from your site."
        >
          <DateTimePicker
            v-model="form.end_at"
            placeholder="Select Date/Time"
          />
        </FormRow>
      </FormPanel>
      <FormPanel label="Content Section Details">
        <FormRow
          label="Mobile Style"
          tooltip="By default, Commons content sections have a touch-friendly layout with no arrows by default. Change this if you would prefer to have arrows."
        >
          <Select
            placeholder="Touch-friendly"
            :items="mobileStyles"
            v-model="form.mobile_style"
            item-text="title"
            item-value="value"
          />
        </FormRow>
        <FormRow
          label="Display Page(s)"
          tooltip="Select the page or pages on which you'd like this Content Section to appear."
        >
          <PagesSelector v-model="form.page_ids" multiple />
        </FormRow>

        <FormRow
          label="Max Items Shown at Once (default 4)"
          tooltip="The number of items shown at once, or in one row, on a large desktop screen."
        >
          <Select
            placeholder="(default 4)"
            :items="maxItemsShownOptions"
            v-model="form.max_items_shown"
            item-text="title"
            item-value="value"
          />
        </FormRow>

        <FormRow
          label="Disable Horizontal Scroll"
          tooltip="If you want to show cascading rows of all the content in this section, check this box."
        >
          <Checkbox v-model="form.disable_scroll" />
        </FormRow>

        <FormRow
          label="Disable View More on Mobile"
          tooltip="If you want to show all items on mobile, without the “view more” caret, enable this."
          v-if="form.disable_scroll"
        >
          <Checkbox v-model="form.disable_view_more" />
        </FormRow>

        <FormRow
          label="Include Description with Preview Thumbnail"
          tooltip="If you want to show the description below the preview thumbnail, check this box."
        >
          <Checkbox v-model="form.description_with_thumbnails" />
        </FormRow>

        <FormRow
          label="Infinite Loop"
        >
          <Checkbox v-model="form.loop" />
        </FormRow>

        <FormRow
          label="Keyboard Navigation Between Content Blocks"
          tooltip="To move between content blocks in this section using the left and right arrow keys."
        >
          <Checkbox v-model="form.keyboard_nav" />
        </FormRow>
        <FormRow
          label="Sort By"
          required
          tooltip="This is the order in which your content blocks will be sorted."
        >
          <RadioGroup
            v-model="form.sort_by"
            :rules="[validation.required]"
            :items="sortByItems"
          />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ btnText }}
    </SubmitFooter>
  </v-form>
</template>

<script>
import mixin from '../../_extends/form.extends.js';
export default {
  extends: mixin,
  data() {
    return {
      pages: [],
      sortByItems: [
        { title: 'Created at, Ascending', value: 'created_at_asc' },
        { title: 'Created at, Descending', value: 'created_at_desc' },
        { title: 'Start at, Ascending', value: 'start_at_asc' },
        { title: 'Start at, Descending', value: 'start_at_desc' },
        { title: 'Custom Position', value: 'position' },
      ],
      maxItemsShownOptions: [
        { title: 4, value: 'four' },
        { title: 3, value: 'three' },
        { title: 2, value: 'two' },
        { title: 1, value: 'one' },
      ],
      mobileStyles: [
        { title: "Touch-Friendly", value: "touch" },
        { title: "Arrows", value: "arrows" }
      ],
    };
  },
};
</script>

<style></style>
