<template>
  <div class="has-many">
    <div class="has-many-title">
      <h2 class="has-many-title__title">{{ settings.title }}</h2>
      <v-tooltip
        open-delay="500"
        v-if="settings.helper_text"
        max-width="300"
        top
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" size="20" class="form-question"
            >$question_round</v-icon
          >
        </template>
        <span>
          <slot name="tooltip">
            {{ settings.helper_text }}
          </slot>
        </span>
      </v-tooltip>
    </div>
    <SchemaBuilder :schemaId="settings.schema_id" v-model="field" />
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    settings: Object,
  },
  data() {
    return {
      field: { ...(this.value || {}) },
    };
  },
  watch: {
    field: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit("input", { ...this.field });
      },
    },
  },
};
</script>

<style scoped lang="scss">
.has-many-field {
  padding: 10px;
  border-bottom: 1px dashed #969696;
  margin-bottom: 15px;
}

.has-many {
  background: #f7f7f7;
  padding: 15px;
  margin: 25px 0;
}

.has-many-title {
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.env-production {
  .has-many {
    background: #262729 !important;
  }
}
</style>
