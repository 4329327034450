<template>
  <div class="submission-content__layout">
    <div class="submission-content__layout-preview">
      <SubmissionContentLayoutSelector
        v-model="layout.preview"
        label="Preview Field"
        :items="mediaFields"
      />
    </div>
    <div class="submission-content__layout-content">
      <div
        v-for="(item, idx) in layout.content"
        :key="idx"
        class="submission-content__layout-content-item"
      >
        <div class="submission-content__layout-content-item-container">
          <div v-if="item.combo" class="d-flex flex-column" style="gap: 16px">
            <div
              class="d-flex align-center"
              style="gap: 10px"
              v-for="subitem in item.items"
              :key="subitem.uuid"
            >
              <SubmissionContentLayoutSelector
                :items="nonMediaFields"
                label="Content Field"
                v-model="subitem.field_id"
                :rules="[validation.required]"
              />
              <v-btn icon @click="deleteComboField(idx, subitem.uuid)">
                <v-icon>$delete</v-icon>
              </v-btn>
            </div>
          </div>
          <template v-else>
            <SubmissionContentLayoutSelector
              :items="nonMediaFields"
              label="Content Field"
              v-model="item.field_id"
              :rules="[validation.required]"
            />
          </template>
          <div class="submission-content__layout-content-actions">
            <v-btn icon @click="moveUp(idx)">
              <v-icon>$arrow_up</v-icon>
            </v-btn>
            <v-btn icon @click="moveDown(idx)">
              <v-icon>$arrow_down</v-icon>
            </v-btn>
            <v-btn icon @click="deleteField(item.field_id)">
              <v-icon>$delete</v-icon>
            </v-btn>
          </div>
        </div>
        <template v-if="item.combo">
          <v-btn
            color="primary"
            small
            @click="addContentCombinationField(idx)"
            class="my-3"
          >
            Add Field
          </v-btn>
        </template>
        <CodeEditor label="Styles" v-model="item.styles" />
      </div>
      <div class="d-flex" style="gap: 10px">
        <v-btn color="primary" @click="addContentField">Add Field</v-btn>
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn color="primary" @click="addContentCombination" v-on="on">
              Add Combination
            </v-btn>
          </template>
          <span>
            Renders multiple fields on the same line in the visualization
          </span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    value: Object,
    mediaFields: Array,
    nonMediaFields: Array,
  },
  data() {
    return {
      layout: { ...this.value },
    };
  },
  methods: {
    addContentField() {
      const uuid = uuidv4();

      this.layout.content.push({
        uuid,
        styles: `.s-${uuid} {\n}`,
        combo: false,
      });
      this.form = { ...this.form };
    },
    addContentCombination() {
      const uuid = uuidv4();
      const uuid2 = uuidv4();

      this.layout.content.push({
        combo: true,
        styles: `.s-${uuid} {\n}`,
        uuid,
        items: [{ uuid: uuid2 }],
      });
      this.form = { ...this.form };
    },
    addContentCombinationField(idx) {
      const uuid = uuidv4();

      this.layout.content[idx].items.push({
        uuid,
      });
      this.form = { ...this.form };
    },
    moveUp(idx) {
      if (idx < 1) return;
      const { content } = this.layout;
      const element = { ...content[idx] };
      content.splice(idx, 1);
      content.splice(idx - 1, 0, element);

      this.form = { ...this.form };
    },
    moveDown(idx) {
      const { content } = this.layout;
      if (idx >= content.length - 1) return;

      const element = { ...content[idx] };
      content.splice(idx, 1);
      content.splice(idx + 1, 0, element);

      this.form = { ...this.form };
    },
    deleteField(id) {
      const { content } = this.layout;
      this.layout.content = content.filter((t) => t.field_id != id);
    },
    deleteComboField(idx, id) {
      const { content } = this.layout;
      this.layout.content[idx].items = content[idx].items.filter(
        (t) => t.uuid != id
      );
    },
  },
  watch: {
    layout: {
      deep: true,
      handler() {
        if (JSON.stringify(this.layout) != JSON.stringify(this.value)) {
          this.$emit("input", { ...this.layout });
        }
      },
    },
    value: {
      deep: true,
      handler() {
        if (JSON.stringify(this.layout) != JSON.stringify(this.value)) {
          this.layout = { ...this.value };
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.submission-content {
  &__layout {
    display: flex;
    border: 1px solid #e7e7e7;

    &-preview {
      background: #e7e7e7;
      padding: 15px;
      width: 100%;
      max-width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-content {
      width: 100%;
      max-width: 50%;
      padding: 15px;

      &-item {
        margin-bottom: 25px;
        border: 1px solid #ccc;
        padding: 10px;

        &-container {
          display: flex;
          align-items: top;
          justify-content: space-between;
        }
      }
      &-actions {
        margin-left: 10px;
      }
    }
  }
}
</style>
