<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Participant Info">
        <FormRow required label="Email">
          <TextField placeholder="Email" v-model="form.email"></TextField>
        </FormRow>
        <FormRow required label="Password">
          <TextField
            type="password"
            placeholder="Password"
            v-model="form.password"
          ></TextField>
        </FormRow>
        <FormRow required label="Confirm Password">
          <TextField
            type="password"
            placeholder="Password"
            v-model="form.password_confirmation"
          ></TextField>
        </FormRow>
        <v-divider></v-divider>
        <FormRow required label="First Name">
          <TextField
            placeholder="First Name"
            v-model="form.first_name"
          ></TextField>
        </FormRow>
        <FormRow required label="Last Name">
          <TextField
            placeholder="Last Name"
            v-model="form.last_name"
          ></TextField>
        </FormRow>

        <!-- Change User Profile Picture -->
        <FormRow label="Profile Picture">
          <Uploader v-model="form.avatar" :ratio="1" enableCropper />
        </FormRow>

        <v-divider></v-divider>
        <FormRow label="Company">
          <TextField placeholder="Company" v-model="form.company"></TextField>
        </FormRow>
        <FormRow label="Title">
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
      </FormPanel>
      <FormPanel label="Participant Contact">
        <FormRow label="Street Address">
          <TextField
            placeholder="Street Address"
            v-model="form.street"
          ></TextField>
        </FormRow>
        <FormRow label="City">
          <TextField placeholder="City" v-model="form.city"></TextField>
        </FormRow>
        <FormRow label="State">
          <TextField placeholder="State" v-model="form.state"></TextField>
        </FormRow>
        <FormRow label="Zipcode">
          <TextField placeholder="Zipcode" v-model="form.zipcode"></TextField>
        </FormRow>
        <FormRow label="Phone Number">
          <TextField
            placeholder="Phone Number"
            v-model="form.phone"
          ></TextField>
        </FormRow>
      </FormPanel>
      <FormPanel label="Registration Form Responses" v-if="showRegistration()">
        <div class="registration-note">
          Registered via “{{ registrationForm.title }}”
        </div>
        <FormRow
          v-for="(val, idx) in registrationFormFiltered"
          :label="val.label || val.title"
          :key="idx"
          clean
        >
          <div class="extra-field">
            <div class="extra-value" :class="`extra-value-${val.type}`">
              <TextField
                v-if="val.type == 'TextField' || val.type == 'EmailField'"
                :placeholder="val.label"
                v-model="form.extra[val.uuid]"
                :type="val.type == 'EmailField' ? 'email' : 'text'"
              />
              <Checkbox
                v-else-if="val.type == 'Checkbox'"
                v-model="form.extra[val.uuid]"
              />
              <Uploader
                v-else-if="val.type == 'MediaField'"
                :ratio="320 / 180"
                v-model="form.extra[val.uuid]"
                enableCropper
              />
              <RadioGroup
                v-else-if="val.type == 'Radio'"
                v-model="form.extra[val.uuid]"
                :items="val.options"
                item-value="uuid"
                item-text="title"
              />
            </div>
          </div>
        </FormRow>
      </FormPanel>
      <FormPanel label="Participant Extra Information" v-if="showExtra()">
        <FormRow v-for="(val, key) in form.extra" :label="key" :key="key" clean>
          <div class="extra-field">
            <div class="extra-value">
              <TextField
                :placeholder="key"
                v-model="form.extra[key]"
              ></TextField>
            </div>
            <v-btn
              v-if="['super_admin'].includes(currentUser.active_team_role)"
              outlined
              color="primary"
              x-small
              @click="deleteField(key)"
              >Delete</v-btn
            >
          </div>
        </FormRow>
        <FormRow
          clean
          v-if="['super_admin'].includes(currentUser.active_team_role)"
        >
          <v-btn @click="showAddField = true" color="primary">
            + Add field
          </v-btn>
        </FormRow>
      </FormPanel>
      <FormPanel label="Participant Details">
        <FormRow
          label="Block User"
          tooltip="Check the box if this user needs to be blocked from accessing content on your site"
        >
          <Checkbox v-model="form.blocked" />
        </FormRow>
        <FormRow label="Roles">
          <template #tooltip>
            Here you can assign your pre-exisitng "roles" to a user.
          </template>
          <RolesSelector multiple v-model="form.role_ids"></RolesSelector>
        </FormRow>

        <FormRow
          label="Email Validation Results"
          tooltip="These are the results of an email validation check, from when the user registered."
          v-if="
            form.email_validation_results &&
            form.email_validation_results.errors
          "
        >
          <div v-if="form.email_validation_results.errors">
            Errors: {{ form.email_validation_results.errors.length }}
          </div>
          <div
            v-for="(result, index) in form.email_validation_results.results"
            :key="index"
          >
            <div>
              <span class="email-validation-results__email"
                >{{ result.email }}:
              </span>
              <span>{{ result.verdict }}</span>
            </div>

            <div>Source: {{ result.source }}</div>
            <div v-if="result.suggestion">
              (Suggestion: {{ result.suggestion }})
            </div>
          </div>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">
      {{ btnText }}
      <template #actions>
        <div v-if="form.id && !form.confirmed_at">
          <v-btn
            outlined
            color="primary"
            elevation="0"
            rounded
            large
            @click="confirmUser"
          >
            Confirm
          </v-btn>
        </div>
      </template>
    </SubmitFooter>
    <v-dialog max-width="300" v-model="showAddField">
      <v-card>
        <v-card-text>
          <br />
          <div class="title">Add new field</div>
          <br />
          <TextField label="Field Name" v-model="newFieldName" />
          <br />
          <TextField label="Field Value" v-model="newFieldValue" />
          <br />
          <v-btn block color="primary" @click="addNewField">Add field</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";

export default {
  extends: mixin,
  data() {
    return {
      showAddField: false,
      newFieldName: "",
      newFieldValue: "",
      registrationForm: null,
    };
  },
  async mounted() {
    if (this.form.extra && this.form.registration_form_id) {
      const { data } = await this.$api.RegistrationForm.get({
        id: this.form.registration_form_id,
      });
      this.registrationForm = data;
    }
  },
  methods: {
    addNewField() {
      this.form.extra[this.newFieldName] = this.newFieldValue;
      this.form = { ...this.form };
      this.newFieldName = "";
      this.newFieldValue = "";
      this.showAddField = false;
    },
    async deleteField(field) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      delete this.form.extra[field];
      this.form = { ...this.form };
    },
    async confirmUser() {
      const confirmed = await this.$confirmWindow({
        title: "Are you sure?",
        description: `This participant hasn't confirmed their email. Are you sure you want to confirm their account?`,
      });
      if (!confirmed) return;
      await this.$api.User.confirm({ id: this.form.id });
      this.$parent.setForm();
    },
    showExtra() {
      return this.form.extra && !this.form.registration_form_id;
    },
    showRegistration() {
      return (
        this.form.extra &&
        this.form.registration_form_id &&
        this.registrationForm &&
        this.registrationForm.registration_form.length
      );
    },
  },
  computed: {
    registrationFormFiltered() {
      return this.registrationForm.registration_form.filter((field) => {
        const label = field.label || field.title;
        return !["first name", "last name", "password", "email"].includes(
          label.trim().toLowerCase()
        );
      });
    },
  },
};
</script>

<style scoped lang="scss">

.profile{
  border-radius: 50%;
  width: 60px;
  height: 60px;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.profile-hover{
  transform: translateY(-5px);
}

.extra-field {
  display: flex;
  align-items: center;
}

.extra-value {
  margin-right: 15px;
}

.extra-value-MediaField {
  flex-grow: 1;
}

.registration-note {
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  color: var(--v-text-base);
}

.email-validation-results__email {
  font-weight: bold;
}
</style>
