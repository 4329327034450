<template>
  <div class="theme-template">
    <div class="page-title">Theme Templates</div>
    <div class="theme-template__content">
      <div class="theme-template__form">
        <v-text-field
          label="Template Name"
          dense
          hide-details
          outlined
          class="theme-template__input"
          v-model="form.title"
        ></v-text-field>
        <v-btn
          :disabled="createTemplateInProgress"
          @click="createTemplate"
          color="primary"
          >Create Template</v-btn
        >
      </div>
      <div class="theme-template__list">
        <div v-if="list">
          <div class="theme-template__item" v-for="item in list" :key="item.id">
            <div>
              <div class="theme-template__item-title">{{ item.title }}</div>
              <div class="theme-template__item-author">
                {{ item.author_name }}
              </div>
              <div class="theme-template__item-timestamp">
                {{
                  item.created_at
                    | moment(
                      "timezone",
                      currentUser.active_project.timezone,
                      "MM/DD/YY h:mm A zz"
                    )
                }}
              </div>
            </div>
            <div class="theme-template__item-actions">
              <v-btn
                :to="`/theme-templates/${item.id}`"
                outlined
                x-small
                class="mr-3"
                >Edit</v-btn
              >
              <v-btn @click="destroy(item)" color="primary" outlined x-small
                >Delete</v-btn
              >
            </div>
          </div>
        </div>
        <Spinner v-else />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    theme: Object,
  },
  data() {
    return {
      form: {},
      list: null,
      createTemplateInProgress: false,
    };
  },
  created() {
    this.setTemplates();
  },
  methods: {
    async destroy(item) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Theme.destroy({ id: item.id, template: true });
      this.setTemplates();
    },
    async setTemplates() {
      const { data } = await this.$api.Theme.lite({ template: true });
      this.list = data;
    },
    async createTemplate() {
      try {
        this.createTemplateInProgress = true;
        await this.$api.Theme.create({
          ...this.form,
          source_theme_id: this.theme.id,
          template: true,
        });
        this.form = {};
        this.setTemplates();
        this.$root.$emit("themesChanged");
      } finally {
        this.createTemplateInProgress = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.page-title {
  font-size: 20px;
}

.theme-template {
  padding: 20px 25px;

  &__content {
    position: relative;
    &:after {
      content: "";
      box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1;
      pointer-events: none;
    }
  }

  &__form {
    background: #f7f7f7;
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dedede;
  }
  &__input {
    max-width: 190px;

    &::v-deep fieldset {
      background: white;
    }
  }

  &__list {
    width: 100%;
    height: 400px;
    overflow: auto;
  }
  &__item {
    border-bottom: 1px solid #dedede;
    margin-bottom: -1px;
    padding: 8px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-size: 14px;
      font-weight: bold;
    }
    &-timestamp {
      color: gray;
      font-weight: bold;
      font-size: 10px;
    }
    &-author {
      color: var(--v-primary-base);
      font-size: 10px;
      font-weight: bold;
    }
    &-actions {
    }
  }
}
</style>
