<template>
  <div>
    <TagSelector
      :items="calcComponentsList(localValue.custom_component_id)"
      :value="localValue.custom_component_id"
      @input="setItem($event)"
      class="form-builder__custom-component-selector"
    />
  </div>
</template>

<script>
import mixin from "./form-builder.mixin.js";
export default {
  extends: mixin,
  data() {
    return {
      customComponents: [],
    };
  },
  created() {
    this.setCustomComponents();
  },
  methods: {
    calcComponentsList(id) {
      if(!id) return this.customComponents;
      return this.customComponents.filter(
        (t) => t.custom_component_id != id
      );
    },
    async setCustomComponents() {
      const { data } = await this.$api.CustomComponent.lite();
      this.customComponents = data;
    },
    setItem(event){
      this.localValue.custom_component_id = event;
      this.$emit("input", this.localValue);
    }
  },
};
</script>

<style>
.form-builder__custom-component-selector.tag-selector {
  max-width: 400px !important;
}
</style>
