<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Schedule Item Info">
        <FormRow>
          <template #label> Link To Existing "Event" </template>
          <template #tooltip
            >If this item directly correlates to a pre-existing "Event" on the
            platform, select the event from the drop-down menu.</template
          >
          <EventsSelector v-model="form.event_id" />
        </FormRow>
        <FormRow
          label="Title"
          required
          tooltip="This is the title for your schedule as it will be displayed on the front-end of your site."
        >
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow label="Description">
          <template #tooltip>
            Add any finer detail to be displayed on the front-end of your site
            under the "Title."
          </template>
          <TextArea
            placeholder="Description"
            v-model="form.description"
          ></TextArea>
        </FormRow>
        <FormRow
          tooltip="This replaces the default Schedule thumbnail for your entry."
        >
          <template #label>Background Image<br />(900x150)</template>
          <Uploader
            position="top"
            :ratio="900 / 150"
            v-model="form.preview"
            enableCropper
          />
        </FormRow>
        <FormRow label="Use Dark Theme">
          <template #tooltip>
            Tick this box if the image content is "light," the copy would become
            black to ensure visibility.
          </template>
          <Checkbox placeholder="Use Dark Theme" v-model="form.dark"></Checkbox>
        </FormRow>
      </FormPanel>

      <FormPanel label="Schedule Item Timing">
        <FormRow
          tooltip="The time at which this scheduled item will happen."
          label="Start Time"
          required
        >
          <DateTimePicker
            placeholder="Select Date/Time"
            v-model="form.start_at"
          />
        </FormRow>
        <FormRow
          tooltip="The time at which this scheduled item will end."
          label="End Time"
        >
          <DateTimePicker
            placeholder="Select Date/Time"
            v-model="form.end_at"
          />
        </FormRow>
      </FormPanel>
      <FormPanel label="Schedule Item Details">
        <FormRow
          required
          label="Display Schedule(s)"
          tooltip="Select the schedule or schedules on which you'd like this item to appear."
        >
          <SchedulesSelector multiple v-model="form.schedule_ids" />
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../../_extends/form.extends.js";
export default {
  extends: mixin,
  data() {
    return {
      schedules: [],
    };
  },
};
</script>

<style></style>
