<template>
  <div>
    <RadioGroup
      itemValue="value"
      :items="options"
      :value="value"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    label: String,
  },
  data() {
    return {
      options: [
        { title: "Yes", value: true },
        { title: "No", value: false },
      ],
    };
  },
};
</script>

<style scoped lang="scss"></style>
