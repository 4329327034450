<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Users </template>
      <template v-slot:description>
        Users are all your guests and team members for use on the live site. You
        can create custom “roles” for guests, which can then be used to create
        unique experiences within Commons. This is also where all contact info
        for you guests and team will live.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/users/participants.svg" alt="Users" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Participant"
      btn-to="/users/participants/new"
      :sort-by.sync="sortBy"
      :search-text.sync="searchText"
      search-placeholder="Search Participant"
    />

    <div class="page-subtitle">Roles</div>
    <div v-if="rolesList">
      <Stories
        :click-path="(item) => `/users/participants/roles/${item.id}/edit`"
        create-path="/users/participants/roles/new"
        :list="rolesList"
        add-btn-text="New Role"
      />
    </div>
    <Spinner v-else relative />

    <div class="page-subtitle">
      <span> Participants </span>
      <div class="actions">
        <form
          method="post"
          action="/api/v1/admin_side/users/download_schedule.csv"
          target="_blank"
        >
          <input
            type="hidden"
            name="token"
            :value="$store.getters['auth/token']"
          />
          <v-btn
            type="submit"
            small
            outlined
            color="primary"
            class="download-schedule-btn"
            v-if="currentUser.active_project.slug == 'EVERY2202'"
          >
            Download Schedule CSV
          </v-btn>
        </form>
        <form
          method="post"
          action="/api/v1/admin_side/users/download.csv"
          target="_blank"
        >
          <input
            type="hidden"
            name="token"
            :value="$store.getters['auth/token']"
          />
          <v-btn type="submit" small outlined color="primary">
            Download CSV
          </v-btn>
        </form>
      </div>
    </div>
    <div v-if="list">
      <div v-if="computedList.length">
        <ParticipantsDataTable :list="computedList" @destroy="destroy" />
        <!-- <Pagination :perPage.sync="perPage" :page.sync="page" /> -->
      </div>
      <div v-else class="no-entries">No Participants</div>
    </div>
    <Spinner v-else relative />
  </div>
</template>

<script>
import ParticipantsDataTable from "@/components/dataview/participants.datatable";
import IndexPage from "../index-page";

export default {
  name: "ParticipantsPage",
  components: { ParticipantsDataTable },
  extends: IndexPage,
  data() {
    return {
      rolesList: null,
      searchFields: ["full_name", "email"],
    };
  },
  created() {
    this.fetchRoles();
  },
  methods: {
    async fetchRoles() {
      const { data } = await this.$api.Role.get();
      this.rolesList = data;
    },
    async fetchList() {
      const { data } = await this.$api.User.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroy({ id }) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.User.destroy({ id });
      this.list = this.list.filter((t) => t.id != id);
    },
  },
};
</script>

<style scoped lang="scss">
.page-subtitle {
  display: flex;
  justify-content: space-between;
}
.actions {
  display: flex;
}
.download-schedule-btn {
  margin-right: 1em;
}
</style>
