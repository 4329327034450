import { render, staticRenderFns } from "./not-authorized.layout.vue?vue&type=template&id=2baf0ebc&scoped=true&"
import script from "./not-authorized.layout.vue?vue&type=script&lang=js&"
export * from "./not-authorized.layout.vue?vue&type=script&lang=js&"
import style0 from "./not-authorized.layout.vue?vue&type=style&index=0&id=2baf0ebc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2baf0ebc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VBtn,VIcon,VMain})
