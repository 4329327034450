<template>
  <div>
    <v-tabs v-if="propotions.length" v-model="activeTab">
      <v-tab>Source Image</v-tab>
      <v-tab v-for="tab in propotions" :key="tab.techname">{{
        tab.techname
      }}</v-tab>
      <v-btn
        v-if="localValue.source"
        @click="setVersions(localValue.source)"
        x-small
        style="margin-top: 13px"
        outlined
        >Set All Versions to Source</v-btn
      >
    </v-tabs>
    <ImageWithCropper
      @updated="setVersions"
      v-if="activeTab == 0"
      v-model="localValue.source"
    />
    <div class="propotions">
      <div v-for="(tab, idx) in propotions" :key="tab.techname">
        <ImageWithCropper
          :source="localValue.source"
          v-if="activeTab == idx + 1"
          v-model="localValue[tab.techname]"
          :settings="{ ratio: tab.w / tab.h, enableCropper: true }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ImageWithCropper from "./ImageWithCropper.vue";
export default {
  props: {
    settings: Object,
    value: Object,
  },
  components: {
    ImageWithCropper,
  },
  data() {
    return {
      activeTab: 0,
      localValue: { ...this.value },
    };
  },
  methods: {
    setVersions($event) {
      this.propotions.map((t) => {
        this.localValue = {
          ...this.localValue,
          [t.techname]: $event,
        };
      });
    },
  },
  computed: {
    propotions() {
      return this.settings.proportions || [];
    },
  },
  watch: {
    localValue: {
      deep: true,
      handler() {
        this.$emit("input", { ...this.localValue });
      },
    },
    value: {
      deep: true,
      immediate: true,
      handler() {
        if (JSON.stringify(this.localValue) != JSON.stringify(this.value)) {
          this.localValue = this.value;
        }
        if (this.localValue.url && !this.localValue.source) {
          this.localValue = {
            ...this.localValue,
            source: {
              url: this.localValue.url,
            },
          };
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
.propotions::v-deep .uploader {
  background-size: cover !important;
}
</style>
