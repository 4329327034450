<template>
  <div>
    <div v-if="list">
      <div v-if="list.length" class="submission-cards">
        <div
          v-for="item in list"
          :key="item.id"
          class="submission-card"
          :class="{ 'with-preview': previewUrl(item) }"
          :style="{ backgroundImage: `url(${previewUrl(item)})` }"
        >
          <div class="submission-card__content">
            <div v-if="item.published" class="submission-card__published">
              Published
            </div>
            <div class="submission-card__title">
              {{ title(item) }}
            </div>
            <div class="submission-card__description">
              <div v-for="(line, idx) in description(item)" :key="idx">
                <b>{{ line.label }}:</b> {{ line.value }}
              </div>
            </div>
          </div>
          <div class="submission-card__footer">
            <Actions
              hide-duplicate
              :dark="!!previewUrl(item)"
              :path="`/widgets/submission-forms/${entity.id}/submissions/${item.id}`"
              @destroy="destroy(item)"
            />
            <div class="submission-card__status" :class="item.status">
              {{ item.status }}
            </div>
          </div>
          <div class="submission-card__user">
            <router-link :to="`/users/participants/${item.user_id}/edit`">{{
              item.user_name
            }}</router-link>
          </div>
        </div>
      </div>
      <div v-else class="no-entries">No Submissions</div>
    </div>
    <Spinner v-else />
  </div>
</template>

<script>
import _ from "lodash";

export default {
  props: {
    entity: Object,
  },
  data() {
    return {
      list: null,
    };
  },
  created() {
    this.setList();
  },
  methods: {
    async destroy(submission) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Submission.destroy({ id: submission.id });

      this.list = this.list.filter((t) => t.id != submission.id);
    },
    async setList() {
      const { data } = await this.$api.Submission.lite({
        submission_form_id: this.entity.id,
      });
      this.list = data;
    },
    previewUrl(item) {
      const preivewField = item.formatted_data.find(
        (t) => t.type == "MediaField" && t.value?.preview?.thumb?.url
      );
      return preivewField?.value?.preview?.thumb?.url;
    },
    title(item) {
      const titleField = item.formatted_data.find((t) => t.type == "TextField");
      return titleField?.value;
    },
    description(item) {
      const descriptionFields = _.take(
        _.tail(
          item.formatted_data.filter((t) =>
            ["TextField", "EmailField"].includes(t.type)
          )
        ),
        4
      );
      return descriptionFields;
    },
  },
};
</script>

<style scoped lang="scss">
.submission-cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.submission-card {
  width: calc(33.33% - 20px);
  background: var(--v-border-lighten1);
  margin: 10px;
  padding: 20px 20px 10px 20px;
  border-radius: 10px;
  min-height: 165px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  &__published {
    font-size: 10px;
    text-transform: uppercase;
    color: white;
    background: var(--v-primary-base);
    display: inline-flex;
    padding: 1px 5px;
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 3px;
  }

  &.with-preview {
    color: white;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.35);
    }
  }

  &__content,
  &__footer {
    position: relative;
    z-index: 1;
  }

  &__title {
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
  }
  &__user {
    font-weight: 900;
    font-size: 14px;
    line-height: 24px;
    background: rgba(255, 255, 255, 0.6);
    padding: 0 8px;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
  &__description {
    font-size: 12px;
    line-height: 16px;
  }
  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-transform: capitalize;
  }

  &__status {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    padding: 5px 12px;
    border-radius: 100px;

    &.published {
      background: var(--v-text-base);
      color: white;
    }
    &.unpublished {
      background: var(--v-primary-base);
      color: white;
    }
  }
}
</style>
