var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"has-many",class:{ 'link-mode': _vm.linkMode, 'as-parent': _vm.anyParentIncludes }},[_c('div',{staticClass:"has-many-title"},[_c('h2',{staticClass:"has-many-title__title"},[_vm._v(_vm._s(_vm.settings.title))]),(_vm.settings.helper_text)?_c('v-tooltip',{attrs:{"open-delay":"500","max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"form-question",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("$question_round")])]}}],null,false,3923573852)},[_c('span',[_vm._t("tooltip",[_vm._v(" "+_vm._s(_vm.settings.helper_text)+" ")])],2)]):_vm._e()],1),_c('v-expansion-panels',{staticClass:"panels",attrs:{"accordion":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.fields),function(field,idx){return _c('v-expansion-panel',{key:field.uuid,staticClass:"form-panel",class:{
        'visible-fields':
          _vm.linkMode && (_vm.parentIncludes(field) || _vm.selfIncludes(field)),
      },style:([
        _vm.visiblePanels && !_vm.visiblePanels.includes(field.uuid)
          ? { display: 'none' }
          : {} ])},[(!_vm.linkMode || _vm.anyParentIncludes)?_c('v-expansion-panel-header',[_c('div',[_c('v-text-field',{staticStyle:{"max-width":"200px","padding":"0"},attrs:{"hide-details":"","placeholder":_vm.findSchemaTitle(field)},on:{"click":function($event){$event.stopPropagation();}},model:{value:(field.panel_title),callback:function ($$v) {_vm.$set(field, "panel_title", $$v)},expression:"field.panel_title"}}),_c('div',{staticClass:"has-many__actions"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyLink(field)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$copy_link")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.duplicate(field)}}},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("$content_copy")])],1),_c('v-btn',{staticClass:"has-many-move-up",attrs:{"icon":"","disabled":idx == 0},on:{"click":function($event){$event.stopPropagation();return _vm.moveUp(idx)}}},[_c('v-icon',[_vm._v("$arrow_up")])],1),_c('v-btn',{staticClass:"has-many-move-up",attrs:{"icon":"","disabled":idx == _vm.fields.length - 1},on:{"click":function($event){$event.stopPropagation();return _vm.moveDown(idx)}}},[_c('v-icon',[_vm._v("$arrow_down")])],1),_c('v-btn',{staticClass:"has-many-delete",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteEntity(idx)}}},[_c('v-icon',[_vm._v("$delete")])],1)],1)],1)]):_vm._e(),_c('v-expansion-panel-content',{attrs:{"eager":""}},[_c('div',{staticClass:"has-many-field"},[_c('SchemaBuilder',{attrs:{"schemaId":field.schema_id || _vm.settings.schema_id},model:{value:(_vm.fields[idx]),callback:function ($$v) {_vm.$set(_vm.fields, idx, $$v)},expression:"fields[idx]"}})],1)])],1)}),1),(!_vm.linkMode || _vm.anyParentIncludes)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","outlined":""}},'v-btn',attrs,false),on),[_vm._v("+ Add "+_vm._s(_vm.entityTitle))])]}}],null,false,1226887725)},[_c('v-list',_vm._l((_vm.schemas),function(schema){return _c('v-list-item',{key:schema.id,on:{"click":function($event){return _vm.addEntity(schema)}}},[_vm._v(_vm._s(schema.title))])}),1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }