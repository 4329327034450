<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="Countdown Clock Info">
        <FormRow required label="Countdown Name">
          <TextField
            :rules="[validation.required]"
            placeholder="Countdown Name"
            v-model="form.title"
            :rows="3"
          />
        </FormRow>
        <FormRow
          label="Left Title"
          tooltip="The text you'd like to appear on the left side of your clock."
        >
          <TextArea
            placeholder="Left Title"
            v-model="form.left_title"
            :rows="3"
          ></TextArea>
        </FormRow>
        <FormRow
          label="Right Title"
          tooltip="The text you'd like to appear on the right side of your clock."
        >
          <TextArea
            placeholder="Right Title"
            v-model="form.right_title"
            :rows="3"
          ></TextArea>
        </FormRow>
        <FormRow
          label="Background Color"
          tooltip="Here you can select the color of your background for the Countdown Clock."
        >
          <ColorPicker placeholder="Background Color" v-model="form.bg_color" />
        </FormRow>
        <FormRow
          tooltip="Here you can add a custom background image for the Countdown Clock."
        >
          <template #label>
            Background Image<br />
            (1280x300)
          </template>
          <Uploader :ratio="1280 / 300" v-model="form.preview" enableCropper />
        </FormRow>
        <FormRow
          label="Title Text Color"
          tooltip="Here you can select the color of your text for the Countdown Clock."
        >
          <ColorPicker
            placeholder="Title Text Color"
            v-model="form.text_color"
          />
        </FormRow>
      </FormPanel>
      <FormPanel label="Countdown Clock Timing">
        <FormRow
          required
          label="Countdown To"
          tooltip="Enter the date and time for the countdown here."
        >
          <DateTimePicker
            placeholder="Select Date/Time"
            v-model="form.countdown_to"
          />
        </FormRow>
      </FormPanel>
      <FormPanel label="Countdown Clock Details">
        <FormRow
          label="Display Page(s)"
          tooltip="Select the page or pages on which you'd like this Countdown Clock to appear."
        >
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";

export default {
  extends: mixin,
};
</script>

<style></style>
