<template>
  <div>
    <CodeEditor :label="label" @input="$emit('input', $event)" :value="value">
      <template v-slot:label>
        <div class="html-title">
          <div class="html-title__title">{{ settings.title }}</div>
          <v-tooltip
            open-delay="500"
            v-if="settings.helper_text"
            max-width="300"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" size="20" class="form-question"
                >$question_round</v-icon
              >
            </template>
            <span>
              <slot name="tooltip">
                {{ settings.helper_text }}
              </slot>
            </span>
          </v-tooltip>
        </div>
      </template>
    </CodeEditor>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    label: String,
    settings: Object,
  },
};
</script>

<style scoped lang="scss">
.html-title {
  padding: 15px 10px 0 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 8px;
}
</style>
