<template>
  <v-form ref="form">
    <FormPanels>
      <FormPanel label="UGC Section Info">
        <FormRow
          required
          label="Title"
          tooltip="This is the title of the Submission Form as it will be displayed on the front-end of your site."
        >
          <TextField placeholder="Title" v-model="form.title"></TextField>
        </FormRow>
        <FormRow
          label="Allow Anonymous"
          tooltip="Tick this box if you want anyone to be able to submit the form."
        >
          <Checkbox placeholder="Allow Anonymous" v-model="form.anon" />
        </FormRow>
        <FormRow
          label="Send email with submission link"
          tooltip="Send email with submission link"
        >
          <Checkbox
            placeholder="Send email with submission link"
            v-model="form.send_sharing_email"
          />
        </FormRow>
        <FormRow
          label="Submission email content"
          v-if="form.send_sharing_email"
        >
          <template #tooltip>
            Submission email content (html available)</template
          >
          <TextArea
            placeholder="Submission email content (html available)"
            v-model="form.sharing_email_text"
          ></TextArea>
        </FormRow>
        <FormRow
          label="Publish Automatically"
          tooltip="Enable this is you want posts to be published immediately, without moderation. (Use with caution.) Note that video uploads won't have preview images, unless they are webcam fields."
        >
          <Checkbox placeholder="Publish Automatically" v-model="form.autopublish" />
        </FormRow>
        <FormRow
          label="Place in Page"
          tooltip="By default, submission forms open in a dialog. Check this if you want the form to show directly in the page instead."
        >
          <Checkbox placeholder="Place in Page" v-model="form.place_in_page" />
        </FormRow>
        <FormRow label="Description">
          <template #tooltip>
            Add any finer detail to be displayed on the front-end of your site
            under the "title."
          </template>
          <TextArea
            placeholder="Description"
            v-model="form.description"
          ></TextArea>
        </FormRow>
        <FormRow label="Submission Button Text">
          <template #tooltip>
            This is the Call-to-Action button text that leads the user to the
            Submission Form dialog. (Hidden if "Place in Page" is enabled.)
          </template>
          <TextField v-model="form.btn_text"></TextField>
        </FormRow>
        <FormRow label="Submit Button Text">
          <template #tooltip>
            This is the label on the final submit button of a Submission Form. (Defaults to "Submit".)
          </template>
          <TextField v-model="form.submit_btn_txt"></TextField>
        </FormRow>
        <FormRow label="Success Header Text">
          <template #tooltip>
            This is what is shown in the header of the submission dialog after a
            successful submission. "Submission complete" will be shown if this
            is blank.
          </template>
          <TextField v-model="form.success_header"></TextField>
        </FormRow>
        <FormRow label="Success Message">
          <template #tooltip>
            This is what is shown in the copy below the header of the submission
            dialog after a successful submission. "Thank you for your
            submission." will be shown if this is blank.
          </template>
          <TextArea v-model="form.success_message"></TextArea>
        </FormRow>
        <FormRow label="Success Share Custom Component">
          <template #tooltip>
            Include a custom component with the submission success message.
          </template>
          <CustomComponentSelector v-model="form.success_share_custom_component_id"></CustomComponentSelector>
        </FormRow>
        <FormRow label="Bottom Matter">
          <template #tooltip>
            This is what is shown below the submit button in the submission
            form. Supports HTML.
          </template>
          <TextArea v-model="form.bottom_matter"></TextArea>
        </FormRow>
        <FormRow
          label="Manual Order"
          tooltip="Enable this to allow manual control over the order of the submissions."
        >
          <Checkbox placeholder="Manual Order" v-model="form.manual_order" />
        </FormRow>
      </FormPanel>

      <FormPanel required label="UGC Section Form">
        <FormBuilder v-model="form.submission_form" />
      </FormPanel>
      <FormPanel
        label="UGC Section Details"
        tooltip="Select the page or pages on which you'd like this Submission Form to appear."
      >
        <FormRow
          label="Submission Notification Email Fwd'ing"
          tooltip="Enter an email address for Submission notification emails to to be sent."
        >
          <TextField
            placeholder="Submission Notification Email Fwd'ing"
            v-model="form.email_to"
          ></TextField>
        </FormRow>
        <FormRow label="Display Page(s)">
          <PagesSelector multiple v-model="form.page_ids"></PagesSelector>
        </FormRow>

        <FormRow
          label="Slug"
          tooltip="For developers."
        >
          <TextField
            placeholder="Slug"
            v-model="form.slug"
          ></TextField>
        </FormRow>
      </FormPanel>
    </FormPanels>
    <SubmitFooter :refs="$refs" :submit="submit">{{ btnText }}</SubmitFooter>
  </v-form>
</template>

<script>
import mixin from "../../_extends/form.extends.js";
export default {
  extends: mixin,
};
</script>

<style></style>
