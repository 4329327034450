<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="id"
      show-expand
      class="elevation-0 data-table"
    >
      <template v-slot:body="{ items }">
        <tbody v-for="item in items" :key="item.id">
          <tr class="data-table__row">
            <td />

            <td>
              <router-link :to="`/variables/${item.id}/edit`">
                {{ item.slug }}
              </router-link>
            </td>
            <td>
              <router-link :to="`/variables/${item.id}/edit`">
                {{ item.value }}
              </router-link>
            </td>
            <td class="text-center nowrap">
              <Actions
                hide-show
                hide-duplicate
                :path="`/variables/${item.id}`"
                @destroy="$emit('destroy', item)"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Slug",
          align: "start",
          value: "slug",
          sortable: false,
        },
        {
          text: "Value",
          align: "start",
          value: "value",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          sortable: false,
        },
      ];
    },
  },
};
</script>

<style></style>
