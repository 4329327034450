<template>
  <div>
    <Banner theme="pink">
      <template v-slot:title> Events </template>
      <template v-slot:description>
        Content Sections are rows of thumbnails (content blocks) that can be
        used to house pre-recorded video content, downloads, popups w/ imagery &
        copy, or external links. These can exist on any page but are not a part
        of the timeline.
      </template>
      <template v-slot:image>
        <img src="@/assets/banners/events.svg" alt="Events" />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Event"
      btn-to="/events/new"
      :view.sync="view"
      :sort-by.sync="sortBy"
      search-placeholder="Search Events"
      :search-text.sync="searchText"
    />

    <div v-if="list">
      <div v-if="computedList.length">
        <EventsDataTable
          v-if="view == 'table'"
          :list="computedList"
          @destroy="destroy"
          @duplicate="duplicate"
        />
        <EventsDataGrid
          v-if="view == 'grid'"
          :list="computedList"
          @destroy="destroy"
          @duplicate="duplicate"
        />
        <!-- <Pagination :perPage.sync="perPage" :page.sync="page" /> -->
      </div>
      <div v-else class="no-entries">No Events</div>
    </div>
    <Spinner v-else relative />
  </div>
</template>

<script>
import IndexPage from "./index-page";

import EventsDataTable from "@/components/dataview/events.datatable";
import EventsDataGrid from "@/components/dataview/events.datagrid";
export default {
  name: "EventsPage",
  components: { EventsDataTable, EventsDataGrid },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.Event.lite({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroy({ id }) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.Event.destroy({ id });
      this.list = this.list.filter((t) => t.id != id);
    },
    async duplicate({ id }) {
      await this.$api.Event.duplicate({ id });
      this.fetchList();
    },
  },
};
</script>

<style scoped lang="scss"></style>
