import Vue from "vue";

[
  "RichTextEditor",
  "TextField",
  "TextArea",
  "RadioGroup",
  "Checkbox",
  "ColorPicker",
  "DateTimePicker",
  "FormBuilder",
  "Banner",
  "Uploader",
  "UploaderWithBgPosition",
  "Spinner",
  "Search",
  "ContextSearch",
  "Dropdown",
  "SubmitFooter",
  "IndexPageActions",
  "Pagination",
  "Stories",
  "Actions",
  "Slides",
  "Alert",
  "ConfirmDialog",
  "RecaptchaText",
  "ThemesTabs",
  "Avatar",
  "Switcher",
  "CodeEditor",
  "Addon",
  "SubmissionLayoutBuilder",
  "EnvSelectorDialog",
  "MetaTagsEditor",
  "ThemeTemplate",
  "JsonViewer",
  "ImageCropper",
].forEach((e) => {
  Vue.component(e, require(`./${e}.vue`).default);
});

[
  "TextBlock",
  "Countdown",
  "ContentSection",
  "Event",
  "List",
  "Schedule",
  "SupportForm",
  "SubmissionForm",
  "HtmlBlock",
  "SubmissionContent",
  "Presentation",
  "CustomComponent",
  "RegistrationForm",
  "VenueEventsList",
].forEach((e) => {
  Vue.component(`Page${e}`, require(`./page-components/${e}.vue`).default);
});

["FormRow", "FormPanels", "FormPanel"].forEach((e) => {
  Vue.component(e, require(`./form/${e}.vue`).default);
});

[
  "AddonEmailPasswordLogin",
  "AddonCasLogin",
  "AddonJustGiving",
  "AddonTwitterLogin",
  "AddonLinkedinLogin",
  "AddonFacebookLogin",
  "AddonGoogleLogin",
  "AddonVimeoUpload",
  "AddonYoutubeUpload",
  "AddonGa",
  "AddonPixel",
  "AddonTikTokPixel",
  "AddonGdpr",
  "AddonMailerlite",
  "AddonDropbox",
  "AddonGoogleDrive",
  "AddonTicketmaster",
].forEach((e) => {
  Vue.component(e, require(`./addon-forms/${e}.vue`).default);
});

["ListPanels", "ListPanel"].forEach((e) => {
  Vue.component(e, require(`./list/${e}.vue`).default);
});

[
  "Boolean",
  "DateTime",
  "HasMany",
  "HasOne",
  "Html",
  "Image",
  "MultiSelect",
  "Number",
  "RichText",
  "Select",
  "TextArea",
  "TextField",
].forEach((e) => {
  Vue.component(`SchemaField${e}`, require(`./schema/fields/${e}.vue`).default);
  Vue.component(
    `SchemaBuilder${e}`,
    require(`./schema/builder/${e}.vue`).default
  );
});

["RelationSelector"].forEach((e) => {
  Vue.component(`SchemaField${e}`, require(`./schema/fields/${e}.vue`).default);
});

["Builder"].forEach((e) => {
  Vue.component(`Schema${e}`, require(`./schema/${e}.vue`).default);
});

[
  "BlogTagsSelector",
  "TimezoneSelector",
  "EnvSelector",
  "ThemesSelector",
  "SchedulesSelector",
  "TagSelector",
  "ListsSelector",
  "NotificationTypeSelector",
  "TextBlockLayoutSelector",
  "FontSelector",
  "Select",
  "EventsSelector",
  "PagesSelector",
  "RolesSelector",
  "ContentSectionsSelector",
  "ProjectSelector",
  "ProjectsSelector",
  "UsersSelector",
  "SubmissionFormSelector",
  "SubmissionContentLayoutSelector",
  "CustomComponentsSelector",
  "CustomComponentSelector",
  "TicketmasterVenueSelector",
  "VenueEventSelector",
].forEach((e) => {
  Vue.component(e, require(`./selects/${e}.vue`).default);
});

[
  "PresentationImage",
  "PresentationRotator",
  "PresentationVimeo",
  "PresentationYoutube",
].forEach((e) => {
  Vue.component(e, require(`./presentation/${e}.vue`).default);
});

[
  "TextField",
  "TextArea",
  "EmailField",
  "NumberField",
  "MediaField",
  "Checkbox",
  "Radio",
  "Select",
  "HtmlBlock",
  "PasswordField",
  "CustomComponent",
].forEach((e) => {
  Vue.component(
    `FormBuilder${e}`,
    require(`./form-builder/${e}.form-builder.vue`).default
  );
});

[
  "TextField",
  "TextArea",
  "EmailField",
  "NumberField",
  "MediaField",
  "Checkbox",
  "Radio",
  "HtmlBlock",
  "Select",
].forEach((e) => {
  Vue.component(
    `Dynamic${e}`,
    require(`./dynamic-inputs/${e}.dynamic.vue`).default
  );
});

["NextBtn", "BackBtn"].forEach((e) => {
  Vue.component(`Onboarding${e}`, require(`./onboarding/${e}.vue`).default);
});
