<template>
  <div>
    <h2 class="page-title">Find Or Create</h2>
    <div
      class="error-box"
      v-if="['wrong_project', 'wrong_env'].includes(result)"
    >
      <div v-if="result == 'wrong_project'">
        You are in the wrong project. Before proceeding, switch to target
        project.
      </div>
      <div v-else-if="result == 'wrong_env'">
        You are in the wrong environment. Before proceeding, switch to
        <b>{{ env }}</b
        >.
      </div>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      variable: null,
      result: null,
      env: null,
    };
  },
  created() {
    this.findVariable();
  },
  methods: {
    async findVariable() {
      const { project_id, env, slug, content } = this.$route.query;
      this.env = env;
      if (project_id && project_id != this.currentUser.active_project_id) {
        this.result = "wrong_project";
        return;
      }
      if (env && env != this.currentUser.active_project_env) {
        this.result = "wrong_env";
        return;
      }

      const { data } = await this.$api.Variable.find({ slug });
      this.variable = data;
      this.result = "ok";

      if (this.variable) {
        this.$router.push(`/variables/${this.variable.id}/edit`);
      } else {
        const params = new URLSearchParams({
          slug,
          content,
        });
        this.$router.push(`/variables/new?${params}`);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.error-box {
  padding: 15px;
  border: 2px solid red;
  border-radius: 5px;
}
</style>
