<template>
  <div>
    <div class="page-title">MailerLite</div>
    <p>Please contact a web devveloper to complete setup.</p>
  </div>
</template>

<script>
import mixin from "./addon-form.mixin";

export default {
  extends: mixin,
};
</script>

<style></style>
