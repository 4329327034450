<template>
  <div>
    <DateTimePicker
      :label="label"
      @input="$emit('input', $event)"
      :value="value"
      style="margin-bottom: 10px"
    ></DateTimePicker>
  </div>
</template>

<script>
export default {
  props: {
    value: [Date, String],
    label: String,
  },
};
</script>

<style scoped lang="scss"></style>
