<template>
  <div>
    <ListPanel
      :hide-destroy="page.url == '/'"
      :icon="page.icon"
      :action-path="`/pages/${page.id}`"
      @destroy="$emit('destroy', page)"
      @duplicate="$emit('duplicate', page)"
    >
      <template #title>
        {{ page.title }}
      </template>
      <template #label>
        {{ pageLabel(page) }}
      </template>
      <div v-if="!page.is_virtual_page">
        <div v-if="normalizeComponents(page.components).length">
          <component
            :is="'Page' + component.type"
            v-for="(component, idx) in normalizeComponents(page.components)"
            :key="component.id"
            :item="component"
            :widgets="page.widgets"
            @up="up(idx, page)"
            @down="down(idx, page)"
          />
        </div>

        <div v-else class="no-entries">No Content</div>
      </div>
      <template #prependActions>
        <v-btn icon @click.stop="upMenu(page.id)">
          <v-icon size="24"> $arrow_up </v-icon>
        </v-btn>
        <v-btn icon @click.stop="downMenu(page.id)">
          <v-icon size="24"> $arrow_down </v-icon>
        </v-btn>
      </template>
    </ListPanel>
    <div
      v-if="page.menuChildren && page.menuChildren.length"
      class="pages-group__children"
    >
      <div v-for="child in page.menuChildren" :key="child.id">
        <MenuPageListPanel
          :page="child"
          @refresh="$emit('refresh')"
          @destroy="$emit('destroy', $event)"
          @duplicate="$emit('duplicate', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import pageindexMixin from "./page-index.mixin";
export default {
  name: "MenuPageListPanel",
  mixins: [pageindexMixin],
  props: {
    page: Object,
  },
  methods: {
    async upMenu(id) {
      const res = await this.$api.Page.upMenu({
        id,
      });
      if (res.data.msg) {
        this.$allertWindow(res.data.msg, "error");
      }
      this.$emit("refresh");
    },
    async downMenu(id) {
      const res = await this.$api.Page.downMenu({
        id,
      });
      if (res.data.msg) {
        this.$allertWindow(res.data.msg, "error");
      }
      this.$emit("refresh");
    },
  },
};
</script>

<style scoped lang="scss">
@import "./pages-index.scss";
</style>
