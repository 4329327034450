<template>
  <div>
    <div class="page-title">Edit Tag</div>
    <Form
      v-if="form"
      v-model="form"
      :submit="update"
      btnText="Update Tag"
      action="edit"
      @destroy="destroy"
    />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.blog-tag.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async destroy() {
      await this.$api.BlogTag.destroy({ id: this.$route.params.id });
      this.$router.push("/blog");
    },
    async setForm() {
      const { data } = await this.$api.BlogTag.get({
        id: this.$route.params.id,
      });
      this.form = data;
    },
    async update() {
      await this.$api.BlogTag.update(this.form);
      this.$router.push("/blog");
    },
  },
};
</script>

<style></style>
