<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Image Library </template>
      <template v-slot:description />
      <template v-slot:image>
        <img
          src="@/assets/banners/widgets/custom-components.svg"
          alt="Image Library"
        />
      </template>
    </Banner>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Image"
      btn-to="/dev-tools/image-assets/new"
      :sort-by.sync="sortBy"
      search-placeholder="Search Images"
      :search-text.sync="searchText"
    />

    <div v-if="list">
      <ImageAssetsDataTable
        v-if="computedList.length"
        :list="computedList"
        @destroy="destroy"
      />
      <div v-else class="no-entries">No Images</div>
    </div>
    <Spinner v-else relative />
  </div>
</template>

<script>
import IndexPage from "../index-page";
import ImageAssetsDataTable from "@/components/dataview/image-assets.datatable";

export default {
  name: "ImageLibraryPage",
  components: { ImageAssetsDataTable },
  extends: IndexPage,
  methods: {
    async fetchList() {
      const { data } = await this.$api.ImageAsset.get({
        sort_by: this.sortBy,
      });
      this.list = data;
    },
    async destroy(item) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.ImageAsset.destroy({ id: item.id });
      this.list = this.list.filter((t) => t.id != item.id);
    },
  },
};
</script>

<style scoped lang="scss"></style>
