<template>
  <div>
    <Banner theme="orange">
      <template v-slot:title> Venue Events </template>
      <template v-slot:description>
        Venue Events are live, in-person concerts, performances, musicals, etc.
      </template>
      <!-- <template v-slot:image>
        <img
          src="@/assets/banners/widgets/content-blocks.svg"
          alt="Content Blocks"
        />
      </template> -->
    </Banner>
    <v-tabs v-model="tab">
      <v-tab>Current and Upcoming</v-tab>
      <v-tab>Previous Events</v-tab>
    </v-tabs>

    <IndexPageActions
      :sort-list="sortList"
      btn-text="New Venue Event"
      btn-to="/venue-events/new"
      :view.sync="view"
      :sort-by.sync="sortBy"
      search-placeholder="Search Venue Event"
      :search-text.sync="searchText"
    />

    <VenueEventsDataGrid
      v-if="view == 'grid'"
      :list="computedList"
      @destroy="destroyVenueEvent"
      hide-duplicate
    />
    <ListPanels :loaded="!!list" v-if="view == 'table'">
      <div v-if="computedList && computedList.length">
        <ListPanel
          v-for="item in computedList"
          :key="item.id"
          :action-path="`/venue-events/${item.id}`"
          :title="venueEventTitle(item)"
          :label="item.label"
          @destroy="destroyVenueEvent(item)"
          hide-duplicate
          :class="{
            'hidden-from-lists': item.is_addon,
            'hidden-because-grouped': item.main_venue_event_id,
          }"
          :hideExpand="item.other_venue_events.length == 0"
          :hide-destroy="item.other_venue_events.length > 0"
          :hide-edit="item.other_venue_events.length > 0"
        >
          <template #status>
            <div v-if="item.hidden" class="list-panel__cancelled">Hidden</div>
            <div v-if="item.offsale" class="list-panel__cancelled">Offsale</div>
            <div v-if="item.cancelled" class="list-panel__cancelled">
              Cancelled
            </div>
          </template>
          <div v-if="item.other_venue_events.length">
            <VenueEventsDatatable
              :list="allEvents(item)"
              :entity="item"
              @destroy="separateOtherEvent($event, item)"
              hide-duplicate
            />
          </div>
        </ListPanel>
      </div>
      <div v-else class="no-entries">No Venue Events</div>
    </ListPanels>
  </div>
</template>

<script>
import IndexPage from "./index-page";
import VenueEventsDatatable from "@/components/dataview/venue-events.datatable";
import VenueEventsDataGrid from "@/components/dataview/venue-events.datagrid";

export default {
  name: "VenueEventsPage",
  components: { VenueEventsDatatable, VenueEventsDataGrid },
  extends: IndexPage,
  data() {
    return {
      sortBy: this.$ls.get(`${this.$route.name}/sortBy`, "effective_datetime"),
      sortList: [
        { text: "Event date", value: "effective_datetime", order: "ASC" },
        { text: "Created date", value: "created_at" },
        { text: "Updated date", value: "updated_at" },
      ],
      tab: 0,
    };
  },
  methods: {
    async fetchList() {
      const params = { sort_by: this.sortBy };
      const sortByObj = this.sortList.find((e) => e.value == this.sortBy);
      if (sortByObj) params.sort_order = sortByObj.order;
      if (this.tab == 1) params.previous = true;
      const { data } = await this.$api.VenueEvent.get(params);
      this.list = data;
    },
    async destroyVenueEvent({ id }) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.VenueEvent.destroy({ id });
      this.list = this.list.filter((t) => t.id != id);
    },
    async separateOtherEvent(otherVenueEvent) {
      const confirmed = await this.$confirmWindow({ title: "Are you sure?" });
      if (!confirmed) return;
      await this.$api.VenueEvent.separate({ id: otherVenueEvent.id });
      this.fetchList();
    },
    venueEventTitle(item) {
      return `${item.dates_formatted}: ${item.title}`;
    },
    allEvents(item) {
      return [item].concat(item.other_venue_events);
    },
  },
  watch: {
    tab() {
      this.fetchList();
    },
  },
};
</script>

<style lang="scss">
.hidden-from-lists {
  .list-panel-title {
    color: #919199 !important;
  }
}
.hidden-because-grouped {
  display: none;
}
</style>

<style scoped lang="scss">
.othervenue-events {
  &__new-btn {
  }
}

.hidden-from-lists {
  background: #f8f8f9 !important;
}

.list-panel__cancelled {
  font-weight: bold;
  color: var(--v-primary-base);
  padding: 0.25em 0.75em 0.3em;
  border-radius: 0.25em;
  z-index: 1;
}

@media screen and (max-width: 980px) {
  .othervenue-events {
    &__new-btn {
      padding: 0 !important;
      min-width: 36px !important;
      &-text {
        display: none;
      }
    }
  }
}
</style>
