<template>
  <div>
    <div class="page-title">Edit Easy Update</div>
    <Form
      v-if="form"
      v-model="form"
      :submit="update"
      btnText="Update Easy Update"
      @destroy="destroy"
    />
    <Spinner v-else />
  </div>
</template>

<script>
import Form from "./_form.variables.page";
export default {
  components: { Form },
  data() {
    return {
      form: null,
    };
  },
  created() {
    this.setForm();
  },
  methods: {
    async update() {
      await this.$api.Variable.update(this.form);
      this.$router.push("/variables");
    },
    async setForm() {
      const { data } = await this.$api.Variable.get({
        id: this.$route.params.id,
      });
      this.form = data;
    },
    async destroy() {
      const confimed = await this.$confirmWindow({
        title: "Are you sure?",
      });
      if (!confimed) return;

      await this.$api.Variable.destroy({ id: this.form.id });
      this.$router.push("/variables");
    },
  },
};
</script>

<style></style>
